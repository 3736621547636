import styled from "styled-components";

const orgDetails = JSON.parse(localStorage.getItem("org"));

let primaryColor = "#e86020";
if (orgDetails && orgDetails.primaryColor) {
  primaryColor = orgDetails.primaryColor;
}

export const StyledInputGroup = styled.div`
  position: relative;
`;

export const StyledInput = styled.input`
  ${"" /* border: solid 1px ${primaryColor}; */}
  border:1px solid #000000;
  border-left: ${(props) => props.inputError && "10px solid #e86020"};
  border-radius: 4px;
  background: none;
  font-size: 14px;
  color: #000000;
  transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 320px;
  height: 40px;

  &:focus,
  &:valid,
  &[value]:not([value=""]) {
    outline: none;
    ${"" /* border: 1px solid ${primaryColor}; */}
    border:1px solid #000000;
  }
`;

export const StyledLabel = styled.label`
  position: absolute;
  top: -7px;
  left: 8px;
  pointer-events: none;
  ${"" /* transform: translateY(1rem); */}
  transform: ${(props) =>
    props.hasValue ? "translateY(-25%) scale(0.8)" : "translateY(1rem)"};
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: ${(props) => (props.hasValue ? "#ffffff" : "none")};
  padding: ${(props) => (props.hasValue ? "0 0.2em" : "none")};
  color: #233747;
  font-size: 18px;
`;

export const StyledInputContainer = styled.div`
  position: relative;

  ${StyledInput}:focus ~ ${StyledLabel},
  ${StyledInput}:valid ~ ${StyledLabel} {
    transform: translateY(-25%) scale(0.8);
    background-color: #ffffff;
    padding: 0 0.2em;
    color: #233747;
  }
`;

//select element

export const StyledSelectGroup = styled.div`
  position: relative;
`;

export const StyledSelect = styled.select`
  width: ${(props) => props.width || "320px"};
  height: 40px;
  background-color: #ffffff;
`;

export const SelectLabel = styled.label`
  position: absolute;
  top: -10px;
  left: 20px;
  background-color: #ffffff;
`;

export const StyledSelectContainer = styled.div`
  position: relative;

  ${StyledSelect}:focus {
    outline: none;
    ${"" /* border: 1px solid ${primaryColor}; */}
    border:1px solid #000000
  }

  ${StyledSelect}:not(:focus) {
    outline: none; /* Remove outline */
    ${"" /* border: 1px solid ${primaryColor}; */}
    border:1px solid #000000
  }

  ${StyledSelect}:focus ~ ${StyledLabel}, ${StyledSelect}:valid ~ ${StyledLabel} {
    transform: translateY(-25%) scale(0.8);
    background-color: #ffffff;
    padding: 0 0.2em;
    color: #233747;
  }
`;

export const StyledTextarea = styled.textarea`
  ${"" /* border: solid 1.5px ${primaryColor}; */}
  border:1px solid #000000;
  border-radius: 4px;
  background: none;
  padding: 1rem;
  font-size: 1rem;
  color: #000000;
  transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
  width: ${(props) => props.width || "320px"};
  height: ${(props) => props.height || "120px"};
  resize: vertical; /* This property allows vertical resizing */

  &::placeholder {
    color: transparent;
  }

  &:focus {
    outline: none;
    ${"" /* border: 1.5px solid ${primaryColor}; */}
    border:1px solid #000000
  }

  &:focus ~ label {
    transform: translateY(-25%) scale(0.8);
    background-color: #ffffff;
    padding: 0 0.2em;
    color: #000000;
  }
`;

// for file uploads

export const StyledFileInput = styled.input.attrs({
  type: "file",
  id: "fileInput",
})`
  display: none;
`;

// Styled label for the file input
export const StyledUploadLabel = styled.label`
  background-color: ${(props) => (props.disabled ? "#e0e0e0" : "transparent")};
  color: ${primaryColor};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: 126px;
  height: 36px;
  font-size: 14px;
  border: 1px solid ${(props) => (props.disabled ? "#ccc" : primaryColor)}; 
  border-radius: 6px;
  display: flex;
  justify-content: center;
  padding-top: 5px;
`;
