import React from "react";
import "./index.css";

const ProfileDetailsTabs = (props) => {
  const { tabData, setActiveTab, activeTab } = props;
  const onClickTab = () => {
    setActiveTab(tabData.id);
  };

  const orgDetails = JSON.parse(localStorage.getItem("org"));
  const { primaryColor } = orgDetails;
  // const highlightTab = activeTab && "active-tab";

  return (
    <li>
      <button
        style={
          activeTab
            ? {
                color: primaryColor,
                borderBottom: `3px solid ${primaryColor}`,
              }
            : {}
        }
        className={`btn-tabs`}
        onClick={onClickTab}
        type="button"
      >
        {tabData.tabName}
      </button>
    </li>
  );
};

export default ProfileDetailsTabs;
