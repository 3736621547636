/* eslint-disable react-hooks/exhaustive-deps */
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Toaster } from "react-hot-toast";
import { PrimaryButton } from "../../StyledCss/styledbtn";
import formatDate from "../../util/formatdate";
import ExpenditureHistory from "../ExpenditureHistory";
import Header from "../Header";
import LeftNavBar from "../LeftNavBar";
import SlidingModal from "../SlidingModal/slidingModal";

import FileDownloader from "../ZipFilesDownloader";
import "./index.css";

import { PageHeaderName } from "../../StyledCss/styledComponents";
import {
  getExpenditureByMonth,
  getMonthlyExpenditureBillUrls,
} from "../../api/services/hroneApi";
import ExpenditureForm from "../ExpenditureForm/expenditureForm";
import FailureView from "../FailureView";
import LoadingView from "../LoadingView";
import PageHeader from "../PageHeader";

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const Expenditure = () => {
  const date = new Date();
  const [startMonth, setStartMonth] = useState(date);
  const [expenditureHistoryList, setExpenditureHistory] = useState([]);
  const formattedStartMonth = format(startMonth, "yyyy-MM");
  const [uploadBillModal, setUploadBillModal] = useState(false);
  const [monthlyBills, setMonthlyBills] = useState();
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);

  useEffect(() => {
    getExpenditureHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startMonth]);

  useEffect(() => {
    getMonthlyBills();
  }, [startMonth]);

  const getExpenditureHistory = async () => {
    setApiStatus(apiStatusConstants.inProgress);
    try {
      const response = await getExpenditureByMonth(formattedStartMonth);
      if (response.status === 200) {
        setApiStatus(apiStatusConstants.success);
        const data = await response.json();
        const updatedData = data.map((eachItem) => ({
          id: eachItem.id,
          expenditureType: eachItem.expenditure_type,
          date: formatDate(eachItem.date),
          amount: eachItem.amount,
          billImage: eachItem.bill_image_url,
          description: eachItem.description,
        }));
        setExpenditureHistory(updatedData);
      } else {
        setApiStatus(apiStatusConstants.failure);
      }
    } catch (error) {
      // alert(error);
      setApiStatus(apiStatusConstants.failure);
    }
  };

  const getMonthlyBills = async () => {
    try {
      const response = await getMonthlyExpenditureBillUrls(formattedStartMonth);
      if (response.status === 200) {
        const data = await response.json();
        setMonthlyBills(data);
      }
    } catch (error) { }
  };
  const onCloseModal = () => {
    setUploadBillModal(false);
  };
  const refreshData = () => getExpenditureHistory();

  const renderExpenditureTable = () => {
    switch (apiStatus) {
      case apiStatusConstants.success:
        return (
          <ExpenditureHistory
            expenditureHistoryList={expenditureHistoryList}
            refreshData={refreshData}
          />
        );
      case apiStatusConstants.failure:
        return <FailureView onClickRetry={refreshData} />;
      case apiStatusConstants.inProgress:
        return <LoadingView />;
      default:
        return null;
    }
  };

  return (
    <div>
      <Header />
      <div className="d-flex ">
        <LeftNavBar />
        <div style={{ width: "100vw", backgroundColor: "#ededed" }}>
          <PageHeader>
            <PageHeaderName>Expenditure</PageHeaderName>

            <div className="d-flex align-items-center">
              <PrimaryButton
                style={{ marginRight: "10px" }}
                onClick={() => setUploadBillModal(!uploadBillModal)}
              >
                Upload Bill
              </PrimaryButton>
              <FileDownloader links={monthlyBills} date={formattedStartMonth} />

              {/* <div className="month-picker d-flex flex-column m-2"> */}
              {/* <label className="mb-1 date-input-label">Select Month</label> */}
              <DatePicker
                className="date-input-label"
                selected={startMonth}
                onChange={(date) => {
                  if (date) {
                    setStartMonth(date);
                  } else {
                    setStartMonth(new Date())
                  }
                }}
                showMonthYearPicker
                dateFormat="MMMM yyyy"
              // readOnly={true}
              />
              {/* </div> */}
            </div>
          </PageHeader>

          <div className="table-container">{renderExpenditureTable()}</div>
          <SlidingModal
            modalName="Add Expenditure"
            closeBtn={onCloseModal}
            isModalOpen={uploadBillModal}
          >
            <ExpenditureForm
              getExpenditureHistory={getExpenditureHistory}
              setUploadBillModal={setUploadBillModal}
            />
          </SlidingModal>

          <Toaster position="top-center" reverseOrder={false} />
        </div>
      </div>
    </div>
  );
};

export default Expenditure;
