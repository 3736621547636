import React from 'react'
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AllEmployees from "../Components/AllEmployees";
import Expenditure from "../Components/Expenditure";
import HomeRoute from "../Components/HomeRoute";
import LeaveRequestsManagement from "../Components/LeaveManagementRoute";
import LoginForm from "../Components/LoginForm";
import EnterEmail from "../Components/EnterEmail/enterEmail";
import NotFoundRoute from "../Components/NotFoundRoute";
import OTPEnter from "../Components/OtpEnter/otpEnter";
import Payslip from "../Components/Payslip";
import Policies from "../Components/Policies/policies";
import ProfileRoute from "../Components/ProfileRoute";
import ProtectedRoute from "../Components/ProtectedRoute/ProtectedRoute";
import Reimbursement from "../Components/Reimbursement";
import ResetPassword from "../Components/ResetPassword/resetPassword";
import ResourceTracking from "../Components/ResourceTracking/index";
import SalaryManagement from "../Components/SalaryManagement";
import Settings from "../Components/Settings";
import Tenant from "../Components/Tenants/tenant";
import { ToastContainer } from "react-toastify";

const AuthenticatedRouter = () => {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<LoginForm />} />
                <Route path="forgot-passwd" element={<EnterEmail />} />
                <Route path="verifyOTP" element={<OTPEnter />} />
                <Route path="reset-passwd" element={<ResetPassword />} />
                {/* <Route path="/test" element={<ForgotPassword />} /> */}
                <Route
                    exact
                    path="/"
                    element={
                        <ProtectedRoute>
                            <HomeRoute path="/" />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/profile"
                    element={
                        <ProtectedRoute>
                            <ProfileRoute path="profile" />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/allemployees"
                    element={
                        <ProtectedRoute>
                            <AllEmployees path="allemployees" />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/leave_management"
                    element={
                        <ProtectedRoute>
                            <LeaveRequestsManagement path="leave_management" />
                            {/* <LeaveRequestsRoute path="leavesrequest"/> */}
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/salary"
                    element={
                        <ProtectedRoute>
                            <SalaryManagement path="salary" />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="payslip/:paramsEmployeeId"
                    element={
                        <ProtectedRoute>
                            <Payslip path="payslip" />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/resource_tracking"
                    element={
                        <ProtectedRoute>
                            <ResourceTracking path="resource_tracking" />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/reimbursement"
                    element={
                        <ProtectedRoute>
                            <Reimbursement path="reimbursement" />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/expenditure"
                    element={
                        <ProtectedRoute>
                            <Expenditure path="expenditure" />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/settings"
                    element={
                        <ProtectedRoute>
                            <Settings path="settings" />
                        </ProtectedRoute>
                    }
                />
                <Route
                    exact
                    path="/policies"
                    element={
                        <ProtectedRoute>
                            <Policies path="policies" />
                        </ProtectedRoute>
                    }
                />
                <Route path="/tenant" element={<Tenant />} />
                <Route path="*" element={<NotFoundRoute />} />
            </Routes>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Router>
    )
}
export default AuthenticatedRouter