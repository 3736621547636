import React, { useEffect, useState } from "react";
import formatDate from "../../util/formatdate";
import "./index.css";
import { getProfile, getUserData } from "../../api/services/hroneApi";
import {
  DetailsTextLabel,
  DetailsText,
  TabHeading,
} from "../../StyledCss/styledCss";

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const EmploymentInfo = () => {
  const [employeeData, setEmployeeData] = useState(null);
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);

  useEffect(() => {
    getUserDetails(); // eslint-disable-next-line
  }, []);

  const getUserDetails = async () => {
    setApiStatus(apiStatusConstants.inProgress);

    try {
      const response = await getProfile();
      if (response.status === 200) {
        const data = await response.json();
        // console.log(data)
        const updatedData = {
          id: data.id,
          firstName: data.first_name,
          lastName: data.last_name,
          dob: data.date_of_birth,
          qualifications: data.qualifications,
          department: data.department,
          bloodGroup: data.blood_group,
          gender: data.gender,
          mobileNo: data.mobile_number,
          email: data.email,
          location: data.address,
          designation: data.designation,
          joiningDate: data.joining_date,
          role: data.role,
          address: data.address,
          totalExperience: data.total_experience,
          bankAccountNumber: data.bank_account_number,
          panNumber: data.pan_number,
          ifsc: data.ifsc,
          employeeProjectStatus: data.employee_project_status,
        };
        setApiStatus(apiStatusConstants.success);
        setEmployeeData(updatedData);
        // console.log(updatedData)
        setApiStatus(apiStatusConstants.success);
      } else {
        setApiStatus(apiStatusConstants.failure);
      }
    } catch (e) {
      setApiStatus(apiStatusConstants.failure);
    }
  };

  const { role } = getUserData();

  const ProfileInfoSection = () => (
    <div className="profile-info-container">
      <TabHeading>Employment Details</TabHeading>
      <div>
        <DetailsTextLabel>Employee ID</DetailsTextLabel>
        <DetailsText>{employeeData.id}</DetailsText>
      </div>
      <div>
        <DetailsTextLabel>Role</DetailsTextLabel>
        <DetailsText>{role}</DetailsText>
      </div>
      <div>
        <DetailsTextLabel>Department</DetailsTextLabel>
        <DetailsText>{employeeData.department}</DetailsText>
      </div>
      <div>
        <DetailsTextLabel>Designation</DetailsTextLabel>
        <DetailsText>{employeeData.designation}</DetailsText>
      </div>
      <div>
        <DetailsTextLabel>Email</DetailsTextLabel>
        <DetailsText>{employeeData.email}</DetailsText>
      </div>
      <div>
        <DetailsTextLabel>Date Of Joining</DetailsTextLabel>
        <DetailsText>{formatDate(employeeData.joiningDate)}</DetailsText>
      </div>

      <div>
        <DetailsTextLabel>Total Experience</DetailsTextLabel>
        <DetailsText>
          {employeeData.totalExperience >= 12
            ? (employeeData.totalExperience / 12).toFixed(1)
            : employeeData.totalExperience}
          {employeeData.totalExperience >= 12
            ? " Years"
            : employeeData.totalExperience === 1
            ? " Month"
            : " Months"}
        </DetailsText>
      </div>

      <div>
        <DetailsTextLabel>Project Status</DetailsTextLabel>
        <DetailsText>{employeeData.employeeProjectStatus}</DetailsText>
      </div>

      <div>
        <DetailsTextLabel>Account Number</DetailsTextLabel>
        <DetailsText>{employeeData.bankAccountNumber}</DetailsText>
      </div>

      <div>
        <DetailsTextLabel>IFSC Code</DetailsTextLabel>
        <DetailsText>{employeeData.ifsc}</DetailsText>
      </div>

      <div>
        <DetailsTextLabel>PAN Number</DetailsTextLabel>
        <DetailsText>{employeeData.panNumber}</DetailsText>
      </div>
    </div>
  );

  const renderProfile = () => {
    switch (apiStatus) {
      case apiStatusConstants.inProgress:
        return (
          <div className="d-flex justify-content-center w-100">
            <div className="spinner-border text-primary " role="status"></div>
          </div>
        );

      case apiStatusConstants.success:
        return ProfileInfoSection();
      case apiStatusConstants.failure:
        return null;
      default:
        return null;
    }
  };

  return <div>{renderProfile()}</div>;
};

export default EmploymentInfo;
