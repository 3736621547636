// Styled component for LeftMobNavbar
import styled from "styled-components";

export const LeftMobNavbar = styled.div`
  display: flex;
  flex-direction: column;
  width: 270px;
  position: fixed;
  top: 0%;
  left: 0%;
  bottom: 0%;
  z-index: 9999;
  color: white;
  background-color: #f45c2c;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
`;
