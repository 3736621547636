export function snakeToCamel(str) {
  return str.replace(/_([a-z])/g, (match, char) => char.toUpperCase());
}

export function convertKeysToCamelCase(obj) {
  const camelCaseObj = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const camelKey = snakeToCamel(key);
      camelCaseObj[camelKey] = obj[key];
    }
  }
  return camelCaseObj;
}
