/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import formatDate from "../../util/formatdate";
import { getAllWorksByMonth } from "../../api/services/hroneApi";

const ReimbursementHistory = () => {
  const date = new Date();
  const [month, setMonth] = useState(date);
  //   const [endMonth, setEndMonth] = useState(date);
  const [history, setHistory] = useState([]);

  const formattedMonth = format(month, "yyyy-MM");
  //   const formattedEndMonth = format(endMonth, "yyyy-MM");

  useEffect(() => {
    getReimbursementHistory();
  }, [month]);

  const getReimbursementHistory = async () => {
    try {
      const response = await getAllWorksByMonth(formattedMonth);

      if (response.ok) {
        const data = await response.json();
        // console.log(data);
        const updatedData = data.map((eachWork) => ({
          workId: eachWork.extra_work_id,
          firstName: eachWork.first_name,
          lastName: eachWork.last_name,
          employeeId: eachWork.employee_id,
          date: formatDate(eachWork.date),
          hoursWorked: eachWork.hours,
          status: eachWork.status,
          rejectedReason: eachWork.rejected_reason,
        }));

        setHistory(updatedData);
      }
    } catch (error) {}
  };

  const columnDefs = [
    {
      headerName: "EMP ID",
      field: "employeeId",
      width: 120,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "NAME",
      valueGetter: function (params) {
        const firstName = params.data.firstName;
        const lastName = params.data.lastName;
        const combinedData = firstName + " " + lastName;
        return combinedData;
      },

      filter: true,
      floatingFilter: true,
      sortable: true,
    },
    {
      headerName: "DATE",
      field: "date",
      width: 130,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "HOURS WORKED",
      field: "hoursWorked",
      width: 170,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "STATUS",
      field: "status",
      width: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "REJECTED REASON",
      field: "rejectedReason",
      width: 200,
      tooltipField: "rejectedReason",
      filter: true,
      floatingFilter: true,
    },
  ];

  return (
    <div
      className="ag-theme-alpine"
      style={{
        height: "60vh",
        fontSize: "14px",
        marginLeft: "20px",
        marginRight: "20px",
        marginTop: "20px",
        fontFamily: "Arial, Helvetica, sans-serif",
      }}
    >
      <div className="d-flex align-items-center justify-content-end mb-4">
        <div className="">
          <label style={{ fontSize: "16px" }} className="mb-1">
            Select Month
          </label>
          <DatePicker
            className="date-input-label"
            selected={month}
            onChange={(date) => {
              if (date) {
                setMonth(date);
              } else {
                setMonth(new Date());
              }
            }}
            showMonthYearPicker
            dateFormat="MMMM yyyy"
          />
        </div>
      </div>

      <AgGridReact
        pagination={true}
        paginationPageSize={10}
        rowData={history}
        columnDefs={columnDefs}
      ></AgGridReact>
    </div>
  );
};

export default ReimbursementHistory;
