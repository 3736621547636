import React, { useEffect, useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { convertKeysToCamelCase } from "../../util/convertCamelCase";
import { getLeavesSummary } from "../../api/services/hroneApi";
import DatePicker from "react-datepicker";
import { format } from "date-fns";

const LeavesSummary = () => {
  const date = new Date();
  const [leavesSummaryData, setLeavesSummaryData] = useState([]);
  const [startMonth, setStartMonth] = useState(date);
  const formattedYearMonth = format(startMonth, "yyyy-MM");

  useEffect(() => {
    fetchLeavesSummaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startMonth]);

  const fetchLeavesSummaryData = async () => {
    try {
      const response = await getLeavesSummary(formattedYearMonth);
      if (response.status === 200) {
        const data = await response.json();
        const updatedData = data.map((eachItem) =>
          convertKeysToCamelCase(eachItem)
        );

        setLeavesSummaryData(updatedData);
      }
    } catch (error) {}
  };

  const columnDefs = [
    {
      headerName: "EMP ID",
      field: "employeeId",
      floatingFilter: true,
      filter: true,
    },
    {
      headerName: "NAME",
      field: "employeeName",
      floatingFilter: true,
      filter: true,
      width: 250,
      editable: true,
      tooltipField: "employeeName",
    },

    {
      headerName: "LEAVES COUNT",
      field: "leavesCount",
      floatingFilter: true,
      filter: true,
    },
  ];

  const renderLeavesSummary = () => (
    <div
      className="ag-theme-alpine history-container"
      style={{
        height: "70vh",
        fontSize: "14px",
        fontFamily: "Arial, Helvetica, sans-serif",
        marginLeft: "15px",
        marginRight: "15px",
        marginTop: "10px",
      }}
    >
      <AgGridReact
        rowData={leavesSummaryData}
        columnDefs={columnDefs}
        pagination={true}
        paginationPageSize={20}
      ></AgGridReact>
    </div>
  );

  return (
    <>
      <div className=" d-flex flex-column align-items-end me-3 mb-3">
        <label style={{ marginRight: "60px" }} className="mb-1">
          Select Month
        </label>
        <DatePicker
          selected={startMonth}
          onChange={(date) => {
            if (date) {
              setStartMonth(date);
            } else {
              setStartMonth(new Date());
            }
          }}
          showMonthYearPicker
          dateFormat="MMMM yyyy"
          className="date-input-label"
        />
      </div>
      {renderLeavesSummary()}
    </>
  );
};

export default LeavesSummary;
