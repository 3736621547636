import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { HiOutlinePencil } from "react-icons/hi2";
import { AiOutlinePlus } from "react-icons/ai";
import SlidingModal from "../SlidingModal/slidingModal";
import EditSkills from "../EditSkillsModal/editskills";
import { getSkills } from "../../api/services/hroneApi";
import "./index.css";
import AddSkillForm from "../AddSkillForm/addSkillForm";

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const EmployeeSkillsCard = () => {
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
  const [skillsList, setSkillsList] = useState([]);
  const [addSkillClicked, setAddSkillClicked] = useState(false);
  const [editSkills, setEditSkills] = useState(false);
  useEffect(() => {
    getSkillsList(); // eslint-disable-next-line
  }, []);

  const notifySkillsAdded = () => toast.success("Skills Added Successfully");
  const notifySkillUpdate = () => toast.success("Skills Updated Successfully");
  const getSkillsList = async () => {
    setApiStatus(apiStatusConstants.inProgress);
    try {
      const response = await getSkills();
      if (response.ok) {
        const data = await response.json();
        const UpdatedData = data.map((eachItem) => ({
          skillName: eachItem.skill_name,
          skillRating: eachItem.skill_rating,
          skillId: eachItem.skill_id,
        }));
        setSkillsList(UpdatedData);
        setApiStatus(apiStatusConstants.success);
      } else {
        setApiStatus(apiStatusConstants.failure);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const closePopup = () => {
    setAddSkillClicked(false);
  };

  const onClickAddSkillBtn = () => {
    setAddSkillClicked(true);
  };

  const renderSkillsList = () => (
    <ul className="skills-list-container">
      {skillsList.map((eachSkill, index) => {
        const rating = parseInt(eachSkill.skillRating) * 10;
        const ratingColor = rating > 30 ? "bg-success" : "bg-danger";
        return (
          <>
            <div>
              <li key={index} className="skills-list-item">
                <div className="d-flex justify-content-between">
                  <p>{eachSkill.skillName}</p>
                  <p className=""> {rating} %</p>
                </div>
                <div className="progress">
                  <div
                    className={`progress-bar ${ratingColor}`}
                    role="progressbar"
                    style={{ width: `${rating}%` }}
                    aria-valuenow="95"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </li>
            </div>
          </>
        );
      })}
    </ul>
  );

  const renderLoader = () => (
    <div className="d-flex justify-content-center w-100">
      <div className="spinner-border text-primary " role="status"></div>
    </div>
  );

  const renderFailure = () => (
    <div>
      <h3>Coudnt fetch Data</h3>
    </div>
  );

  const UsersSkillsList = () => {
    switch (apiStatus) {
      case apiStatusConstants.inProgress:
        return renderLoader();
      case apiStatusConstants.success:
        return (
          <div>
            {skillsList.length > 0 ? (
              renderSkillsList()
            ) : (
              <div className="Noskillsmsgcontainer">
                <p className="noSkillsmsg">No skills to Display</p>
              </div>
            )}
          </div>
        );
      case apiStatusConstants.failure:
        return renderFailure();
      default:
        return null;
    }
  };

  return (
    <div className="skills-container">
      <div className="add-skill-btn-container">
        {skillsList.length > 0 && (
          <button
            onClick={() => setEditSkills(true)}
            className="addSkillsBtn"
            type="button"
          >
            <HiOutlinePencil />
          </button>
        )}

        <button
          onClick={onClickAddSkillBtn}
          className="addSkillsBtn"
          type="button"
        >
          <AiOutlinePlus />
        </button>
      </div>

      {UsersSkillsList()}
      <SlidingModal
        modalName="Add Skills"
        closeBtn={closePopup}
        isModalOpen={addSkillClicked}
      >
        <AddSkillForm
          getSkillsList={getSkillsList}
          setAddSkillClicked={setAddSkillClicked}
          notifySkillsAdded={notifySkillsAdded}
        />
      </SlidingModal>

      <SlidingModal
        modalName="Edit Skills"
        closeBtn={() => setEditSkills(false)}
        isModalOpen={editSkills}
      >
        <EditSkills
          skillsList={skillsList}
          closeModal={() => setEditSkills(false)}
          getSkillsList={getSkillsList}
          setAddSkillClicked={setAddSkillClicked}
          notifySkillUpdate={notifySkillUpdate}
        />
      </SlidingModal>

      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default EmployeeSkillsCard;
