import React, { useEffect, useState } from "react";
// import { useSelector } from 'react-redux';
import jwt_decode from "jwt-decode";
import { Navigate, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
} from "../../StyledCss/styledInputs";
import { LoginPageButton } from "../../StyledCss/styledbtn";
import { updateUserPassword } from "../../api/services/hroneApi";
import "./resetPassword.css";

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const ResetPassword = () => {
  // const isOtpValidated = useSelector((state) => state.resetPswd.isOtpValidated);
  // const [success, setSuccess] = useState("");
  // const [err, setErr] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  const userResetToken = JSON.parse(sessionStorage.getItem("userResetToken"));
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const { email_validated, otp_validated } = userResetToken
    ? jwt_decode(userResetToken)
    : () => {};

  const [apiStatus, setAPiStatus] = useState(apiStatusConstants.initial);
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      sessionStorage.removeItem("userResetToken");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Clean up the event listener when the component unmounts.
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const [validationResult, setValidationResult] = useState({
    isLengthValid: false,
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);

    const isLengthValid = password.length >= 8 && password.length <= 50;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[-!$@$%^&*()_+|~=`{}[\]:";'<>?,./]/.test(password);

    setValidationResult({
      isLengthValid,
      hasUppercase,
      hasLowercase,
      hasNumber,
      hasSpecialChar,
    });
  };

  const onChangeConfirmPassword = (e) => {
    const password = e.target.value;
    if (password.trim() !== "") {
      // setConfirmPasswordError(false);
      setConfirmNewPassword(password);
      // setPasswordMatch(true);
    } else {
      // setConfirmPasswordError(true);
      setConfirmNewPassword(password);
    }
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    try {
      setAPiStatus(apiStatusConstants.inProgress);
      const response = await updateUserPassword(newPassword);

      if (response.status === 200) {
        setAPiStatus(apiStatusConstants.success);
        setTimeout(() => {
          navigate("/login");
        }, 1500);
      }
    } catch (error) {}
  };

  const image = process.env.PUBLIC_URL + "/forgotpassword.jpg";
  const companyLogo = process.env.PUBLIC_URL + "/urbuddilogo.png";

  return email_validated && otp_validated ? (
    <div className="d-flex align-items-center" style={{ paddingLeft: "6%" }}>
      <img style={{ height: "70%" }} src={image} alt="forgotPasswordVector" />

      <div className="reset-div">
        <div className="col-11 col-sm-8 col-md-5 col-lg-5 col-xl-3">
          <div className="reset-card-container m-3 p-3">
            <img className="company-logo" src={companyLogo} alt="companyLogo" />

            <p>Set New Password</p>
            <p></p>

            <form onSubmit={resetPassword} autoComplete="off">
              <div
              // className={success ? "hide-form" : ""}
              >
                <div className="form-group mb-3 ">
                  {/* <label htmlFor="newPassword">New Password</label> */}
                  {/* <Input
                    // style={{ width: "100%" }}
                    onChange={handlePasswordChange}
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    autoFocus={true}
                    required
                    value={newPassword}
                  /> */}

                  <StyledInputGroup>
                    <StyledInputContainer>
                      <StyledInput
                        onChange={handlePasswordChange}
                        type="password"
                        id="newPassword"
                        name="newPassword"
                        autoFocus={true}
                        value={newPassword}
                      />
                      <StyledLabel className="user-label">
                        New Password*
                      </StyledLabel>
                    </StyledInputContainer>
                  </StyledInputGroup>

                  {/* {formik.touched.newPassword && formik.errors.newPassword && (
                    <div className="text-danger">
                      {formik.errors.newPassword}
                    </div>
                  )} */}
                </div>

                <p>Your new password must follow these rules :</p>
                <div className="mb-4 d-flex flex-wrap gap-3">
                  <div className="w-30 checkbox-container">
                    <input
                      className="checkboxElement"
                      for="option1"
                      type="checkbox"
                      checked={validationResult.isLengthValid}
                    />
                    <label className="checkbox-label" id="option1">
                      8-50 Characters
                    </label>
                  </div>

                  <div className="d-flex align-items-center w-30">
                    <input
                      className="checkboxElement"
                      for="option2"
                      type="checkbox"
                      checked={validationResult.hasUppercase}
                    />
                    <label className="checkbox-label" id="option2">
                      One Uppercase
                    </label>
                  </div>

                  <div className="d-flex align-items-center w-30">
                    <input
                      className="checkboxElement"
                      for="option3"
                      type="checkbox"
                      checked={validationResult.hasLowercase}
                    />
                    <label className="checkbox-label" id="option3">
                      One Lowercase
                    </label>
                  </div>

                  <div className="d-flex align-items-center w-50">
                    <input
                      className="checkboxElement"
                      for="option3"
                      type="checkbox"
                      checked={validationResult.hasNumber}
                    />
                    <label className="checkbox-label" id="option3">
                      One Number (0-9)
                    </label>
                  </div>

                  <div className="d-flex align-items-center w-60">
                    <input
                      className="checkboxElement"
                      for="option3"
                      type="checkbox"
                      checked={validationResult.hasSpecialChar}
                    />
                    <label className="checkbox-label" id="option3">
                      One Special Character{" "}
                    </label>
                  </div>
                </div>

                <div className="form-group mb-3 ">
                  {/* <label htmlFor="confirmPassword">Confirm New Password</label>
                  <Input
                    style={{ width: "100%" }}
                    type="text"
                    id="confirmPassword"
                    name="confirmPassword"
                    required
                    value={confirmNewPassword}
                    onChange={onChangeConfirmPassword}
                  /> */}

                  <StyledInputGroup>
                    <StyledInputContainer>
                      <StyledInput
                        type="text"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={confirmNewPassword}
                        onChange={onChangeConfirmPassword}
                      />
                      <StyledLabel className="user-label">
                        Confirm New Password*
                      </StyledLabel>
                    </StyledInputContainer>
                  </StyledInputGroup>
                </div>
              </div>

              {/* {success ? (
                <p className="h5 text-center text-success">{success}</p>
              ) : null} */}

              <div className="d-flex justify-content-center mt-3">
                {/* {isLoading ? (
                  <ClipLoader color="#cb7c25" />
                ) : success ? (
                  <Button
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    {" "}
                    Back to login
                  </Button>
                ) : (
                  <div>
                    <LoginPageButton type="submit">Submit</LoginPageButton>
                  </div>
                )} */}

                <div className="submit-button-container">
                  <LoginPageButton type="submit">
                    {apiStatus === apiStatusConstants.inProgress ? (
                      <ClipLoader color="#ffffff" />
                    ) : (
                      "Submit"
                    )}
                  </LoginPageButton>
                </div>
              </div>
            </form>
            {/* {err ? <p className="h5 text-center text-danger">{err}</p> : null} */}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Navigate to="/verifyOTP" />
  );
};

export default ResetPassword;
