import axios from "axios";
import jwt_decode from "jwt-decode";
const backendEndpoint = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getJwtToken = () => {
  const jwtToken = JSON.parse(localStorage.getItem("loginDetails"));
  return jwtToken;
};

export const getUserData = () => {
  const jwtToken = JSON.parse(localStorage.getItem("loginDetails"));
  if (jwtToken) {
    const userObject = jwt_decode(jwtToken);
    if (userObject) {
      const camelCaseUserObject = {
        email: userObject.email,
        firstName: userObject.first_name,
        lastName: userObject.last_name,
        role: userObject.role,
        empId: userObject.employee_id,
        tenantId: userObject.tenant_id,
      };

      return camelCaseUserObject;
    }
  } else {
    return {};
  }
};

export const getHeaders = (contentType = "application/json") => {
  const jwtToken = getJwtToken();
  const { tenantId } = getUserData();
  const headers = {
    "Content-Type": contentType,
    Tenantid: `${tenantId}`,
    // "ngrok-skip-browser-warning": "69420",
    Authorization: `bearer ${jwtToken}`,
  };

  return headers;
};

export const authentication = async (loginObject) => {
  try {
    const response = await axios.post(`${backendEndpoint}/v1/authentication`, {
      email: loginObject.email,
      password: loginObject.password,
      device_token: loginObject.deviceToken,
      domain_name: loginObject.domainName,
    });
    // console.log(response, "response");
    return response;
  } catch (error) {
    // console.log(error, "error");
    throw error;
  }
};

export const updateDeviceToken = (deviceToken) => {
  try {
    const { empId, email } = getUserData();
    const response = axios.post(
      `${backendEndpoint}/v1/${empId}/device_token`,
      {
        device_token: deviceToken,
        employee_email: email,
        employee_id: empId,
      },
      {
        headers: getHeaders(),
      }
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteDeviceToken = (deviceToken) => {
  try {
    const { empId } = getUserData();
    const response = axios.post(
      `${backendEndpoint}/v1/${empId}/logout`,
      {
        device_token: deviceToken,
      },
      {
        headers: getHeaders(),
      }
    );
    return response;
  } catch (err) {
    throw err;
  }
};
// Forgot Password API fetch calls

export const verifyUser = async (data) => {
  try {
    const response = await axios.post(
      `${backendEndpoint}/v1/forgot_password`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    // console.log(response.data);
    if (response.status === 200) {
      sessionStorage.setItem(
        "userResetToken",
        JSON.stringify(response.data.token)
      );
    }
    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const verifyOtp = async (data) => {
  try {
    const userResetToken = JSON.parse(sessionStorage.getItem("userResetToken"));
    const { employee_id, tenant_id } = jwt_decode(userResetToken);
    const response = await axios.post(
      `${backendEndpoint}/v1/${employee_id}/verify_otp`,
      {
        otp: data, // Wrap the data in an object with the 'otp' key
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${userResetToken}`,
          Tenantid: `${tenant_id}`,
        },
      }
    );

    if (response.status === 200) {
      sessionStorage.setItem(
        "userResetToken",
        JSON.stringify(response.data.token)
      );
    }
    return response;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateUserPassword = async (data) => {
  try {
    const userResetToken = JSON.parse(sessionStorage.getItem("userResetToken"));
    const { employee_id, tenant_id } = jwt_decode(userResetToken);
    const response = await axios.post(
      `${backendEndpoint}/v1/${employee_id}/set_password`,
      {
        new_password: data,
      },
      {
        headers: {
          Authorization: `Bearer ${userResetToken}`,
          Tenantid: `${tenant_id}`,
        },
      }
    );
    return response;
  } catch (err) {
    throw err;
  }
};

//notifications API fetch calls

export const getNotifications = async () => {
  const { empId } = getUserData();
  const url = `${backendEndpoint}/v1/${empId}/notifications`;

  const options = {
    method: "GET",
    headers: getHeaders(),
  };
  try {
    const response = await fetch(url, options);
    if (response) {
      return response;
    }
  } catch (err) {
    throw err;
  }
};

export const markNotifications = async () => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/notifications`;
    const options = {
      method: "PUT",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);

    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteNotifications = async () => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/notifications`;
    const options = {
      method: "DELETE",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);

    return response;
  } catch (err) {
    throw err;
  }
};

//Birthdays notifications

export const getBirthdays = async () => {
  const { empId } = getUserData();
  const url = `${backendEndpoint}/v1/${empId}/birthdays`;

  try {
    const options = {
      method: "GET",
      headers: getHeaders(),
    };

    const response = await fetch(url, options);

    return response;
  } catch (err) {
    throw err;
  }
};

//events api calls

export const getTotalEmployeesCount = async () => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/employees_count`;

    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);

    return response;
  } catch (err) {
    throw err;
  }
};

export const getEvents = async () => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/events`;

    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);

    return response;
  } catch (err) {
    throw err;
  }
};

export const addEvent = (eventObj) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/event`;
    const options = {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        event: eventObj.eventName,
        date: eventObj.eventDate,
      }),
    };
    const response = fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteEvent = async (eachEvent) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/event/${eachEvent.id}?event=${eachEvent.event}`;

    const options = {
      method: "DELETE",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

//Holidays fetch api calls

export const getHolidays = async () => {
  try {
    const { empId } = getUserData();

    const url = `${backendEndpoint}/v1/${empId}/holidays`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };

    const response = await fetch(url, options);

    return response;
  } catch (err) {
    throw err;
  }
};

export const addHoliday = async (addHolidayObj) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/holiday`;

    const options = {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        occasion: addHolidayObj.holidayName,
        date: addHolidayObj.eventDate,
      }),
    };

    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteHoliday = async (eachHoliday) => {
  // console.log(eachHoliday)
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/holiday/${eachHoliday.id}`;

    const options = {
      method: "DELETE",
      headers: getHeaders(),
    };

    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

//Profile API Fetch calls

export const getProfile = async () => {
  const { empId } = getUserData();
  try {
    const url = `${backendEndpoint}/v1/${empId}/employee`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);
    return response;
  } catch {}
};

export const updateEmployee = async (data) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/update_employee`;

    const options = {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify({
        mobile_number: data.mobileNumber,
        date_of_birth: data.dob,
        blood_group: data.bloodGroup,
        emergency_contact_name: data.emergencycontactName,
        emergency_contact_number: data.emergencyContactNumber,
        address: data.location,
      }),
    };
    const response = await fetch(url, options);

    return response;
  } catch (err) {
    throw err;
  }
};

export const updateProfilePicture = async (formData, boundary) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/profile_picture`;
    const response = await axios.put(url, formData, {
      headers: getHeaders(`multipart/form-data ; boundary = ${boundary}`),
    });

    return response;
  } catch {}
};

export const updateCoverPicture = async (formData, boundary) => {
  try {
    const { empId } = getUserData();

    const url = `${backendEndpoint}/v1/${empId}/cover_picture`;
    const response = await axios.put(url, formData, {
      headers: getHeaders(`multipart/form-data ; boundary = ${boundary}`),
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteProfilePicture = async () => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/profile_picture`;

    const options = {
      method: "DELETE",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteCoverPicture = async () => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/cover_picture`;
    const options = {
      method: "DELETE",
      headers: getHeaders(),
    };

    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

//leave API Fetch calls

export const getUserLeavesHistory = async (queryArg) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/leaves?start_month=${queryArg.startMonth}&end_month=${queryArg.endMonth}`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getLeavesSummary = async (formattedYearMonth) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/leaves_summary?year_month=${formattedYearMonth}`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);
    return response;
  } catch (error) {}
};

export const applyLeave = async (queryArg) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/leave`;
    const options = {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(queryArg),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

//Skils API Fetch calls

export const getSkills = async () => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/skills`;

    const options = {
      method: "GET",
      headers: getHeaders(),
    };

    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const addSkill = async (skillArray) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/skill`;
    const options = {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(skillArray),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateSkill = async (EditedskillData) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/skill`;

    const options = {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify(EditedskillData),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};
export const deleteUserSkill = async (skillName) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/skill`;

    const options = {
      method: "DELETE",
      headers: getHeaders(),
      body: JSON.stringify({
        skill_name: skillName,
      }),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

//Other API fetch calls

//1. certificates API calls

export const getUserCertificates = async () => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/certificates`;

    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);
    return response;
  } catch {}
};
//2.reset password
export const resetUserPassword = async (resetObject) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/password`;
    const options = {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify({
        current_password: resetObject.currentPassword,
        new_password: resetObject.newPassword,
      }),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

//ALL employees Component API Fetch calls
export const exportAllEmployees = async () => {
  try {
    const { empId } = getUserData();
    const options = {
      method: "GET",
      headers: getHeaders(),
      responseType: "blob",
    };

    const url = `${backendEndpoint}/v1/${empId}/export_employees`;
    const response = await axios(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const sampleFile = async () => {
  try {
    const { empId } = getUserData();
    const options = {
      method: "GET",
      headers: getHeaders(),
      responseType: "blob",
    };

    const url = `${backendEndpoint}/v1/${empId}/export_employees/sample_file`;
    const response = await axios(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getAllActiveEmployees = async () => {
  try {
    const { empId } = getUserData();
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(
      `${backendEndpoint}/v1/${empId}/employees/active`,
      options
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getAllReleasedEmployees = async () => {
  try {
    const { empId } = getUserData();
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(
      `${backendEndpoint}/v1/${empId}/employees/released`,
      options
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteUser = async (deleteUserIds) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/employee`;
    const options = {
      method: "DELETE",
      headers: getHeaders(),
      body: JSON.stringify({ employee_ids: deleteUserIds }),
    };

    const response = await fetch(url, options);

    return response;
  } catch (err) {
    throw err;
  }
};

export const importEmployees = async (formData) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/import_employees`;
    const response = await axios.post(url, formData, {
      headers: getHeaders("multipart/form-data"),
    });
    return response;
  } catch (err) {
    throw err;
  }
};

//Add employee component API fetch calls

export const addEmployee = async (data, certificatesList) => {
  try {
    const { empId } = getUserData();
    const options = {
      method: "POST",
      body: JSON.stringify({
        first_name:
          data.firstName[0].toUpperCase() + data.firstName.substring(1),
        last_name: data.lastName[0].toUpperCase() + data.lastName.substring(1),
        id: data.id,
        mobile_number: data.mobileNumber,
        email: data.email,
        date_of_birth: data.dob,
        joining_date: data.joiningDate,
        qualifications: data.qualifications,
        designation: data.designation,
        department: data.department,
        address: data.location,
        blood_group: data.bloodGroup,
        gender: data.gender,
        password: data.password,
        role: data.role,
        salary: parseInt(data.salary),
        reporting_to: data.reportingTo,
        tenth: certificatesList["10th"],
        intermediate: certificatesList["Intermediate"],
        degree: certificatesList["Degree"],
        masters: certificatesList["PG"],
        original_degree: certificatesList["original Degree"],
      }),
      headers: getHeaders(),
    };
    const response = await fetch(
      `${backendEndpoint}/v1/${empId}/employee`,
      options
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const editEmployee = async (data, updatedCertificates) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/update_employee/all`;
    const options = {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify({
        first_name: data.firstName,
        last_name: data.lastName,
        id: data.id,
        email: data.email.trim(),
        role: data.role,
        gender: data.gender,
        date_of_birth: data.dob,
        joining_date: data.joiningDate,
        qualifications: data.qualifications,
        department: data.department,
        mobile_number: data.mobileNumber,
        blood_group: data.bloodGroup,
        designation: data.designation,
        salary: parseInt(data.salary),
        address: data.location,
        bank_name: data.bankName,
        bank_account_number: data.bankAccountNumber,
        ifsc: data.ifsc,
        pan_number: data.panNumber,
        employement_status: data.employeeStatus,
        employee_project_status: data.employeeProjectStatus,
        reporting_to: data.reportingTo,
        tenth: updatedCertificates.tenth,
        intermediate: updatedCertificates.intermediate,
        degree: updatedCertificates.degree,

        masters: updatedCertificates.masters,
        original_degree: updatedCertificates.originalDegree,
      }),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

//Leave Management component API calls

export const getLeavesCount = async () => {
  try {
    const { empId } = getUserData();
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(
      `${backendEndpoint}/v1/${empId}/leaves_count`,
      options
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getLeads = async () => {
  try {
    const { empId } = getUserData();
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(
      `${backendEndpoint}/v1/${empId}/leads`,
      options
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getHolidaysByYear = async (holidaysYear) => {
  try {
    const { empId } = getUserData();
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(
      `${backendEndpoint}/v1/${empId}/year_holidays?year=${holidaysYear}`,
      options
    );
    return response;
  } catch (err) {
    throw err;
  }
};

//Leave request component API calls

export const getPendingLeaves = async () => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/pending_leaves`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };

    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateLeave = async (leaveStatus, LeaveId, rejectedReason) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/leave/${LeaveId}`;

    const options = {
      method: "PUT",
      body: JSON.stringify({
        status: leaveStatus,
        rejected_reason: rejectedReason,
      }),
      headers: getHeaders(),
    };

    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getEmployeesLeavesHistory = async (
  formattedStartMonth,
  formattedEndMonth
) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/leaves/all?start_month=${formattedStartMonth}&end_month=${formattedEndMonth}`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const exportPayroll = async (formattedDate) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/payroll?current_date=${formattedDate}`;

    const options = {
      method: "GET",
      headers: getHeaders(),
      responseType: "blob",
    };
    const response = await axios.get(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const exportLeaves = async () => {
  try {
    const { empId } = getUserData();

    const options = {
      method: "GET",
      headers: getHeaders(),
      responseType: "blob",
    };
    const url = `${backendEndpoint}/v1/${empId}/export_leaves`;

    const response = await axios(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

//salary management Api calls

export const getALlPayslips = async (formattedMonth) => {
  try {
    const { empId } = getUserData();

    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(
      `${backendEndpoint}/v1/${empId}/payslips?year_month=${formattedMonth}`,
      options
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getRecentPayslips = async (employeeId) => {
  try {
    // const { empId } = getUserData();

    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(
      `${backendEndpoint}/v1/${employeeId}/recent_payslips`,
      options
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getRecentPayslipPdfFiles = async (employeeId) => {
  try {
    // const { empId } = getUserData();

    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(
      `${backendEndpoint}/v1/${employeeId}/payslip/files`,
      options
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const getEmployeePayslipPdfFile = async (
  selectedYear,
  selectedMonth,
  employeeId
) => {
  try {
    // const { empId } = getUserData();

    const options = {
      method: "GET",
      headers: getHeaders(),
    };

    const url = `${backendEndpoint}/v1/${employeeId}/payslip/file?year_month=${selectedYear}-${selectedMonth}`;
    const response = await fetch(url, options);

    return response;
  } catch (err) {
    throw err;
  }
};

export const importLeaves = async (formData) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/import_leaves`;
    const response = await axios.post(url, formData, {
      headers: getHeaders("multipart/form-data"),
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const addIncentive = async (data) => {
  const { empId } = getUserData();
  try {
    const url = `${backendEndpoint}/v1/${empId}/employee_incentives`;
    const response = await axios.put(url, data, {
      headers: getHeaders(),
    });
    return response;
  } catch (err) {
    throw err;
  }
};

//Payslip component API Fetch calls

export const getUserPayslip = async (paramsEmployeeId, formattedStartMonth) => {
  try {
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(
      `${backendEndpoint}/v1/${paramsEmployeeId}/payslip?year_month=${formattedStartMonth}`,

      options
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const regenerateUserPayslip = async (data, formattedMonth) => {
  try {
    const { empId } = getUserData();
    const options = {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify(data),
    };
    const response = await fetch(
      `${backendEndpoint}/v2/${empId}/update_payslip?year_month=${formattedMonth}`,

      options
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const auditFileExcelDownload = async () => {

  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/payroll/audit`;

    const options = {
      method: "GET",
      headers: getHeaders(),
      responseType: "blob",
    };
    const response = await axios.get(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

//Resource Tracking paySlip component calls

export const exportResources = async (activeTrackingGadget) => {
  try {
    const { empId } = getUserData();
    const options = {
      method: "GET",
      headers: getHeaders(),
      responseType: "blob",
    };

    const url = `${backendEndpoint}/v1/${empId}/export_resources?device_type=${activeTrackingGadget}`;

    const response = await axios(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const assignResource = async (resourceData) => {
  try {
    const { empId } = getUserData();
    const options = {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(resourceData),
    };
    const url = `${backendEndpoint}/v1/${empId}/resource/assign`;

    const response = await fetch(url, options);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getExcessOrReleasedResources = async () => {
  try {
    const { empId } = getUserData();
    const options = {
      method: "GET",
      headers: getHeaders(),
    };

    const url = `${backendEndpoint}/v1/${empId}/resources/released`;

    const response = await axios(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const importResources = async (formData) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/import_resources`;
    const response = await axios.post(url, formData, {
      headers: getHeaders("multipart/form-data"),
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getResource = async (activeTrackingGadget) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/resources?type=${activeTrackingGadget}`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getResourceSummary = async () => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/resources_summary`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const addResource = async (data) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/resource`;
    const options = {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        employee_id: data.employeeId,
        allocated_date: data.allocatedDate,
        device_type: data.deviceType,
        serial_id: data.serialId,
        model: data.model,
        count: data.totalAssigned,
        status: data.status,
      }),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateResource = async (releaseData) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/resource`;
    const response = await axios.put(url, releaseData, {
      headers: getHeaders(),
    });

    return response;
  } catch (err) {
    throw err;
  }
};

export const getWorkRequests = async () => {
  try {
    const { empId } = getUserData();

    const url = `${backendEndpoint}/v1/${empId}/pending_extra_works`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateExtraWork = async (workId, status, rejectedReason) => {
  const { empId } = getUserData();
  try {
    const url = `${backendEndpoint}/v1/${empId}/extra_work/${workId}`;
    const options = {
      method: "PUT",
      body: JSON.stringify({
        status: status,
        rejected_reason: rejectedReason,
      }),
      headers: getHeaders(),
    };

    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getAllWorksByMonth = async (formattedMonth) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/extra_works/all?year_month=${formattedMonth}`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getUserWorksByMonth = async (formattedStartMonth) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/extra_works?year_month=${formattedStartMonth}`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const applyUserExtraWork = async (data) => {
  const { empId } = getUserData();
  try {
    const options = {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(data),
    };

    const response = await fetch(
      `${backendEndpoint}/v1/${empId}/extra_work`,
      options
    );
    return response;
  } catch (err) {
    throw err;
  }
};

//expenditure API Fetch calls

export const getExpenditureByMonth = async (formattedStartMonth) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/expenditures?year_month=${formattedStartMonth}`;

    const options = {
      method: "GET",
      headers: getHeaders(),
    };

    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const addExpenditure = async (formData, boundary) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/expenditure`;
    const response = await axios.post(url, formData, {
      headers: getHeaders(`multipart/form-data ; boundary = ${boundary}`),
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getMonthlyExpenditureBillUrls = async (Month) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/expenditure/urls?year_month=${Month}`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);

    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteExpenditure = async (expenditureIds) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/expenditure`;
    const options = {
      method: "DELETE",
      headers: getHeaders(),
      body: JSON.stringify({ expenditure_ids: expenditureIds }),
    };

    const response = await fetch(url, options);

    return response;
  } catch (err) {
    throw err;
  }
};

export const getOrganizationSettingsInfo = async () => {
  const { empId } = getUserData();
  try {
    const url = `${backendEndpoint}/v1/${empId}/basic_info`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateOrganizationSettingsInfo = async (data) => {
  const { empId } = getUserData();

  try {
    const url = `${backendEndpoint}/v1/${empId}/basic_info`;
    const options = {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify(data),
    };

    const response = await fetch(url, options);
    // const response = await axios.put(url, formData, {
    //   headers: getHeaders(`multipart/form-data ; boundary = ${boundary}`),
    // });
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateOrganizationLogo = async (formData, boundary) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/tenant_logo`;
    const response = await axios.put(url, formData, {
      headers: getHeaders(`multipart/form-data ; boundary = ${boundary}`),
    });
    return response;
  } catch {}
};

export const getTenantsData = async () => {
  const { empId } = getUserData();
  try {
    const url = `${backendEndpoint}/v1/${empId}/tenants`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const addTenantData = async (data) => {
  const { empId } = getUserData();
  try {
    const url = `${backendEndpoint}/v1/${empId}/tenant`;
    const options = {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(data),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateTenantData = async (data) => {
  const { empId } = getUserData();
  try {
    const url = `${backendEndpoint}/v1/${empId}/tenant`;
    const options = {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify(data),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteTenant = async (tenantId) => {
  const { empId } = getUserData();
  try {
    const url = `${backendEndpoint}/v1/${empId}/tenant`;
    const options = {
      method: "DELETE",
      headers: getHeaders(),
      body: JSON.stringify({ tenant_id: tenantId }),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const tenantDataBeforeLogin = async (domain) => {
  try {
    const url = `${backendEndpoint}/v1/tenant/${domain}`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const uploadPolicyPdf = async (policyObj, boundary) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/policy`;

    const response = await axios.post(url, policyObj, {
      headers: getHeaders(`multipart/form-data ; boundary = ${boundary}`),
    });

    return response;
  } catch (error) {}
};

export const getPolicyPdfList = async (policyObj, boundary) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/policies`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getOrganizationLeads = async () => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/leads`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getEmployeeLead = async () => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/employee/lead`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAdmins = async () => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/admins`;
    const options = {
      method: "GET",
      headers: getHeaders(),
    };
    const response = await fetch(url, options);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateAssignedLead = async (assignedLead) => {
  try {
    const { empId } = getUserData();
    const url = `${backendEndpoint}/v1/${empId}/lead`;
    const options = {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify({ lead_email: assignedLead }),
    };
    const response = await fetch(url, options);

    return response;
  } catch (error) {
    throw error;
  }
};
