import React, { useState } from "react";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import { importEmployees, sampleFile } from "../../api/services/hroneApi";
import UploadingLoader from "../UploadingLoader";

import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
  StyledFileInput,
  StyledUploadLabel,
} from "../../StyledCss/styledInputs";
import "./uploadEmployeeExcel.css";
const ApiExcelStatus = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const UploadEmployeeExcel = ({ fetchEmployees, setOpenPopupExcel }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadExcelError, setUploadExcelError] = useState("");
  const [apiExcelStatus, setApiExcelStatus] = useState(ApiExcelStatus.initial);
  const fileUpload = () => toast.success("File uploaded successfully");

  const [fileUploadButtonDisabled, SetFileUploadButtonDisabled] =
    useState(false);
  const [fileSubmitButtonDisabled, SetFileSubmitButtonDisabled] =
    useState(true);

  const orgDetails = JSON.parse(localStorage.getItem("org"));
  const { primaryColor } = orgDetails;

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file !== undefined) {
      setSelectedFile(file);
      setUploadExcelError("");
      SetFileSubmitButtonDisabled(false);
      SetFileUploadButtonDisabled(true);
    }
  };
  const handleExportData = async () => {
    try {
      const response = await sampleFile();
      if (response.status === 200) {
        const fileNameFromServer = response.headers["content-disposition"];
        const filename = fileNameFromServer
          ? fileNameFromServer.split("filename=")[1]
          : "all_employees.xlsx";

        //here we are creating an download trigger through DOM
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(response.data);
        downloadLink.download = filename;
        downloadLink.click();
        URL.revokeObjectURL(downloadLink.href);
        // setOpenPopupExcel(false);
      }
    } catch (error) {
      console.error("Error while exporting data:", error);
    }
  };

  const uploadExcel = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    if (selectedFile !== null) {
      setApiExcelStatus(ApiExcelStatus.inProgress);
      try {
        const response = await importEmployees(formData);

        if (response.status === 200) {
          fileUpload();
          setApiExcelStatus(ApiExcelStatus.success);
          setOpenPopupExcel(false);
          fetchEmployees();
          setSelectedFile(null);
        }
      } catch (error) {
        const errorData = error.response.data.detail;
        setUploadExcelError(errorData);
        setApiExcelStatus(ApiExcelStatus.initial);
      }
    } else {
      setUploadExcelError("Please select a file to upload");
    }
  };

  const displayUploadingLoader = () => {
    switch (apiExcelStatus) {
      case ApiExcelStatus.inProgress:
        return <UploadingLoader />;
      case ApiExcelStatus.failure:
        return <p>Error uploading file</p>;
      default:
        return null;
    }
  };

  const hasValue = selectedFile !== null && selectedFile.name !== "";

  return (
    <>
      <div>
        <p
          style={{
            marginRight: "auto",
            marginTop: "20px",
            cursor: "pointer",
            textDecoration: "underline",
            color: `${primaryColor}`,
            width: "fit-content",
          }}
          onClick={handleExportData}
        >
          Download Sample File
        </p>
        <div className="d-flex align-items-center gap-3">
          {/* <StyledFileInput
            accept=".xlsx, .xls"
            onChange={handleFileSelect}
            id="fileInput"
            type="file"
            disabled={fileUploadButtonDisabled}
          /> */}

          {!selectedFile && (
            <StyledFileInput
              accept=".xlsx, .xls"
              onChange={handleFileSelect}
              id="fileInput"
              type="file"
              disabled={fileUploadButtonDisabled}
            />
          )}

          <StyledUploadLabel
            disabled={fileUploadButtonDisabled}
            htmlFor="fileInput"
            id="uploadBtn"
            style={
              fileUploadButtonDisabled
                ? { color: "#999999" }
                : { backgroundColor: `${primaryColor}`, color: "#ffffff" }
            }
          >
            Upload
          </StyledUploadLabel>

          <StyledInputGroup>
            <StyledInputContainer>
              <StyledInput
                value={selectedFile !== null ? selectedFile.name : ""}
                readOnly
                required
                type="text"
                autoComplete="off"
              />
              <StyledLabel hasValue={hasValue} className="user-label">
                File Name
              </StyledLabel>
            </StyledInputContainer>
          </StyledInputGroup>

          <div>
            <SecondaryButton
              onClick={() => {
                setSelectedFile(null);
                SetFileSubmitButtonDisabled(true);
                SetFileUploadButtonDisabled(false);
                setUploadExcelError("");
              }}
            >
              Cancel
            </SecondaryButton>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end gap-2 mt-5">
        <SecondaryButton onClick={() => setOpenPopupExcel(false)}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          disabled={fileSubmitButtonDisabled}
          onClick={() => uploadExcel()}
          className="uploadBtn-2"
        >
          Submit
        </PrimaryButton>
      </div>

      {displayUploadingLoader()}

      {uploadExcelError.length > 0 && (
        <p style={{ color: "red" }}>*{uploadExcelError}</p>
      )}
    </>
  );
};

export default UploadEmployeeExcel;
