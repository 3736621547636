import { useState } from "react";
import toast from "react-hot-toast";
import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
  StyledFileInput,
  StyledUploadLabel,
} from "../../StyledCss/styledInputs";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import { exportResources, importResources } from "../../api/services/hroneApi";
import UploadingLoader from "../UploadingLoader";

const ApiExcelStatus = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const UploadResourcesForm = ({ setOpenPopupExcel }) => {
  const [uploadExcelError, setUploadExcelError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [apiExcelStatus, setApiExcelStatus] = useState(ApiExcelStatus.initial);

  const fileUpload = () => toast.success("File uploaded successfully");
  const [fileUploadButtonDisabled, SetFileUploadButtonDisabled] =
    useState(false);

  const [fileSubmitButtonDisabled, SetFileSubmitButtonDisabled] =
    useState(true);

  const orgDetails = JSON.parse(localStorage.getItem("org"));
  const { primaryColor } = orgDetails;

  const handleexportResources = async () => {
    try {
      const response = await exportResources("All");
      if (response.status === 200) {
        // setIsPayrollDownload(false)
        setOpenPopupExcel(false);
        const fileNameFromServer = response.headers["content-disposition"];

        const filename = fileNameFromServer
          ? fileNameFromServer.split("filename=")[1]
          : "resources.xlsx";

        //here we are creating an download trigger through DOM
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(response.data);

        downloadLink.download = filename;
        downloadLink.click();
        URL.revokeObjectURL(downloadLink.href);
      }
    } catch (error) {}
  };

  const uploadExcel = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile);

    if (selectedFile !== null) {
      setApiExcelStatus(ApiExcelStatus.inProgress);

      try {
        const response = await importResources(formData);

        if (response.status === 200) {
          fileUpload();
          setApiExcelStatus(ApiExcelStatus.success);
          setOpenPopupExcel(false);
          // getResourceDetails();
          setSelectedFile(null);
        }
      } catch (error) {
        setUploadExcelError(error.response.data.detail);
        setApiExcelStatus(ApiExcelStatus.initial);
      }
    } else {
      setUploadExcelError("Please select a file to upload");
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];

    if (selectedFile === null || file !== undefined) {
      setSelectedFile(file);
      setUploadExcelError("");
      SetFileUploadButtonDisabled(true);
      SetFileSubmitButtonDisabled(false);
    } else {
      setSelectedFile(null);
    }
  };

  const displayUploadingLoader = () => {
    switch (apiExcelStatus) {
      case ApiExcelStatus.inProgress:
        return <UploadingLoader />;
      case ApiExcelStatus.failure:
        return <p>Error uploading file</p>;
      default:
        return null;
    }
  };

  const hasValue = selectedFile !== null && selectedFile.name !== "";

  return (
    <>
      {/* <div className="d-flex align-items-center justify-content-between">
        <h3>Upload File</h3>
      </div>
      <hr /> */}
      <div>
        <div className="">
          <p
            style={{
              marginRight: "auto",
              marginTop: "20px",
              cursor: "pointer",
              textDecoration: "underline",
              color: `${primaryColor}`,
              width: "fit-content",
            }}
            onClick={handleexportResources}
          >
            Download Sample File
          </p>
          <div className="d-flex align-items-center gap-2">
            {/* <StyledFileInput
              accept=".xlsx, .xls"
              onChange={handleFileSelect}
              id="fileInput"
              type="file"
              disabled={fileUploadButtonDisabled}
            /> */}

            {!selectedFile && (
              <StyledFileInput
                accept=".xlsx, .xls"
                onChange={handleFileSelect}
                id="fileInput"
                type="file"
                disabled={fileUploadButtonDisabled}
              />
            )}

            <StyledUploadLabel
              disabled={fileUploadButtonDisabled}
              htmlFor="fileInput"
              id="uploadBtn"
              style={
                fileUploadButtonDisabled
                  ? { color: "#999999" }
                  : { backgroundColor: `${primaryColor}`, color: "#ffffff" }
              }
            >
              Upload
            </StyledUploadLabel>

            <StyledInputGroup>
              <StyledInputContainer>
                <StyledInput
                  type="text"
                  name="text"
                  autoComplete="off"
                  readOnly
                  value={selectedFile !== null ? selectedFile.name : ""}
                />
                <StyledLabel className="user-label" hasValue={hasValue}>
                  File Name
                </StyledLabel>
              </StyledInputContainer>
            </StyledInputGroup>

            <SecondaryButton
              onClick={() => {
                setSelectedFile(null);
                SetFileUploadButtonDisabled(false);
                SetFileSubmitButtonDisabled(true);
              }}
            >
              Cancel
            </SecondaryButton>
          </div>

          <div className="mt-5 d-flex gap-3 justify-content-end">
            <SecondaryButton onClick={() => setOpenPopupExcel(false)}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              disabled={fileSubmitButtonDisabled}
              onClick={() => uploadExcel()}
              className="uploadBtn-2"
            >
              Submit
            </PrimaryButton>
          </div>
        </div>
        {displayUploadingLoader()}
        {uploadExcelError.length > 0 && (
          <p style={{ color: "red" }}>*{uploadExcelError}</p>
        )}
      </div>
    </>
  );
};

export default UploadResourcesForm;
