import React, { useEffect, useState } from "react";
import EmployeeSkillsCard from "../EmployeeSkillsCard";
import EmploymentInfo from "../EmploymentInfo";
import Header from "../Header";
import LeftNavBar from "../LeftNavBar";
import PersonalInfo from "../PersonalInfo";
import ProfileDetailsTabs from "../ProfileDetailsTabs";
// import { AiFillCamera, AiFillDelete } from "react-icons/ai";
import toast, { Toaster } from "react-hot-toast";
import { BsX } from "react-icons/bs";
import { FaCamera } from "react-icons/fa6";
import { PageHeaderName } from "../../StyledCss/styledComponents";
import { Button } from "../../StyledCss/styledbtn";
import {
  getProfile,
  getUserData,
  // deleteCoverPicture,
  updateCoverPicture,
} from "../../api/services/hroneApi";
import ProfilePicModal from "../AddProfilePic/profilePicModal";
import Others from "../Others";
import PageHeader from "../PageHeader";
import PopupModal from "../PopupModal";
import UploadingLoader from "../UploadingLoader";
import "./index.css";
const { id } = getUserData();

const ApiImageStatus = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const profilePageTabs = [
  { id: "PERSONAL INFO", tabName: "Personal Info" },
  { id: "EMPLOYMENT DETAILS", tabName: "Employment Details" },
  { id: "SKILLS", tabName: "Skills" },
  { id: "OTHER DETAILS", tabName: "Others" },
];

const ProfileRoute = () => {
  const [apiImageStatus, setApiImageStatus] = useState(ApiImageStatus.initial);
  const [activeTab, setActiveTab] = useState(profilePageTabs[0].id);
  const [profileDetails, setProfileDetails] = useState({});
  const [openCoverPicModal, setCoverPicModal] = useState(false);
  const [coverPic, setCoverPic] = useState(null); // This is used to send image file to backend
  const [coverPicUrl, setCoverPicUrl] = useState(null);
  const [isButtonReadOnly, setIsButtonReadOnly] = useState(true);
  const [maxSizeInBytes] = useState(1 * 1024 * 1024);
  const [profilePicUploadModal, setProfilePicUploadModal] = useState(false);

  const notifySaved = () => toast.success("Coverpic Updated Successfully");
  // const notifyCoverPicDeleted = () =>
  //   toast.success("Coverpic Deleted Successfully");

  useEffect(() => {
    getProfileDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProfileDetails = async () => {
    try {
      const response = await getProfile();
      if (response.ok) {
        const data = await response.json();

        const updatedData = {
          profilePicUrl: data.profile_picture_url,
          coverPicUrl: data.cover_picture_url,
          designation: data.designation,
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
        };
        setProfileDetails(updatedData);
      }
    } catch (error) {}
  };

  const refreshPage = () => {
    getProfileDetails();
  };

  const renderActiveTabDetails = () => {
    switch (activeTab) {
      case "PERSONAL INFO":
        return <PersonalInfo refreshPage={refreshPage} />;
      case "EMPLOYMENT DETAILS":
        return <EmploymentInfo />;

      case "SKILLS":
        return <EmployeeSkillsCard />;
      case "OTHER DETAILS":
        return <Others />;
      default:
        return null;
    }
  };

  const onChangeCoverPicture = (e) => {
    const image = e.target.files[0];
    if (image !== undefined && image.size <= maxSizeInBytes) {
      setIsButtonReadOnly(false);
    } else {
      setIsButtonReadOnly(true);
    }

    if (coverPicUrl === null && image !== undefined) {
      setCoverPic(e.target.files[0]);
      setCoverPicUrl(URL.createObjectURL(e.target.files[0]));
    } else if (coverPicUrl !== null && image !== undefined) {
      setCoverPic(e.target.files[0]);
      setCoverPicUrl(URL.createObjectURL(e.target.files[0]));
    } else {
      setCoverPicUrl(null);
      setCoverPic(null);
    }
  };

  const uploadCoverPic = async () => {
    const boundary = `Boundary-${Math.random().toString(16).substring(2)}`;

    const formData = new FormData();
    formData.append("cover_picture", coverPic);
    formData.append("id", id);

    try {
      setApiImageStatus(ApiImageStatus.inProgress);
      const response = await updateCoverPicture(formData, boundary);
      if (response.status === 200) {
        setApiImageStatus(ApiImageStatus.success);
        setCoverPicModal(false);
        getProfileDetails();
        notifySaved();
        setCoverPic(null);
        setCoverPicUrl(null);
      }
    } catch (error) {
      setApiImageStatus(ApiImageStatus.failure);
    }
  };

  const displayUploadingLoader = () => {
    switch (apiImageStatus) {
      case ApiImageStatus.inProgress:
        return <UploadingLoader />;
      case ApiImageStatus.failure:
        return <p>Error uploading file</p>;
      default:
        return null;
    }
  };

  const coverPicUploadModal = () => {
    let srcValue = "";

    if (coverPicUrl === null) {
      srcValue = profileDetails.coverPicUrl;
    } else {
      srcValue = coverPicUrl;
    }

    return (
      <div className="popup-overlay">
        <div className="coverpic-form">
          <div className="d-flex align-items-center justify-content-between">
            <h3>Background Photo</h3>
            <button
              className="cross-icon"
              onClick={() => {
                setCoverPicModal(!openCoverPicModal);
                setCoverPicUrl(null);
              }}
            >
              <BsX style={{ fontSize: "30px" }} />
            </button>
          </div>

          <hr />

          <div>
            <img className="cover-pic" alt="coverpic" src={srcValue} />
          </div>

          <div className="d-flex align-items-center justify-content-end">
            {coverPic !== null && (
              <strong style={{ marginRight: "auto" }}>[Max Size 1MB]</strong>
            )}
            <input
              accept="image/*"
              onChange={onChangeCoverPicture}
              hidden
              id="upload-cover-pic"
              type="file"
            />
            <label className="upload-image-label" htmlFor="upload-cover-pic">
              Upload
            </label>
            <Button
              disabled={isButtonReadOnly}
              style={{ marginLeft: "15px" }}
              onClick={uploadCoverPic}
              type="button"
              className="submit-btn"
              title="Max size 3MB"
            >
              Submit
            </Button>
          </div>
          <div className="d-flex justify-content-end">
            {displayUploadingLoader()}
          </div>
        </div>
      </div>
    );
  };

  const closeModal = () => {
    setProfilePicUploadModal(false);
  };

  return (
    <>
      <Header />
      <div className="d-flex">
        <LeftNavBar />

        <div className="profile-container">
          <PageHeader>
            <PageHeaderName>Profile</PageHeaderName>
          </PageHeader>

          <div className="new-profile-page">
            <div className="profile-pic-container">
              <div className="profileImageContainer">
                <img
                  className="profile-image"
                  alt="profile-pic"
                  src={profileDetails.profilePicUrl}
                />

                <button
                  onClick={() => setProfilePicUploadModal(true)}
                  className="camera-icon"
                >
                  <FaCamera />
                </button>
              </div>

              <div className="user-name-section">
                <p className="employeeName">
                  {profileDetails.firstName} {profileDetails.lastName}
                </p>
                <p style={{ marginBottom: "0px" }}>{profileDetails.email}</p>
                <p>{profileDetails.designation}</p>
              </div>
            </div>
            <div className="bottom-container">
              <ul className="tabs-container">
                {profilePageTabs.map((eachTab, index) => (
                  <ProfileDetailsTabs
                    key={index}
                    tabData={eachTab}
                    activeTab={eachTab.id === activeTab}
                    setActiveTab={setActiveTab}
                  />
                ))}
              </ul>
              <div className="user-info-container">
                <div>{renderActiveTabDetails()}</div>
              </div>
            </div>
          </div>

          <PopupModal
            closeBtn={closeModal}
            modalName="Profile Photo"
            showModal={profilePicUploadModal}
          >
            <ProfilePicModal
              profileDetails={profileDetails}
              closeModal={closeModal}
              refreshPage={getProfileDetails}
            />
          </PopupModal>

          {openCoverPicModal && coverPicUploadModal()}

          <Toaster position="top-center" reverseOrder={false} />
        </div>
      </div>
    </>
  );
};

export default ProfileRoute;
