import { configureStore } from "@reduxjs/toolkit";
import LeftNavBarReducer from "../slices/navBarSlice";
import mobileReducer from "../slices/mobileSlice";
import OrganizationInfoReducer from "../slices/organizationDetails";
import notificationReducer from "../slices/notificationSlice";
export const store = configureStore({
  reducer: {
    mobile: mobileReducer,
    leftNavBar: LeftNavBarReducer,
    OrganizationInfo: OrganizationInfoReducer,
    notifications: notificationReducer,
    // [leavesApi.reducerPath]:leavesApi.reducer,
  },
});
