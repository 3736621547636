export function convertKeysToSnakeCase(arrayOfObjects) {
  const snakeCaseKey = (key) =>
    key.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);

  return arrayOfObjects.map((obj) => {
    const newObj = {};
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        const snakeKey = snakeCaseKey(prop);
        newObj[snakeKey] = obj[prop];
      }
    }
    return newObj;
  });
}
