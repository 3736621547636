/* eslint-disable react-hooks/exhaustive-deps */
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import { MdDeleteOutline } from "react-icons/md";

import toast, { Toaster } from "react-hot-toast";
import { BsX } from "react-icons/bs";
import { Button, SecondaryButton } from "../../StyledCss/styledbtn";

import { deleteExpenditure } from "../../api/services/hroneApi";
import "./index.css";

const ExpenditureHistory = (props) => {
  const [viewBill, setViewBill] = useState(false);
  const [displayBillUrl, setDisplayBillUrl] = useState("");
  const { expenditureHistoryList, refreshData } = props;
  const [gridApi, setGridApi] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const notifyBillDeleted = () => toast.success("Bill Deleted Successfully");
  const notifyError = () => toast.error("Error Deleting Bill");

  const handleDownload = async () => {
    // Create a new anchor element
    const link = document.createElement("a");
    link.href = displayBillUrl;
    link.download = "image.jpg"; // The desired filename for the downloaded image

    // Trigger a click event on the anchor element to start the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const ImageViewModal = () => (
    <div className="popup-overlay">
      <div className="display-bill-modal">
        <div className="d-flex align-items-center justify-content-between">
          <h3>Bill</h3>
          <button className="cross-icon" onClick={() => setViewBill(false)}>
            <BsX style={{ fontSize: "30px" }} />
          </button>
        </div>

        <hr />

        {displayBillUrl && (
          <>
            {displayBillUrl.includes(".pdf") ? (
              <iframe
                title="PDF Viewer"
                src={displayBillUrl}
                width="100%"
                height="75%" // Adjust the height as needed
                style={{ marginTop: "10px" }}
              ></iframe>
            ) : (
              <img className="display-bill" src={displayBillUrl} alt="bill" />
            )}
          </>
        )}

        {/* <img className="display-bill" src={displayBillUrl} alt="bill" /> */}
        <div className="text-end">
          <Button onClick={handleDownload}>Download Bill</Button>
        </div>
      </div>
    </div>
  );

  const deleteBill = async () => {
    try {
      const response = await deleteExpenditure(selectedRows);

      if (response.status === 200) {
        refreshData();
        notifyBillDeleted();
      } else {
        notifyError();
      }
    } catch (error) {
      notifyError();
    }
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data.id);
      setSelectedRows(selectedData);
    }
  };

  const expenditureHistoryTable = () => {
    const columnDefs = [
      {
        headerName: "", // Empty header for checkbox column
        checkboxSelection: true, // Enable checkbox selection
        headerCheckboxSelection: true, // Show header checkbox to select all rows
        headerCheckboxSelectionFilteredOnly: true,
        width: 50, // Set the width of the checkbox column
        resizable: false,
      },
      {
        headerName: "EXPENDITURE TYPE",
        field: "expenditureType",
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "DESCRIPTION",
        field: "description",
        tooltipField: "description",
        width: "300px",
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "DATE",
        field: "date",
        width: 120,
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "AMOUNT",
        field: "amount",
        width: 120,
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "BILLS",
        cellRenderer: (params) => {
          return (
            <SecondaryButton
              style={{ marginTop: "0px", paddingTop: "0px" }}
              onClick={() => {
                // ImageViewModal(params.data);
                setViewBill(true);
                setDisplayBillUrl(params.data.billImage);
              }}
            >
              View Bill
            </SecondaryButton>
          );
        },
      },
    ];

    const paginationSize = 20;

    return (
      <div
        style={{
          height: "75vh",
          marginLeft: "15px",
          marginRight: "15px",
          fontSize: "14px",
        }}
        className="ag-theme-alpine history-container"
      >
        <AgGridReact
          rowData={expenditureHistoryList}
          columnDefs={columnDefs}
          pagination={true}
          paginationPageSize={paginationSize}
          rowSelection="multiple"
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          suppressRowClickSelection="true"
        ></AgGridReact>
      </div>
    );
  };

  const getTotalMonthlyBills = () => {
    const totalSum = expenditureHistoryList.reduce(
      (accumulator, currentValue) => accumulator + currentValue.amount,
      0
    );
    return totalSum;
  };

  return (
    <>
      {selectedRows.length > 0 && (
        <div className="d-flex align-items-center deleteEmployeesContainer">
          <p className="pt-3">{selectedRows.length} Bills selected</p>
          <button className="deleteIcon" onClick={deleteBill}>
            <MdDeleteOutline />
          </button>
        </div>
      )}
      {expenditureHistoryTable()}
      <p className="billAmount">
        Total Amount :{" "}
        <span className="total-amount">
          {getTotalMonthlyBills().toLocaleString("en-IN", {
            style: "currency",
            currency: "INR",
          })}{" "}
        </span>
      </p>
      {viewBill && ImageViewModal()}

      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default ExpenditureHistory;
