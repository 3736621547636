import styled from "styled-components";

export const TabHeading = styled.p`
  color: #212121;
  font-weight: 600;
  font-size: 18px;
`;

export const DetailsTextLabel = styled.label`
  color: #646569;
  font-weight: 400;
  font-size: 14px;
  width: 150px;
`;

export const DetailsText = styled.p`
  color: #000000;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

export const InputField = styled.input`
  width: 320px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e86020;
`;
