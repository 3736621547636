import React, { useState } from "react";
// import { AiFillDelete } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";

import {
  StyledFileInput,
  StyledUploadLabel,
} from "../../StyledCss/styledInputs";
import { SecondaryButton } from "../../StyledCss/styledbtn";
import {
  deleteProfilePicture,
  // getProfile,
  getUserData,
  // updateEmployee,
  updateProfilePicture,
} from "../../api/services/hroneApi";
import UploadingLoader from "../UploadingLoader";
import "./profilePicModal.css";
const ApiImageStatus = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const ProfilePicModal = ({ refreshPage, closeModal, profileDetails }) => {
  const [profilePic, setProfilePic] = useState(null);
  const [displayImage, setImage] = useState(null);
  // const [profilePicUrl, setProfilePicUrl] = useState("");
  const [apiImageStatus, setApiImageStatus] = useState(ApiImageStatus.initial);
  const [maxSizeInBytes] = useState(1 * 1024 * 1024);
  // const [isButtonReadOnly, setIsButtonReadOnly] = useState(true);
  const { id } = getUserData();

  const [fileUploadButtonDisabled, SetFileUploadButtonDisabled] =
    useState(false);
  const [fileSubmitButtonDisabled, SetFileSubmitButtonDisabled] =
    useState(true);

  const onChangePicture = (e) => {
    const image = e.target.files[0];

    if (image !== undefined && image.size <= maxSizeInBytes) {
      SetFileUploadButtonDisabled(true);
      SetFileSubmitButtonDisabled(false);
      // setIsButtonReadOnly(false);
    } else if (image === undefined && displayImage === "") {
      // setIsButtonReadOnly(true);
      SetFileUploadButtonDisabled(false);
      SetFileSubmitButtonDisabled(true);
    }

    if (image !== undefined) {
      setProfilePic(e.target.files[0]);
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const onClickSaveImage = async () => {
    const boundary = `Boundary-${Math.random().toString(16).substring(2)}`;

    const formData = new FormData();
    formData.append("profile_picture", profilePic);
    formData.append("id", id);

    // console.log(formData,"data")

    try {
      setApiImageStatus(ApiImageStatus.inProgress);
      if (profilePic.size <= maxSizeInBytes) {
        const response = await updateProfilePicture(formData, boundary);
        if (response.status === 200) {
          const image = response.data.data;

          setApiImageStatus(ApiImageStatus.success);
          refreshPage();
          closeModal();
          // setIsButtonReadOnly(true);
          const orgDetails = JSON.parse(localStorage.getItem("org"));
          orgDetails.profilePic = image;
          localStorage.setItem("org", JSON.stringify(orgDetails));
        } else {
        }
      }
    } catch (error) {
      setApiImageStatus(ApiImageStatus.failure);
    }
  };

  const displayUploadingLoader = () => {
    switch (apiImageStatus) {
      case ApiImageStatus.inProgress:
        return <UploadingLoader />;
      case ApiImageStatus.failure:
        return <p>Error uploading file</p>;
      default:
        return null;
    }
  };

  const orgDetails = JSON.parse(localStorage.getItem("org"));
  // console.log(profileDetails.profilePicUrl, "profile pic url");

  const deleteProfilePic = async () => {
    const response = await deleteProfilePicture();
    if (response.status === 200) {
      refreshPage();
      setImage(null);
      SetFileUploadButtonDisabled(false);
      SetFileSubmitButtonDisabled(true);
      const data = await response.json();
      console.log(data.detail.data, "delete ");
      const profileImage = data.detail.data;
      const orgDetails = JSON.parse(localStorage.getItem("org"));
      orgDetails.profilePic = profileImage;
      localStorage.setItem("org", JSON.stringify(orgDetails));
    }
  };

  const { primaryColor } = orgDetails;

  return (
    <div className="upload-image-section">
      <div className="d-flex flex-column align-items-center">
        {displayImage === null ? (
          <img
            className="avatar"
            alt="profile-pic"
            src={profileDetails.profilePicUrl}
          />
        ) : (
          <img className="avatar" alt="profile-pic" src={displayImage} />
        )}

        <StyledFileInput
          id="fileInput"
          accept="image/*"
          onChange={onChangePicture}
        />

        {profilePic !== null && <p>[Max Size 1MB]</p>}

        <div className="upload-submit-btn-container">
          <StyledUploadLabel
            disabled={fileUploadButtonDisabled}
            htmlFor="fileInput"
            id="uploadBtn"
            style={
              fileUploadButtonDisabled
                ? { color: "#999999" }
                : { backgroundColor: primaryColor, color: "#ffffff" }
            }
          >
            Upload
          </StyledUploadLabel>

          <SecondaryButton
            // className="save-btn"
            disabled={fileSubmitButtonDisabled}
            onClick={onClickSaveImage}
            type="button"
            title="Max size 1MB"
            className="uploadBtn-2"
            style={fileSubmitButtonDisabled ? { border: "0px none" } : {}}
          >
            Save
          </SecondaryButton>
          {/* onClick={deleteProfilePic} */}
          <button onClick={deleteProfilePic} className="profile-pic-delete">
            <MdDeleteOutline style={{ color: " #e86020", fontSize: "30px" }} />
          </button>
        </div>

        {displayUploadingLoader()}
      </div>
    </div>
  );
};

export default ProfilePicModal;
