import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLeftNavBarCollapsed: false,
  };

  export const navBarSlice = createSlice({
    name: "LeftNavBar",
    initialState,
    reducers: {
      collapseLeftnavBar: (state) => {
        state.isLeftNavBarCollapsed = true; 
      },
      expandLeftnavBar: (state) => {
        state.isLeftNavBarCollapsed = false; 
      },
    },
  });


export const { collapseLeftnavBar, expandLeftnavBar } = navBarSlice.actions;
export default navBarSlice.reducer;
