import React, { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
  StyledSelect,
  StyledSelectContainer,
  StyledSelectGroup,
  StyledTextarea,
  StyledFileInput,
  StyledUploadLabel,
} from "../../StyledCss/styledInputs";
import { PrimaryButton } from "../../StyledCss/styledbtn";
import { addExpenditure } from "../../api/services/hroneApi";
import { validateYear } from "../../util/validateYear";
import UploadingLoader from "../UploadingLoader";
import "./expenditureForm.css";
const ApiImageStatus = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const ExpenditureForm = ({ getExpenditureHistory, setUploadBillModal }) => {
  const [isButtonReadOnly, setIsButtonReadOnly] = useState(true);
  const [yearError, setYearError] = useState(false);
  const [apiImageStatus, setApiImageStatus] = useState(ApiImageStatus.initial);
  const [maxSizeInBytes] = useState(1 * 1024 * 1024);
  const [file, setFile] = useState(null);
  const [displayImage, setImage] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const notifySaved = () => toast.success("Bill Uploaded Successfully");
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const descriptionData = watch("description");

  const onSubmit = (data) => {
    onSubmitBill(data);
  };

  const onSubmitBill = async (data) => {
    const date = data.date;
    const isYearValid = validateYear(date);
    const formData = new FormData();
    formData.append("expenditure_type", data.expenditureType);
    formData.append("date", data.date);
    formData.append("amount", data.amount);
    formData.append("bill_file", file);
    formData.append("description", data.description);

    const boundary = `Boundary-${Math.random().toString(16).substring(2)}`;

    try {
      if (isYearValid) {
        setApiImageStatus(ApiImageStatus.inProgress);
        const response = await addExpenditure(formData, boundary);
        if (response.status === 200) {
          setApiImageStatus(ApiImageStatus.success);
          notifySaved();
          getExpenditureHistory();
          setUploadBillModal(false);
          getExpenditureHistory();
        }
      } else {
        setYearError(true);
      }
    } catch (error) {
      setYearError(false);
      setApiImageStatus(ApiImageStatus.failure);
      setErrorMsg(error.response.data.detail);
      // console.error("Error uploading file:", error);
    }
  };

  const displayUploadingLoader = () => {
    switch (apiImageStatus) {
      case ApiImageStatus.inProgress:
        return <UploadingLoader />;
      case ApiImageStatus.failure:
        return <p style={{ color: "red" }}>*{errorMsg}</p>;
      default:
        return null;
    }
  };
  const handleChange = async (e) => {
    const image = e.target.files[0];

    console.log(image, "upload file");

    if (
      (displayImage !== null && file !== null) ||
      image.size <= maxSizeInBytes
    ) {
      setIsButtonReadOnly(false);
    } else {
      setIsButtonReadOnly(true);
    }

    if (displayImage === null) {
      setFile(e.target.files[0]);
      setImage(URL.createObjectURL(e.target.files[0]));
    } else if (displayImage !== null && image !== undefined) {
      setFile(e.target.files[0]);
      setImage(URL.createObjectURL(e.target.files[0]));
    }
    // else {
    //   setImage(null);
    //   setFile(null);
    // }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="expenditure-form d-flex flex-column gap-3"
      autoComplete="off"
    >
      {/* <div className="d-flex align-items-center justify-content-between">
        <h3>Add Expenditure</h3>
      </div>

      <hr /> */}

      {/* <label>Expenditure Type*</label>
      <select
        className={
          errors.expenditureType ? "highlight-error" : "expendtiture-form-input"
        }
        name="expenditureType"
        {...register("expenditureType", { required: true })}
      >
        <option value="">---select---</option>

        <option value="Electricity">Electricity</option>
        <option value="Water">Water</option>
        <option value="Birthday">Birthday</option>
        <option value="Team Outing">Team Outing</option>
        <option value="Team Lunch">Team Lunch</option>
        <option value="Others">Others</option>
      </select> */}

      <StyledSelectGroup>
        <StyledSelectContainer>
          <StyledSelect
            style={errors.expenditureType && { borderLeft: "10px solid red" }}
            name="expenditureType"
            {...register("expenditureType", { required: true })}
          >
            <option value="">---select---</option>

            <option value="Electricity">Electricity</option>
            <option value="Water">Water</option>
            <option value="Birthday">Birthday</option>
            <option value="Team Outing">Team Outing</option>
            <option value="Team Lunch">Team Lunch</option>
            <option value="Others">Others</option>
          </StyledSelect>
          <StyledLabel className="user-label">Expenditure Type*</StyledLabel>
        </StyledSelectContainer>
      </StyledSelectGroup>

      <StyledInputGroup>
        <StyledInputContainer>
          <StyledTextarea
            style={errors.description && { borderLeft: "10px solid red" }}
            {...register("description", { required: true })}
          />
          <StyledLabel hasValue={descriptionData !== ""} className="user-label">
            Description*
          </StyledLabel>
        </StyledInputContainer>
      </StyledInputGroup>

      <StyledInputGroup>
        <StyledInputContainer>
          <StyledInput
            style={errors.date && { borderLeft: "10px solid red" }}
            name="date"
            type="date"
            max="9999-12-31"
            {...register("date", { required: true })}
          />
          <StyledLabel hasValue={true} className="user-label">
            Date*
          </StyledLabel>
        </StyledInputContainer>
      </StyledInputGroup>
      {yearError && (
        <p style={{ color: "red" }}>
          *Invalid year. Please enter a valid 4-digit year.
        </p>
      )}

      <StyledInputGroup>
        <StyledInputContainer>
          <StyledInput
            style={errors.amount && { borderLeft: "10px solid red" }}
            {...register("amount", { required: true })}
            id="amount"
            min="0"
            type="number"
            step="any"
          />
          <StyledLabel className="user-label">Amount*</StyledLabel>
        </StyledInputContainer>
      </StyledInputGroup>

      {/* display image and pdf */}
      {file && (
        <>
          {file.type.startsWith("image/") ? (
            // Display the image
            <img className="bill-image" alt="bill" src={displayImage} />
          ) : file.type === "application/pdf" ? (
            // Display the PDF
            <object
              data={displayImage} // Assuming displayImage contains the PDF URL
              type="application/pdf"
              width="100%"
              height="300px" // Adjust the height as needed
              style={{ marginTop: "10px" }}
            >
              <p>
                This browser does not support viewing PDFs. You can{" "}
                <a href={displayImage}>download the PDF</a> instead.
              </p>
            </object>
          ) : (
            // Handle other file types or show an error message
            <p>Unsupported file type: {file.type}</p>
          )}
        </>
      )}

      {/* {file && <img className="bill-image" alt="bill" src={displayImage} />} */}

      {file && file.size <= 1048576 ? null : (
        <p className="mt-3">[Max Size 1MB]</p>
      )}

      <div className="d-flex gap-3">
        <StyledFileInput
          accept=".jpg, .jpeg, .png, .pdf"
          onChange={handleChange}
          id="fileInput"
          type="file"
          // disabled={fileUploadButtonDisabled}
        />

        <StyledUploadLabel
          // disabled={fileUploadButtonDisabled}
          htmlFor="fileInput"
          id="uploadBtn"
        >
          Upload
        </StyledUploadLabel>

        <PrimaryButton
          // className="submit-btn"
          disabled={isButtonReadOnly}
          type="submit"
          className="uploadBtn-2"
        >
          Submit Bill
        </PrimaryButton>
      </div>

      {displayUploadingLoader()}
    </form>
  );
};

export default ExpenditureForm;
