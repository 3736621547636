import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { updateOrganizationInfo } from "../src/redux/slices/organizationDetails";
import "./App.css";
import AuthenticatedRouter from "./Routers/AuthenticatedRouter";
import {
  getNotifications,
  // getOrganizationSettingsInfo,
  updateDeviceToken,
} from "./api/services/hroneApi";
import "./firebase";
import {
  setDeviceToken,
  setNotifications,
} from "./redux/slices/notificationSlice";
function App() {
  const messaging = getMessaging();
  const userLoggedIn = useSelector((state) => state.notifications.userLogged);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userLoggedIn) {
      getNotificationsList();
    } // eslint-disable-next-line
  }, [userLoggedIn]);

  useEffect(() => {
    async function requestPermissionAndToken() {
      try {
        const permission = await Notification.requestPermission();

        if (permission === "granted") {
          // console.log('Notification permission granted.');
          try {
            const currentToken = await getToken(messaging, {
              vapidKey: process.env.REACT_APP_VAPID_KEY,
            });
            if (currentToken) {
              localStorage.setItem('devToken', JSON.stringify(currentToken))
              dispatch(setDeviceToken(currentToken));
              if (userLoggedIn) {
                updateDeviceToken(currentToken);
                localStorage.setItem('devToken', JSON.stringify(currentToken))
                // console.log(response)
              }
            }
          } catch (error) { }
        }
      } catch (error) { }
    }

    requestPermissionAndToken();
    return () => {
      localStorage.removeItem('devToken')
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Handle incoming FCM messages
    const messaging = getMessaging();

    onMessage(messaging, (payload) => {
      // console.log("Message received from foreground. ", payload);
      const newNotificationObejct = {
        title: payload.notification.title,
        message: payload.notification.body,
        isRead: false,
        profilePicUrl: payload.notification.image,
      };
      dispatch(setNotifications(newNotificationObejct));
    });

    return () => {
      // Cleanup if necessary
    }; // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userLoggedIn) {
      // getOrgDetails();
    } // eslint-disable-next-line
  }, [userLoggedIn]);

  const getNotificationsList = async () => {
    try {
      const response = await getNotifications();
      if (response.ok) {
        const data = await response.json();

        const updatedData = data.map((eachNotification) => ({
          title: eachNotification.title,
          message: eachNotification.message,
          createdAt: eachNotification.created_at,
          isRead: eachNotification.is_read,
          profilePicUrl: eachNotification.profile_picture_url,
        }));
        // console.log(updatedData, "from app.js");
        dispatch(setNotifications(updatedData));
      }
    } catch (err) { }
  };

  // const getOrgDetails = async () => {
  //   try {
  //     const response = await getOrganizationSettingsInfo();

  //     if (response.status === 200) {
  //       const data = await response.json();
  //       const updatedData = {
  //         primaryColor: data.primary_color,
  //         secondaryColor: data.secondary_color,
  //         logoUrl: data.logo_url,
  //         annualLeaves: data.annual_leaves,
  //         basicSalaryPercentage: data.basic_salary_percentage,
  //         hraPercentage: data.hra_percentage,
  //         pfPercentage: data.pf_percentage,
  //         accessControlApiKey: data.access_control_api_key,
  //       };

  //       localStorage.setItem("org", JSON.stringify(updatedData));
  //       dispatch(updateOrganizationInfo(updatedData));
  //     }
  //   } catch (error) { }
  // };

  return (
    <AuthenticatedRouter />
  );
}

export default App;
