import React, { useEffect, useState } from "react";
// import DatePicker from "react-datepicker";
import { Navigate, useParams } from "react-router-dom";

import toast, { Toaster } from "react-hot-toast";
// import ReactToPrint from "react-to-print";
// import { Button } from "../../StyledCss/styledbtn";
// import { MdFileDownload } from "react-icons/md";
import { BsX } from "react-icons/bs";
import {
  getEmployeePayslipPdfFile,
  getRecentPayslipPdfFiles,
  // getRecentPayslips,
  getUserData,
  // getUserPayslip,
} from "../../api/services/hroneApi";
import { convertKeysToCamelCase } from "../../util/convertCamelCase";
import FailureView from "../FailureView";
import Header from "../Header";
import LeftNavBar from "../LeftNavBar";
import LoadingView from "../LoadingView";
import PageHeader from "../PageHeader";
import "./index.css";
import { PageHeaderName } from "../../StyledCss/styledComponents";
// import SpinnerLoader from "../spinnerLoader";

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  payslipnotfound: "NOT_FOUND",
  inProgress: "IN_PROGRESS",
};

const singlePaySlipApiConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  payslipnotfound: "NOT_FOUND",
  inProgress: "IN_PROGRESS",
};

const Payslip = () => {
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
  const [singlePayslipApi, setSinglePayslipApi] = useState(
    singlePaySlipApiConstants.initial
  );

  const [singleMonthPayslip, setSingleMonthPayslip] = useState("");

  const { role, empId } = getUserData();
  const params = useParams();
  let { paramsEmployeeId } = params;
  // console.log(paramsEmployeeId)
  const [yearsList, setYearsList] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const notifyPayslipFailure = () => toast.error("Payslip not found");

  const orgDetails = JSON.parse(localStorage.getItem("org"));
  const { primaryColor } = orgDetails;

  useEffect(() => {
    if (selectedMonth && selectedYear) {
      fetchEmployeePaySlip();
    }

    // eslint-disable-next-line
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    // getPreviousPayslips();
    getPayslipFiles(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const startYear = new Date().getFullYear() - 2;
    const years = Array.from(
      { length: currentYear - startYear + 1 },
      (_, index) => {
        const yearValue = startYear + index;
        return { label: yearValue.toString(), value: yearValue.toString() };
      }
    );

    setYearsList(years.reverse());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [paySlipFiles, setPayslipFiles] = useState([]);

  // recent 3 months payslip
  const getPayslipFiles = async () => {
    setApiStatus(apiStatusConstants.inProgress);
    try {
      const response = await getRecentPayslipPdfFiles(paramsEmployeeId);
      if (response.status === 200) {
        const data = await response.json();
        const updatedData = data.map((eachItem) =>
          convertKeysToCamelCase(eachItem)
        );
        setPayslipFiles(updatedData);
        setApiStatus(apiStatusConstants.success);
      }
    } catch (error) {}
  };

  // single month payslip
  const fetchEmployeePaySlip = async () => {
    try {
      setSinglePayslipApi(singlePaySlipApiConstants.inProgress);

      const response = await getEmployeePayslipPdfFile(
        selectedYear,
        selectedMonth,
        paramsEmployeeId
      );

      if (response.status === 200) {
        const data = await response.json();
        const formattedData = {
          yearMonth: data.year_month,
          payslipUrl: data.payslip_url,
        };
        setSinglePayslipApi(singlePaySlipApiConstants.success);
        setSingleMonthPayslip(formattedData);
      } else if (response.status === 400) {
        setSingleMonthPayslip("");
        notifyPayslipFailure();
        setSinglePayslipApi(singlePaySlipApiConstants.payslipnotfound);
        // setApiStatus(apiStatusConstants.payslipnotfound);
      }
    } catch (err) {
      // setApiStatus(apiStatusConstants.failure);
      setSinglePayslipApi(singlePaySlipApiConstants.failure);
    }
  };

  const getMonth = (monthNumber) => {
    const date = new Date(2000, monthNumber - 1, 1);
    // Use the toLocaleString method to get the month name
    const monthName = date.toLocaleString("default", { month: "short" });
    return monthName; // Outputs "October"
  };

  const policyPdfModal = () => (
    <div className="modal-overlay">
      <div className="pdf-container">
        <div className="pdf-heading-container">
          <h4>Payslip</h4>
          <button className="cross-icon" onClick={() => setActivePayslip("")}>
            <BsX style={{ fontSize: "30px" }} />
          </button>
        </div>
        <iframe
          title="PDF Viewer"
          src={activePaySlip}
          width="100%"
          height="93%" // Adjust the height as needed
          style={{ marginTop: "10px" }}
        ></iframe>
      </div>
    </div>
  );

  const [activePaySlip, setActivePayslip] = useState();

  // function extractFilenameFromURL(url) {
  //   // Get the part of the URL after the last '/'
  //   const urlParts = url.split("/");
  //   let filenameWithExtension = urlParts[urlParts.length - 1];

  //   // Remove query parameters (if any)
  //   const queryParamIndex = filenameWithExtension.indexOf("?");
  //   if (queryParamIndex !== -1) {
  //     filenameWithExtension = filenameWithExtension.substring(
  //       0,
  //       queryParamIndex
  //     );
  //   }

  //   // Decode URI component to handle encoded characters
  //   const decodedFilename = decodeURIComponent(filenameWithExtension);

  //   return decodedFilename;
  // }

  // const handleDownload = (url) => {
  //   const downloadUrl = url;
  //   const extractedFilename = extractFilenameFromURL(url);

  //   console.log(url);
  //   // Create a new anchor element
  //   const link = document.createElement("a");
  //   link.href = downloadUrl;
  //   // link.target = "_blank"; // Open in a new tab, if needed
  //   link.download = extractedFilename; // Set the desired file name

  //   // Trigger a click event on the anchor element to start the download
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const renderPaySlipsList = () => (
    <div className="pt-2">
      <p>Recent Payslips</p>

      {paySlipFiles.length > 0 ? (
        <div className="d-flex ">
          {paySlipFiles.map((eachPayslip) => (
            <div className="payslip-card">
              <div className="month-card">
                <p
                  style={{ backgroundColor: primaryColor }}
                  className="month-bg"
                >
                  {getMonth(eachPayslip.yearMonth.split("-")[1])}
                </p>
                <p>{eachPayslip.yearMonth.split("-")[0]}</p>
              </div>

              <div>
                <p>
                  Payslip for {getMonth(eachPayslip.yearMonth.split("-")[1])}{" "}
                  {eachPayslip.yearMonth.split("-")[0]}
                </p>
                {/* <p>Net pay : {eachPayslip.netPay} /-</p> */}
                <div className="mt-2">
                  {/* <button
                  onClick={() => handleDownload(eachPayslip.payslipUrl)}
                  className="download-btn"
                  style={{ color: primaryColor }}
                >
                  {" "}
                  <MdFileDownload style={{ fontSize: "20px" }} />
                  Download
                </button> */}
                  <button
                    onClick={() => setActivePayslip(eachPayslip.payslipUrl)}
                    className="download-btn"
                    style={{ color: primaryColor }}
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <p>No Recent Payslips Generated</p>
        </div>
      )}
    </div>
  );

  const renderSinglePaySlip = () => (
    <div>
      {singleMonthPayslip !== "" ? (
        <div>
          <p>Payslip</p>
          <div>
            <div className="payslip-card">
              <div className="month-card">
                <p
                  style={{ backgroundColor: primaryColor }}
                  className="month-bg"
                >
                  {getMonth(singleMonthPayslip.yearMonth.split("-")[1])}
                </p>
                <p>{singleMonthPayslip.yearMonth.split("-")[0]}</p>
              </div>

              <div>
                <p>
                  Payslip for{" "}
                  {getMonth(singleMonthPayslip.yearMonth.split("-")[1])}{" "}
                  {singleMonthPayslip.yearMonth.split("-")[0]}
                </p>

                <div className="mt-2">
                  {/* <button
                    onClick={() =>
                      handleDownload(singleMonthPayslip.payslipUrl)
                    }
                    style={{ color: primaryColor }}
                    className="download-btn"
                  >
                    {" "}
                    <MdFileDownload style={{ fontSize: "20px" }} />
                    Download
                  </button> */}
                  <button
                    onClick={() =>
                      setActivePayslip(singleMonthPayslip.payslipUrl)
                    }
                    style={{ color: primaryColor }}
                    className="download-btn"
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p className="bg-warning">no payslip</p>
      )}
    </div>
  );

  const MonthsData = [
    { label: "January", value: "01" },
    { label: "February", value: "02 " },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];

  const renderData = () => {
    switch (apiStatus) {
      case apiStatusConstants.inProgress:
        return <LoadingView />;
      case apiStatusConstants.success:
        return renderPaySlipsList();
      case apiStatusConstants.failure:
        return (
          <FailureView
          // onClickRetry={onClickRetry}
          />
        );
      // case apiStatusConstants.payslipnotfound:
      //   return renderNoPaySlip();
      default:
        return null;
    }
  };

  const renderLoader = () => (
    <div
      style={{ height: "30px" }}
      className="d-flex justify-content-center align-items-center w-100"
    >
      <div className="spinner-border text-primary " role="status"></div>
    </div>
  );

  const renderSinglePaySlipData = () => {
    switch (singlePayslipApi) {
      case singlePaySlipApiConstants.inProgress:
        return renderLoader();
      case singlePaySlipApiConstants.success:
        return renderSinglePaySlip();
      case singlePaySlipApiConstants.failure:
        return null;
      case apiStatusConstants.payslipnotfound:
        return <p>No Payslip Found</p>;
      default:
        return null;
    }
  };

  return (
    <>
      <Header />
      <div className="d-flex">
        <LeftNavBar />
        <div
          className="payslipPage-container"
          style={{ backgroundColor: "#eff2f3" }}
        >
          <PageHeader>
            <PageHeaderName>Payslip</PageHeaderName>
            <div className="date-inputs-container">
              <select
                className="selectElement"
                onChange={(e) => setSelectedMonth(e.target.value)}
              >
                <option value="">Select Month</option>
                {MonthsData.map((eachMonth) => (
                  <option value={eachMonth.value}>{eachMonth.label}</option>
                ))}
              </select>
              <select
                className="selectElement"
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                <option value="">Select Year</option>
                {yearsList.map((eachYear) => (
                  <option value={eachYear.value}>{eachYear.label}</option>
                ))}
              </select>
            </div>
          </PageHeader>

          <div className="p-3 bg-white payslip-page-container">
            {renderSinglePaySlipData()}

            {role === "Admin" ? (
              renderData()
            ) : paramsEmployeeId === empId ? (
              renderData()
            ) : (
              <Navigate to="*" />
            )}
          </div>
        </div>
        {activePaySlip && policyPdfModal()}

        <Toaster position="top-center" reverseOrder={false} />
      </div>
    </>
  );
};

export default Payslip;
