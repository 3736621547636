import { Link } from "react-router-dom";
import { closeMobileMenu } from "../../redux/slices/mobileSlice";
import { useDispatch } from "react-redux";

import "./index.css";
import { getUserData } from "../../api/services/hroneApi";

const NavBarItems = (props) => {
  const { navitemDetails } = props;
  const { icon, navItemName, path, roles } = navitemDetails;

  const { role } = getUserData();

  const urlPath = window.location.pathname;
  const dispatch = useDispatch();

  let activePath = "";

  if (urlPath === "/") {
    activePath = urlPath;
  } else {
    activePath = urlPath.split("/")[1];
  }

  let currentNavPath = "";

  if (path === "/") {
    currentNavPath = path;
  } else {
    currentNavPath = path.split("/")[1];
  }
  const onClickNavItem = () => {
    dispatch(closeMobileMenu());
  };

  const highlightACtiveNav =
    currentNavPath === activePath ? "active-nav" : null;

  return (
    <>
      {roles.includes(role) && (
        <Link className="remove-line-nav-item" to={path}>
          <li
            onClick={onClickNavItem}
            className={`d-flex nav-item-container align-items-center ${highlightACtiveNav}`}
          >
            <div className="nav-item-icon">{icon}</div>
            <p style={{ fontSize: "16px" }} className="mt-4">
              {navItemName}
            </p>
          </li>
        </Link>
      )}
    </>
  );
};

export default NavBarItems;
