import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { format } from "date-fns";
import React, { useEffect, useMemo, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { BsX } from "react-icons/bs";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import "react-toastify/dist/ReactToastify.css";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import AddIncentiveForm from "../AddIncentiveForm";
import "../CommonCss/slidingModal.css";
import FailureView from "../FailureView";
import Header from "../Header";
import LeftNavBar from "../LeftNavBar";
import LoadingView from "../LoadingView";
import PageHeader from "../PageHeader";
import PopupModal from "../PopupModal";
import "./index.css";

import {
  auditFileExcelDownload,
  exportPayroll,
  getALlPayslips,
  getAllActiveEmployees,
} from "../../api/services/hroneApi";

import { PageHeaderName } from "../../StyledCss/styledComponents";
import RegeneratePayslipForm from "../RegeneratePayslip/regenPayslip";
import UploadLeaves from "../UploadLeavesExcel/uploadLeaves";

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const SalaryManagement = () => {
  const [employees, setEmployees] = useState([]);
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
  const [isIncentiveOpen, setIsIncentiveOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [employeesSalary, setEmployeesSalary] = useState([]);
  const [isPayrollDownload, setIsPayrollDownload] = useState(false);
  const [openPopupExcel, setOpenPopupExcel] = useState(false);
  const [isRegeneratePayslipOpen, setRegeneratePayslip] = useState(false);
  const [isAuditFileDownloaded, setIsAuditFileDownloaded] = useState(false);
  const NotifyPayrollDownloaded = () =>
    toast.success("Payroll Downloaded Successfully");

  const NotifyAuditDownloaded = () =>
    toast.success("Audit Downloaded Successfully");
  const modalRef = useRef(null);
  const date = new Date();

  const NotifyIncentiveAdded = () =>
    toast.success("Incentive Added Successfully");
  const NotifyIncentiveFailure = () => toast.error("Something Went Wrong");

  const closeRegeneratePayslip = () => {
    setRegeneratePayslip(false);
  };

  useEffect(() => {
    fetchAllEmployees();
    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("keydown", handleEscapeKeyClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("keydown", handleEscapeKeyClick);
    };
    // eslint-disable-next-line
  }, []);

  const handleEscapeKeyClick = (event) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target) &&
      event.key === "Escape"
    ) {
      closeBtn();
    }
  };

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeBtn();
    }
  };

  const failedPayrollDownload = (data) => {
    toast.error(`Error while generating ${data}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleExportData = async () => {
    setIsPayrollDownload(true);
    const formattedDate = format(date, "yyyy-MM-dd");
    try {
      const response = await exportPayroll(formattedDate);
      if (response.status === 200) {
        setIsPayrollDownload(false);
        const fileNameFromServer = response.headers["content-disposition"];
        const filename = fileNameFromServer
          ? fileNameFromServer.split("filename=")[1]
          : "payroll.xlsx";

        //here we are creating an download trigger through DOM
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(response.data);

        downloadLink.download = filename;
        downloadLink.click();
        NotifyPayrollDownloaded();

        URL.revokeObjectURL(downloadLink.href);
      } else {
        setIsPayrollDownload(false);
        failedPayrollDownload("payroll");
      }
    } catch (error) {
      setIsPayrollDownload(false);

      // console.log(error, "from hr-one api")
      // console.log(error.data)
      failedPayrollDownload("payroll");
    }
  };

  const openIncentive = () => {
    setIsIncentiveOpen(true);
  };

  const closeIncentive = () => {
    setIsIncentiveOpen(false);
  };

  const closeBtn = () => {
    setSelectedId(null);
  };
  const onClickRetry = () => fetchAllEmployees();

  const fetchAllEmployees = async () => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    const formattedMonth = format(currentDate, "yyyy-MM");
    try {
      setApiStatus(apiStatusConstants.inProgress);
      const response = await getAllActiveEmployees();
      const payslipresponse = await getALlPayslips(formattedMonth);
      if (response.status === 200 && payslipresponse.status === 200) {
        const data = await response.json();
        const salaryData = await payslipresponse.json();
        // console.log(salaryData);
        const updatedData = data.map((employee) => ({
          firstName: employee.first_name,
          lastName: employee.last_name,
          id: employee.id,
          email: employee.email,
          designation: employee.designation,
        }));

        setEmployees(updatedData);
        setEmployeesSalary(salaryData);
        setApiStatus(apiStatusConstants.success);
      }
    } catch (error) {
      setApiStatus(apiStatusConstants.failure);
    }
  };
  const onCloseExcelModal = () => {
    setOpenPopupExcel(false);
  };

  const columnDefs = [
    { headerName: "EMP ID", field: "id", filter: true, floatingFilter: true },
    {
      headerName: "NAME",
      valueGetter: function (params) {
        const firstName = params.data.firstName;
        const lastName = params.data.lastName;
        const combinedData = firstName + " " + lastName;
        return combinedData;
      },
      filter: true,
      floatingFilter: true,
      sortable: true,
      editable: true,
    },
    {
      headerName: "EMAIL",
      field: "email",
      filter: true,
      floatingFilter: true,
      tooltipField: "email",
      editable: true,
    },
    {
      headerName: "ROLE",
      field: "designation",
      filter: true,
      floatingFilter: true,
      tooltipField: "designation",
      editable: true,
    },
    {
      headerName: "PAYSLIP",
      cellRendererFramework: (params) => {
        const employee = params.data;
        return (
          <Link to={`/payslip/${employee.id}`} key={employee.id}>
            VIEW
          </Link>
        );
      },
    },
    {
      headerName: "SALARY DETAILS",
      cellRendererFramework: (params) => {
        const employee = params.data;

        return (
          <SecondaryButton
            style={{ marginTop: "0px" }}
            type="button"
            className="d-flex align-items-center"
            onClick={() => setSelectedId(employee.id)}
          >
            View Calculation
          </SecondaryButton>
        );
      },
    },
  ];

  const paginationSize = 12;

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { fontSize: "14px" },
    };
  }, []);

  const renderPayroll = () => {
    return (
      <div className="salary-container">
        <div
          style={{
            height: "75vh",
            // width: "85vw",
            fontSize: "14px",
            fontFamily: "Arial, Helvetica, sans-serif",
          }}
          className="ag-theme-alpine salary-table-container"
        >
          <AgGridReact
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={paginationSize}
            rowData={employees}
            defaultColDef={defaultColDef}
          />
        </div>
      </div>
    );
  };

  const renderData = () => {
    switch (apiStatus) {
      case apiStatusConstants.inProgress:
        return <LoadingView />;
      case apiStatusConstants.success:
        return renderPayroll();
      case apiStatusConstants.failure:
        return <FailureView onClickRetry={onClickRetry} />;
      default:
        return null;
    }
  };

  function renderSalaryCalculation(id) {
    if (Array.isArray(employeesSalary) && employeesSalary.length > 0) {
      const salary = employeesSalary.find((emp) => emp.employee_id === id);

      if (salary !== undefined) {
        // console.log(salary)
        const camelCase = {
          payslipMonth: salary.payslip_for_month,
          empName: `${salary.first_name} ${salary.last_name}`,
          package: salary.package,
          workingDays: salary.working_days,
          basic: salary.basic,
          hra: salary.hra,
          specialAllowance: salary.special_allowance,
          extraWorkingDays: salary.extra_working_days,
          others: salary.others,
          grossEarnings: salary.gross_earnings,
          providentFund: salary.provident_fund,
          professionalTax: salary.professional_tax,
          tds: salary.tds,
          lopDays: salary.lop_days,
          lopAmount: salary.lop_amount,
          grossDeductions: salary.gross_deductions,
          netPay: salary.net_pay,
        };

        return (
          <>
            {Object.entries(camelCase).map(([key, value]) => (
              <>
                <tr key={key}>
                  <th key={key}>{key}</th>
                  <td key={value}>{value}</td>
                </tr>
              </>
            ))}
          </>
        );
      } else {
        return <strong>Payslip is not generated yet</strong>;
      }
    } else {
      return <strong>Loading salary data...</strong>; // You can show a loading message or handle the empty state.
    }
  }

  const downloadAuditFile = async () => {
    setIsAuditFileDownloaded(true);

    try {
      const response = await auditFileExcelDownload();
      if (response.status === 200) {
        setIsAuditFileDownloaded(false);
        const fileNameFromServer = response.headers["content-disposition"];
        const filename = fileNameFromServer
          ? fileNameFromServer.split("filename=")[1]
          : "audit.xlsx";

        //here we are creating an download trigger through DOM
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(response.data);

        downloadLink.download = filename;
        downloadLink.click();
        NotifyAuditDownloaded();
        URL.revokeObjectURL(downloadLink.href);
      } else {
        setIsAuditFileDownloaded(false);
        failedPayrollDownload("audit");
      }
    } catch (error) {
      setIsAuditFileDownloaded(false);
      failedPayrollDownload("audit");
    }
  };

  return (
    <>
      <Header />
      <div className={selectedId ? "apply-opacity d-none" : "d-flex"}>
        <LeftNavBar />
        <div className="salary-page">
          <PageHeader>
            <PageHeaderName>Salary Management</PageHeaderName>
            <div className="d-flex flex-row justify-content-end">
              <div className="generate-btn">
                <PrimaryButton
                  onClick={openIncentive}
                  // className="apply-leave-btn"
                  type="button"
                >
                  Add Incentive
                </PrimaryButton>
              </div>

              <div className="generate-btn">
                {isPayrollDownload ? (
                  <PrimaryButton className="generating-payroll">
                    Generating
                    <ClipLoader
                      className="loading-payroll"
                      color="#686768"
                      size={25}
                    />
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    onClick={handleExportData}
                    // className="apply-leave-btn"
                    type="button"
                  >
                    Generate Payroll
                  </PrimaryButton>
                )}
              </div>

              <div className="generate-btn">
                <PrimaryButton
                  onClick={() => setRegeneratePayslip(!isRegeneratePayslipOpen)}
                  // className="apply-leave-btn"
                  type="button"
                >
                  Generate Payslip
                </PrimaryButton>
              </div>

              <div className="generate-btn">
                {isAuditFileDownloaded ? (
                  <PrimaryButton className="generating-payroll">
                    Generating
                    <ClipLoader
                      className="loading-payroll"
                      color="#686768"
                      size={25}
                    />
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    onClick={downloadAuditFile}
                    // className="apply-leave-btn"
                    type="button"
                  >
                    Generate Audit
                  </PrimaryButton>
                )}
              </div>

              <div className="generate-btn">
                <PrimaryButton onClick={() => setOpenPopupExcel(true)}>
                  Import Leaves
                </PrimaryButton>
              </div>
            </div>
          </PageHeader>

          <div className="salary-management-table-container">
            {renderData()}
          </div>
        </div>
      </div>
      {selectedId && (
        <div className="calculation-modal shadow" ref={modalRef}>
          <div className="close-btn-div">
            <BsX className="close-btn" onClick={closeBtn} />
          </div>

          <div className="employee-salary-div">
            <table>
              <tbody>{renderSalaryCalculation(selectedId)}</tbody>
            </table>
          </div>
        </div>
      )}

      <PopupModal
        modalName="Upload File"
        closeBtn={onCloseExcelModal}
        showModal={openPopupExcel}
      >
        <UploadLeaves setOpenPopupExcel={setOpenPopupExcel} />
      </PopupModal>

      <PopupModal
        modalName="Add Incentive"
        closeBtn={closeIncentive}
        showModal={isIncentiveOpen}
      >
        <AddIncentiveForm
          NotifyIncentiveAdded={NotifyIncentiveAdded}
          NotifyIncentiveFailure={NotifyIncentiveFailure}
          closeBtn={closeIncentive}
        />
      </PopupModal>

      {/* <SlidingModal
        modalName="Add Incentive"
        closeBtn={closeIncentive}
        isModalOpen={isIncentiveOpen}
      >
        <AddIncentiveForm
          NotifyIncentiveAdded={NotifyIncentiveAdded}
          NotifyIncentiveFailure={NotifyIncentiveFailure}
          closeBtn={closeIncentive}
        />
      </SlidingModal> */}

      <PopupModal
        modalName="Regenerate Payslip"
        closeBtn={closeRegeneratePayslip}
        showModal={isRegeneratePayslipOpen}
      >
        <RegeneratePayslipForm
          closeBtn={closeRegeneratePayslip}
          setRegeneratePayslip={setRegeneratePayslip}
        />
      </PopupModal>

      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default SalaryManagement;
