/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./index.css";

import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
  StyledSelect,
  StyledSelectContainer,
  StyledSelectGroup,
} from "../../StyledCss/styledInputs";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import { applyUserExtraWork, getUserData } from "../../api/services/hroneApi";
import { ErrorText } from "../../StyledCss/styledComponents";

const Extraworkform = ({
  hrList,
  extraworkApplied,
  notifySuccess,
  closeBtn,
}) => {
  const [lastMonth, setLastMonth] = useState("");
  const [currentMonth, setCurrentMonth] = useState("");

  useEffect(() => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    let month = today.getMonth() - 1;
    let year = today.getFullYear();
    const day = today.getDate();

    if (month < 0) {
      month = 12;
    } else if (month < 10) {
      month = `0${month + 1}`;
    }

    if (currentMonth < month) {
      year = year - 1;
    }

    const formattedLastMonth = `${year}-${month}-${day}`;

    setLastMonth(formattedLastMonth);
  }, []);

  useEffect(() => {
    const today = new Date();
    let month = today.getMonth() + 1;
    const year = today.getFullYear();
    const day = today.getDate();

    if (month < 10) {
      month = `0${month}`;
    }
    const formattedCurrentMonth = `${year}-${month}-${day}`;
    setCurrentMonth(formattedCurrentMonth);
  }, []);

  const [errorMsg, setErrorMsg] = useState("");
  const { empId, email } = getUserData();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    handleApplyReimbursement(data);
  };

  const handleApplyReimbursement = async (formData) => {
    const data = {
      employee_id: empId,
      employee_email: email,
      hr_email: formData.lead,
      date: formData.date,
      hours: formData.hours,
      status: "Awaiting",
    };

    try {
      const response = await applyUserExtraWork(data);

      if (response.status === 200) {
        extraworkApplied();
        notifySuccess();
      } else {
        const data = await response.json();
        setErrorMsg(data.detail);
      }
    } catch (error) {}
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex flex-column gap-4 mt-3"
    >
      <div className="d-flex gap-3 apply-reimbursement-container">
        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={errors.date && { borderLeft: "10px solid red" }}
              min={lastMonth}
              max={currentMonth}
              type="date"
              name="date"
              autoComplete="off"
              {...register("date", { required: true })}
            />
            <StyledLabel hasValue={true} className="user-label">
              Select Date*
            </StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={errors.hours && { borderLeft: "10px solid red" }}
              {...register("hours", { required: true, pattern: /^[1-8]$/ })}
              type="text"
            />
            <StyledLabel hasValue={true} className="user-label">
              Select Hours*
            </StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        <StyledSelectGroup>
          <StyledSelectContainer>
            <StyledSelect
              style={errors.lead && { borderLeft: "10px solid red" }}
              {...register("lead", { required: true })}
            >
              <option value="">Select Your Lead :</option>
              {Array.isArray(hrList) &&
                hrList.map((hr) => (
                  <option key={hr} value={hr}>
                    {hr}
                  </option>
                ))}
            </StyledSelect>
            <StyledLabel className="user-label">Select Your Lead*</StyledLabel>
          </StyledSelectContainer>
        </StyledSelectGroup>
      </div>

      {errors.hours && (
        <ErrorText>*The value should be between 1 to 8</ErrorText>
      )}

      {errorMsg && <ErrorText>*{errorMsg}</ErrorText>}

      <div className="d-flex justify-content-end gap-3">
        <SecondaryButton type="button" onClick={closeBtn}>
          Cancel
        </SecondaryButton>
        <PrimaryButton type="submit">Submit</PrimaryButton>
      </div>
    </form>
  );
};

export default Extraworkform;
