import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orgInfo: {},
};

export const organizationInfoSlice = createSlice({
  name: "OrganizationInfo",
  initialState,
  reducers: {
    updateOrganizationInfo: (state, action) => {
      state.orgInfo = action.payload;
    },
    
  },
});

export const { updateOrganizationInfo} = organizationInfoSlice.actions;
export default organizationInfoSlice.reducer;
