import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "../CommonCss/slidingModal.css";
import LeaveHistoryTable from "../LeaveHistoryTable";
import "./index.css";


const LeaveManagement = ({ refetchData }) => {
  const date = new Date();
  const [startMonth, setStartMonth] = useState(date);
  const [endMonth, setEndMonth] = useState(date);

  const leavesComponent = () => {
    return (
      <div style={{ width: "100%" }}>
        <div className="leave-history-container">
          <div className="d-flex flex-row flex-wrap justify-content-end align-items-center mb-1">
            <div className="month-picker d-flex flex-column m-1">
              <label className="mb-1 ">Start Month</label>
              <DatePicker
                className="date-input-label"
                selected={startMonth}
                onChange={(date) => {
                  if (date) {
                    setStartMonth(date)
                  } else {
                    setStartMonth(new Date());
                  }
                }}
                showMonthYearPicker
                dateFormat="MMMM yyyy"


              />
            </div>
            <div className="month-picker d-flex flex-column m-1">
              <label className="mb-1 ">End Month</label>
              <DatePicker
                className="date-input-label"
                selected={endMonth}
                onChange={(date) => {
                  if (date) {
                    setEndMonth(date)
                  } else {
                    setEndMonth(new Date());
                  }
                }}
                showMonthYearPicker
                dateFormat="MMMM yyyy"
              />
            </div>
          </div>

          <div>
            <LeaveHistoryTable
              startMonth={startMonth}
              endMonth={endMonth}
              refetchData={refetchData}
            />
          </div>
        </div>
      </div>
    );
  };

  return <div>{leavesComponent()}</div>;
};

export default LeaveManagement;
