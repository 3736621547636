import { createSlice } from "@reduxjs/toolkit";

const jwtToken = localStorage.getItem("loginDetails");

const initialState = {
    notificationsList: [],
    deviceToken: "",
    userLogged: jwtToken ? true : false
};

export const notifications = createSlice({
    name: "notifications",

    initialState,
    reducers: {
        setNotifications: (state, action) => {
            // Check if action.payload is an array or a single object
            if (Array.isArray(action.payload)) {
                // If it's an array, concatenate it with the existing notificationsList
                state.notificationsList = [...state.notificationsList, ...action.payload];
            } else {
                // If it's a single object, push it to the notificationsList
                state.notificationsList.unshift(action.payload);
            }
        },
        clearAllNotifications: (state, action) => {
            state.notificationsList = []
        },
        markNotificationsAsRead: (state, action) => {
            state.notificationsList = action.payload
        },
        setDeviceToken: (state, action) => {
            state.deviceToken = action.payload
        },
        setUserLoggedIn: (state, action) => {
            state.userLogged = true
        }
    },
});

export const {
    setNotifications,
    clearAllNotifications,
    setDeviceToken,
    markNotificationsAsRead,
    setUserLoggedIn
} = notifications.actions;

export default notifications.reducer;

// Action creators are generated for each case reducer function