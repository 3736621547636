import React from "react";
import { Button } from "../../StyledCss/styledbtn";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import "./tenantform.css";
import { addTenantData } from "../../api/services/hroneApi";

function TenantForm({ refreshData, setAddTenantModal }) {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const notifyTenantAdded = () => toast.success("Tenant Added Successfully");
  const notifyError = () => toast.error("Something Went Wrong");
  const onSubmit = async (data) => {
    try {
      const response = await addTenantData(data);

      if (response.status === 200) {
        reset();
        setAddTenantModal();
        notifyTenantAdded();
        setTimeout(() => {
          refreshData();
        }, 1000);
      } else {
      }
    } catch (error) {
      notifyError();
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="expenditure-form"
      autoComplete="off"
    >
      <div className="d-flex align-items-center justify-content-between">
        <h3>Add Tenant</h3>
      </div>

      <hr />

      <label className="mt-2">Organization Name</label>
      <input
        name="organization_name"
        className={
          errors.organization_name ? "highlight-error" : "tenant-form-input"
        }
        type="text"
        {...register("organization_name", {
          required: true,
          pattern: /^(?!\s)[a-zA-Z\s]{3,45}$/,
        })}
      />

      <label className="mt-2">Organization Code</label>
      <input
        name="organization_code"
        className={
          errors.organization_code ? "highlight-error" : "tenant-form-input"
        }
        type="text"
        {...register("organization_code", {
          required: true,
          pattern: /^(?!\s)[a-zA-Z\s]{2,3}$/,
        })}
      />

      <label className="mt-2">Email</label>
      <input
        name="contact_email"
        className={
          errors.contact_email ? "highlight-error" : "tenant-form-input"
        }
        type="email"
        {...register("contact_email", {
          required: true,
          pattern: /^(?!\s)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        })}
      />

      {/* <div>
        <label className="mt-2">Password</label>

        <input
          className={
            errors.password ? "highlight-input-error" : "tenant-form-input"
          }
          type="password"
          placeholder="Password"
          {...register("password", {
            required: true,
            minLength: 8,
            maxLength: 16,
          })}
        />
      </div> */}

      <label className="mt-2">Contact Number</label>
      <input
        name="contact_phone"
        className={
          errors.contact_phone ? "highlight-error" : "tenant-form-input"
        }
        maxLength="10"
        type="tel"
        {...register("contact_phone", {
          required: true,
          maxLength: 10,
          pattern: /[0-9]{10}/,
        })}
      />

      <label className="mt-2">Status</label>
      <select
        className={errors.status ? "highlight-error" : "tenant-form-input"}
        name="status"
        {...register("status", { required: true })}
      >
        <option value="">---select---</option>

        <option value="Active">Active</option>
        <option value="Inactive">Inactive</option>
      </select>

      <label className="mt-2">Organization URL</label>
      <input
        placeholder="https://organizationurl.com"
        name="organization_url"
        className={
          errors.organization_url ? "highlight-error" : "tenant-form-input"
        }
        type="url"
        {...register("organization_url", { required: true })}
      />

      <label className="mt-2">Sub Domain URL</label>
      <input
      placeholder="https://subdomainurl.com"
        name="urbuddi_url"
        className={errors.urbuddi_url ? "highlight-error" : "tenant-form-input"}
        type="url"
        {...register("urbuddi_url", { required: true })}
      />

      <div>
        <Button>SUBMIT</Button>
      </div>
    </form>
  );
}

export default TenantForm;
