/* eslint-disable react-hooks/exhaustive-deps */
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { getDate, getMonth, getYear } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import {
  exportResources,
  getResource,
  updateResource,
} from "../../api/services/hroneApi";
import formatDate from "../../util/formatdate";
import AddResourceForm from "../AddResourceForm/addResourceForm";
import FailureView from "../FailureView";
import Header from "../Header";
import LeftNavBar from "../LeftNavBar";
// import LoadingView from "../LoadingView";
import { useForm } from "react-hook-form";
import { ErrorText, PageHeaderName } from "../../StyledCss/styledComponents";
import ExcessOrReleasedResources from "../ExcessOrReleasedResorces/index";
import PageHeader from "../PageHeader";
import ProfileDetailsTabs from "../ProfileDetailsTabs";
import ResourceTrackingSummary from "../ResourceTrackingSummary/resourceTrackingSummary";
import SlidingModal from "../SlidingModal/slidingModal";
import UploadResourcesForm from "../UploadResourceExcel/uploadresoucesForm";
import "./index.css";

import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
  StyledSelect,
  StyledSelectContainer,
  StyledSelectGroup,
} from "../../StyledCss/styledInputs";
import PopupModal from "../PopupModal";
import ResourceTrackingHistory from "../ResourceTrackingHistory";
import LoadingView from "../LoadingView";
const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const tabData = [
  { id: "ALL", tabName: "All" },
  { id: "SUMMARY", tabName: "Summary" },
  { id: "RELEASED-EXCESS", tabName: "Released/Excess" },
  { id: "TRACKING-HISTORY", tabName: "Tracking History" },
];

const ResourceTracking = () => {
  const [activeTrackingGadget, setActiveTrackingGadget] = useState("All");
  const [addResourceClicked, setAddResourceClicked] = useState(false);
  const [resourcesData, setResourceData] = useState([]);
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
  const [openPopupExcel, setOpenPopupExcel] = useState(false);
  const [deviceCount, setDeviceCount] = useState();
  const [activeTabName, setActiveTab] = useState(tabData[0].id);
  const [releaseClicked, setReleaseClicked] = useState(false);
  const [releaseData, setReleaseData] = useState({});
  // const [releasedToID, setReleasedToId] = useState("");
  // const [idError, setIdError] = useState(false);

  // console.log(releaseData);
  const notifyReleased = () => toast.success("Resource Released Successfully");

  useEffect(() => {
    getResourceDetails();
  }, [activeTrackingGadget]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const empIdField = watch("releasedToID");
  const countValue = watch("count");

  useEffect(() => {
    setReleaseFormError(""); // Reset releaseFormError to an empty string
  }, [empIdField]);

  const handleExportResources = async () => {
    try {
      const response = await exportResources(activeTrackingGadget);
      if (response.status === 200) {
        // setIsPayrollDownload(false)
        setOpenPopupExcel(false);
        const fileNameFromServer = response.headers["content-disposition"];

        const filename = fileNameFromServer
          ? fileNameFromServer.split("filename=")[1]
          : "resources.xlsx";

        //here we are creating an download trigger through DOM
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(response.data);

        downloadLink.download = filename;
        downloadLink.click();
        URL.revokeObjectURL(downloadLink.href);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getResourceDetails();
  }, [activeTrackingGadget]);

  useEffect(() => {
    if (activeTabName === "ALL") {
      getResourceDetails();
    }
  }, [activeTabName]);

  const closeAddResource = () => {
    setAddResourceClicked(false);
  };

  const onCloseExcelModal = () => {
    setOpenPopupExcel(false);
  };

  const getResourceDetails = async () => {
    setApiStatus(apiStatusConstants.inProgress);

    try {
      const response = await getResource(activeTrackingGadget);
      if (response.ok) {
        const data = await response.json();

        const activeDeviceData = data.filter(
          (eachItem) => eachItem.status === "Active"
        );

        const updatedData = activeDeviceData.map((eachItem) => ({
          employeeId: eachItem.employee_id,
          firstName: eachItem.first_name,
          lastName: eachItem.last_name,
          deviceType: eachItem.device_type,
          model: eachItem.model,
          serialId: eachItem.serial_id,
          count: eachItem.count,
          allocatedDate: formatDate(eachItem.allocated_date),
          releasedDate:
            eachItem.released_date !== null
              ? formatDate(eachItem.released_date)
              : "",
          status: eachItem.status,
          id: eachItem.id,
        }));
        setDeviceCount(updatedData.length);
        setResourceData(updatedData);
        setApiStatus(apiStatusConstants.success);
      }
    } catch (error) {
      setApiStatus(apiStatusConstants.failure);
    }
  };

  const onChangeGadgets = (e) => {
    setActiveTrackingGadget(e.target.value);
  };

  const [releaseFormError, setReleaseFormError] = useState("");

  const onClickRelease = async (data) => {
    const Presentdate = new Date();
    const date = getDate(Presentdate);
    const month = getMonth(Presentdate) + 1;
    const year = getYear(Presentdate);
    let currentMonth = null;
    let currentDate = null;

    if (month < 10) {
      currentMonth = `0${month}`;
    } else {
      currentMonth = month;
    }

    if (date < 10) {
      currentDate = `0${date}`;
    } else {
      currentDate = date;
    }

    const releasedate = `${year}-${currentMonth}-${currentDate}`;

    const dataReleased = {
      id: releaseData.data.id,
      status: "Released",
      released_date: releasedate,
      assign_to_id: data.releasedToID,
      released_from_id: releaseData.data.employeeId,
      count: data.count ? parseInt(data.count) : 1,
      model: releaseData.data.model,
      device_type: releaseData.data.deviceType,
      serial_id: releaseData.data.serialId,
    };

    // console.log(dataReleased, "data released");

    try {
      const response = await updateResource(dataReleased);

      if (response.status === 200) {
        getResourceDetails();
        notifyReleased();
        closePopUpModal();
      }
    } catch (error) {
      console.log(error.response.data.detail);
      setReleaseFormError(error.response.data.detail);
    }
  };

  const showReleaseButton = (params) => (
    <SecondaryButton
      className="reject-btn d-flex  justify-content-center align-items-center"
      onClick={() => {
        setReleaseClicked(true);
        setReleaseData(params);
      }}
    >
      Release
    </SecondaryButton>
  );

  const onSubmit = (data) => {
    onClickRelease(data);
  };

  // console.log(typeof releaseData.data.count);

  const releaseForm = () => {
    const releaseDataExistsreleaseForm = releaseData && releaseData.data;
    return (
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={errors.releasedToID && { borderLeft: "10px solid red" }}
              {...register("releasedToID", {
                required: true,

                pattern: /^(?!\s)[a-zA-Z0-9]{3,10}$/,
              })}
              type="text"
            />
            <StyledLabel className="user-label">Emp ID*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        {releaseDataExistsreleaseForm &&
          releaseData.data.deviceType === "LAN" && (
            <StyledInputGroup className="mt-3">
              <StyledInputContainer>
                <StyledInput
                  type="text"
                  defaultValue={releaseData.data.count}
                  style={errors.count && { borderLeft: "10px solid red" }}
                  {...register("count", {
                    required: true,
                  })}
                />
                <StyledLabel hasValue={true} className="user-label">
                  Count*
                </StyledLabel>
              </StyledInputContainer>
            </StyledInputGroup>
          )}

        {errors.count && (
          <ErrorText style={{ marginTop: "10px" }}>
            *Count should be less than or equal to {releaseData.data.count}
          </ErrorText>
        )}

        {countValue &&
          (countValue > releaseData.data.count || countValue < 1) && (
            <ErrorText style={{ marginTop: "10px" }}>
              *Count should be less than or equal to {releaseData.data.count}
            </ErrorText>
          )}

        {releaseFormError && empIdField !== "" && (
          <ErrorText style={{ marginTop: "10px" }}>
            *{releaseFormError}
          </ErrorText>
        )}

        <div className="mt-3 d-flex gap-2 justify-content-end">
          <SecondaryButton onClick={closePopUpModal} type="button">
            Cancel
          </SecondaryButton>
          <PrimaryButton disabled={releaseButtonDisabled} type="submit">
            Release
          </PrimaryButton>
        </div>
      </form>
    );
  };

  const releaseButtonDisabled =
    countValue &&
    (countValue > releaseData.data.count ||
      countValue < 1 ||
      countValue === "");

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { fontSize: "14px" },
    };
  }, []);

  const renderTable = () => {
    const paginationPageSize = 50;
    const columnDefs = [
      {
        headerName: "EMP ID",
        field: "employeeId",
        width: 150,
        filter: true,
        floatingFilter: true,
        pinned: "left",
        editable: true,
      },
      {
        headerName: "NAME",
        valueGetter: function (params) {
          const firstName = params.data.firstName;
          const lastName = params.data.lastName;
          const combinedData = firstName + " " + lastName;
          return combinedData;
        },
        filter: true,
        sortable: true,
        floatingFilter: true,
        pinned: "left",
        editable: true,
      },
      {
        headerName: "DEVICE TYPE",
        field: "deviceType",
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "MODEL",
        field: "model",
        width: 130,
        filter: true,
        floatingFilter: true,
      },

      {
        headerName: "SERIAL ID",
        field: "serialId",
        width: 200,
        filter: true,
        floatingFilter: true,
        tooltipField: "serialId",
        editable: true,
      },

      {
        headerName: "COUNT",
        field: "count",
        width: 150,
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "ALLOCATED DATE",
        field: "allocatedDate",
        width: 200,
        filter: true,
        floatingFilter: true,
      },

      {
        headerName: "RELEASED DATE",
        field: "releasedDate",
        width: 200,
        filter: true,
        floatingFilter: true,
      },

      {
        headerName: "STATUS",
        field: "status",
        width: 150,
        filter: true,
        floatingFilter: true,
      },

      {
        headerName: "ACTION",
        cellRenderer: (params) => showReleaseButton(params),
      },
    ];

    return (
      <div
        className="ag-theme-alpine"
        style={{
          height: "70vh",
          fontSize: "14px",
        }}
      >
        <AgGridReact
          title="All Resources"
          columnDefs={columnDefs}
          rowData={resourcesData}
          pagination={true}
          paginationPageSize={paginationPageSize}
          defaultColDef={defaultColDef}
        ></AgGridReact>
      </div>
    );
  };

  const onClickRetry = () => {
    getResourceDetails();
  };

  // const renderLoader = () => (
  //   <div
  //     style={{ height: "60vh" }}
  //     className="d-flex justify-content-center align-items-center w-100"
  //   >
  //     <div className="spinner-border text-primary " role="status"></div>
  //   </div>
  // );

  const resourceTracking = () => {
    switch (apiStatus) {
      case apiStatusConstants.inProgress:
        return <LoadingView />;
      case apiStatusConstants.success:
        return renderTable();
      case apiStatusConstants.failure:
        return <FailureView onClickRetry={onClickRetry} />;
      default:
        return null;
    }
  };

  const renderTabs = () => (
    <ul style={{ listStyleType: "none" }} className="d-flex">
      {tabData.map((eachItem) => (
        <ProfileDetailsTabs
          tabData={eachItem}
          activeTab={eachItem.id === activeTabName}
          setActiveTab={setActiveTab}
        />
      ))}
    </ul>
  );

  const renderActiveTabDetails = () => {
    switch (activeTabName) {
      case "ALL":
        return resourceTracking();
      case "SUMMARY":
        return <ResourceTrackingSummary />;
      case "RELEASED-EXCESS":
        return <ExcessOrReleasedResources />;
      case "TRACKING-HISTORY":
        return <ResourceTrackingHistory />;
      default:
        break;
    }
  };

  const closePopUpModal = () => {
    setReleaseClicked(false);
    reset();
  };

  return (
    <div>
      <Header />
      <div className="d-flex">
        <LeftNavBar />
        <div className="resourceTrackingContainer" style={{ width: "100%" }}>
          <PageHeader>
            <PageHeaderName>Resource Tracking</PageHeaderName>
            <div>
              <PrimaryButton
                style={{ marginLeft: "15px" }}
                type="button"
                onClick={() => setAddResourceClicked(true)}
              >
                Add Resource
              </PrimaryButton>

              <PrimaryButton
                width="150px"
                style={{ marginLeft: "10px", marginRight: "10px" }}
                onClick={() => setOpenPopupExcel(!openPopupExcel)}
              >
                Import Excel Sheet
              </PrimaryButton>
              <PrimaryButton
                style={{ marginRight: "20px" }}
                type="button"
                onClick={handleExportResources}
              >
                Export Resource
              </PrimaryButton>
            </div>
          </PageHeader>
          <div className="resource-tracking-container">
            <div className="d-flex justify-content-between">
              {renderTabs()}

              {activeTabName === "ALL" && (
                <div className="d-flex align-items-center justify-content-end me-5 mb-3">
                  <StyledSelectGroup>
                    <StyledSelectContainer>
                      <StyledSelect
                        width="150px"
                        value={activeTrackingGadget}
                        onChange={onChangeGadgets}
                      >
                        <option value="All">All </option>
                        <option value="Laptop">Laptop </option>
                        <option value="Mobile">Mobile </option>
                        <option value="Mouse">Mouse </option>
                        <option value="LAN">LAN </option>
                        <option value="Others">Others </option>
                      </StyledSelect>
                      <StyledLabel className="user-label">
                        Device Type
                      </StyledLabel>
                    </StyledSelectContainer>
                  </StyledSelectGroup>

                  <div
                    style={{ border: `1px solid #000000` }}
                    className="resourceCount"
                  >
                    <p>{deviceCount}</p>
                  </div>
                </div>
              )}
            </div>
            {renderActiveTabDetails()}
          </div>

          {/* {resourceTracking()} */}
        </div>

        <SlidingModal
          modalName="Add Resource"
          closeBtn={closeAddResource}
          isModalOpen={addResourceClicked}
        >
          <AddResourceForm
            closeAddResource={closeAddResource}
            setAddResourceClicked={setAddResourceClicked}
            getResourceDetails={getResourceDetails}
          />
        </SlidingModal>

        <PopupModal
          modalName="Assign To"
          closeBtn={closePopUpModal}
          showModal={releaseClicked}
        >
          {releaseForm()}
        </PopupModal>

        <PopupModal
          modalName="Upload File"
          closeBtn={onCloseExcelModal}
          showModal={openPopupExcel}
        >
          <UploadResourcesForm setOpenPopupExcel={setOpenPopupExcel} />
        </PopupModal>

        <Toaster position="top-center" reverseOrder={false} />
      </div>
    </div>
  );
};

export default ResourceTracking;
