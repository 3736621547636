import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { TabHeading } from "../../StyledCss/styledCss";
import { PrimaryButton } from "../../StyledCss/styledbtn";
import {
  getEmployeeLead,
  getOrganizationLeads,
  getUserCertificates,
  updateAssignedLead,
} from "../../api/services/hroneApi";
import { notifySuccess } from "../../util/react-toaster";
import "./index.css";
const Others = () => {
  const [certificatesList, setCertificatesList] = useState({});
  const [leads, setLeads] = useState([]);
  const [assignedLead, setAssignedLead] = useState("");

  useEffect(() => {
    getCertificates();
    getAllLeads();
    getAssignedLead();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCertificates = async () => {
    try {
      const response = await getUserCertificates();
      if (response.ok) {
        const data = await response.json();
        const keysWithTrueValues = Object.keys(data[0]).filter(
          (key) => data[0][key] === true
        );
        setCertificatesList(keysWithTrueValues);
      }
    } catch (error) {}
  };

  const getAllLeads = async () => {
    try {
      const response = await getOrganizationLeads();
      if (response.status === 200) {
        const data = await response.json();

        setLeads(data);
      }
    } catch (error) {}
  };

  const getAssignedLead = async () => {
    try {
      const response = await getEmployeeLead();
      if (response.status === 200) {
        const data = await response.json();
        if (data.length > 0) {
          setAssignedLead(data[0]);
          console.log(data);
        }
      }
    } catch (error) {}
  };

  const certificatesLength = Object.values(certificatesList).length;

  const handleLeadChange = (e) => {
    setAssignedLead(e.target.value);
  };

  const updateLead = async () => {
    try {
      const response = await updateAssignedLead(assignedLead);
      if (response.status === 200) {
        notifySuccess("You have updated your lead successfully");
      }
    } catch (error) {}
  };

  return (
    <div>
      <TabHeading>Certificates Submitted</TabHeading>
      <hr />
      <ol>
        {certificatesLength > 0 ? (
          certificatesList.map((eachItem, index) => {
            if (eachItem === "original_degree") {
              const splitText = eachItem.split("_");
              const formattedText =
                splitText[0].charAt(0).toUpperCase() +
                splitText[0].substring(1) +
                " " +
                splitText[1][0].toUpperCase() +
                splitText[1].substring(1);
              return <li key={index}>{formattedText}</li>;
            }
            return (
              <li key={index}>
                {eachItem === "tenth"
                  ? "10th"
                  : eachItem[0].toUpperCase() + eachItem.substring(1)}
              </li>
            );
          })
        ) : (
          <p>No certificates Submitted</p>
        )}
      </ol>

      <hr />
      <div className="d-flex  justify-content-between ">
        {/* {resetPassword()} */}
        <div className="ml-3">
          <TabHeading>Assigned Lead</TabHeading>
          <label>Select Your Lead</label>
          <select
            value={assignedLead && assignedLead}
            className="select-input "
            onChange={handleLeadChange}
          >
            <option value="">---select---</option>
            {leads.map((eachItem, index) => (
              <option value={eachItem} key={index}>
                {eachItem}
              </option>
            ))}
          </select>
          <div className="mt-3">
            <PrimaryButton onClick={updateLead}>Submit</PrimaryButton>
          </div>
        </div>
      </div>

      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default Others;
