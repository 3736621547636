/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { getResource } from "../../api/services/hroneApi";
import formatDate from "../../util/formatdate";
import LoadingView from "../LoadingView";

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const ResourceTrackingHistory = () => {
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
  // const [activeTrackingGadget, setActiveTrackingGadget] = useState("All");
  // const [deviceCount, setDeviceCount] = useState();
  const [resourcesData, setResourceData] = useState([]);

  useEffect(() => {
    getResourceDetails();
  }, []);

  const getResourceDetails = async () => {
    setApiStatus(apiStatusConstants.inProgress);

    try {
      const response = await getResource("All");
      if (response.ok) {
        const data = await response.json();
        const deviceData = data.filter(
          (eachItem) => eachItem.status !== "Not Assigned"
        );

        const updatedData = deviceData.map((eachItem) => ({
          employeeId: eachItem.employee_id,
          firstName: eachItem.first_name,
          lastName: eachItem.last_name,
          deviceType: eachItem.device_type,
          model: eachItem.model,
          serialId: eachItem.serial_id,
          count: eachItem.count,
          allocatedDate: formatDate(eachItem.allocated_date),
          releasedDate:
            eachItem.released_date !== null
              ? formatDate(eachItem.released_date)
              : "",
          status: eachItem.status,
        }));
        // setDeviceCount(updatedData.length);
        setResourceData(updatedData);
        setApiStatus(apiStatusConstants.success);
      }
    } catch (error) {
      setApiStatus(apiStatusConstants.failure);
    }
  };

  const renderTable = () => {
    const paginationPageSize = 50;
    const columnDefs = [
      {
        headerName: "EMP ID",
        field: "employeeId",
        width: 150,
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "NAME",
        valueGetter: function (params) {
          const firstName = params.data.firstName;
          const lastName = params.data.lastName;
          const combinedData = firstName + " " + lastName;
          return combinedData;
        },
        filter: true,
        sortable: true,
        floatingFilter: true,
        editable: true,
      },
      {
        headerName: "DEVICE TYPE",
        field: "deviceType",
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "MODEL",
        field: "model",
        width: 130,
        filter: true,
        floatingFilter: true,
      },

      {
        headerName: "SERIAL ID",
        field: "serialId",
        width: 200,
        filter: true,
        floatingFilter: true,
        tooltipField: "serialId",
        editable: true,
      },

      {
        headerName: "COUNT",
        field: "count",
        width: 200,
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "ALLOCATED DATE",
        field: "allocatedDate",
        width: 200,
        filter: true,
        floatingFilter: true,
      },

      {
        headerName: "RELEASED DATE",
        field: "releasedDate",
        width: 200,
        filter: true,
        floatingFilter: true,
      },

      {
        headerName: "STATUS",
        field: "status",
        width: 150,
        filter: true,
        floatingFilter: true,
      },

      // {
      //   headerName: "ACTION",
      //   cellRenderer: (params) => showReleaseButton(params),
      // },
    ];

    return (
      <div
        className="ag-theme-alpine"
        style={{
          height: "70vh",
          fontSize: "14px",
        }}
      >
        <AgGridReact
          title="All Resources"
          columnDefs={columnDefs}
          rowData={resourcesData}
          pagination={true}
          paginationPageSize={paginationPageSize}
          defaultColDef={defaultColDef}
        ></AgGridReact>
      </div>
    );
  };

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { fontSize: "14px" },
    };
  }, []);

  const renderExcessOrReleasedTable = () => {
    switch (apiStatus) {
      case apiStatusConstants.inProgress:
        return <LoadingView />;

      case apiStatusConstants.success:
        return renderTable();

      case apiStatusConstants.failure:
        return null;

      default:
        break;
    }
  };

  return <div>{renderExcessOrReleasedTable()}</div>;
};

export default ResourceTrackingHistory;
