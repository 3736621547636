/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { GoUpload } from "react-icons/go";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import Header from "../Header";
import LeftNavBar from "../LeftNavBar";
import PageHeader from "../PageHeader";
import { IoIosInformationCircleOutline } from "react-icons/io";
import Tooltip from "@mui/material/Tooltip";

import "./index.css";

import { PageHeaderName } from "../../StyledCss/styledComponents";
import {
  StyledFileInput,
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
  StyledUploadLabel,
} from "../../StyledCss/styledInputs";
import {
  getOrganizationSettingsInfo,
  updateOrganizationLogo,
  updateOrganizationSettingsInfo,
} from "../../api/services/hroneApi";
import UploadingLoader from "../UploadingLoader";
const ApiImageStatus = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const Settings = () => {
  const [logo, setLogo] = useState(null);
  const [displayImageUrl, setDisplayImageUrl] = useState(null);
  const [organizationData, setOrganizationData] = useState({});
  const [apiImageStatus, setApiImageStatus] = useState(ApiImageStatus.initial);
  const [maxSizeInBytes] = useState(1 * 1024 * 1024);
  const [isButtonReadOnly, setIsButtonReadOnly] = useState(true);

  useEffect(() => {
    setValue("primary_color", organizationData.primaryColor);
    setValue("secondary_color", organizationData.secondaryColor);
    setValue("annual_leaves", organizationData.annualLeaves);
    setValue("basic_salary_percentage", organizationData.basicSalaryPercentage);
    setValue("hra_percentage", organizationData.hraPercentage);
    setValue("pf_percentage", organizationData.pfPercentage);
    setValue("access_control_api_key", organizationData.accessControlApiKey);
  }, [organizationData]);

  useEffect(() => {
    getDetails();
  }, []);

  const { handleSubmit, register, setValue } = useForm();

  const notifySaved = () => toast.success("Logo Updated Successfully");
  const notifyError = () => toast.error("Something Went Wrong");
  const notifyUpdated = () => toast.success("Details Updated Successfully");
  const onSubmit = (data) => {
    updateOrgSettingInfo(data);
  };

  const getDetails = async () => {
    try {
      const response = await getOrganizationSettingsInfo();

      if (response.status === 200) {
        const data = await response.json();

        const updatedData = {
          primaryColor: data.primary_color,
          secondaryColor: data.secondary_color,
          annualLeaves: data.annual_leaves,
          basicSalaryPercentage: data.basic_salary_percentage,
          hraPercentage: data.hra_percentage,
          pfPercentage: data.pf_percentage,
          accessControlApiKey: data.access_control_api_key,
          logoUrl: data.logo_url,
        };

        setOrganizationData(updatedData);
        setDisplayImageUrl(data.logo_url);
      }
    } catch (error) {
      notifyError();
    }
  };

  const orgDetails = JSON.parse(localStorage.getItem("org"));
  const { profilePic } = orgDetails;
  const updateLocalStorage = async () => {
    try {
      const response = await getOrganizationSettingsInfo();
      if (response.status === 200) {
        const data = await response.json();
        const updatedData = {
          primaryColor: data.primary_color,
          secondaryColor: data.secondary_color,
          logoUrl: data.logo_url,
          profilePic,
        };

        localStorage.setItem("org", JSON.stringify(updatedData));
      }
    } catch (error) {}
  };

  const updateOrgSettingInfo = async (data) => {
    try {
      const response = await updateOrganizationSettingsInfo(data);

      if (response.status === 200) {
        notifyUpdated();
        updateLocalStorage();
      }
    } catch (error) {}
  };

  const onChangePicture = (e) => {
    const image = e.target.files[0];

    if (image !== undefined && image.size <= maxSizeInBytes) {
      setIsButtonReadOnly(false);
    } else {
      setIsButtonReadOnly(true);
    }

    if (displayImageUrl === null && image !== undefined) {
      setLogo(e.target.files[0]);
      setDisplayImageUrl(URL.createObjectURL(e.target.files[0]));
    } else if (displayImageUrl !== null && image !== undefined) {
      setDisplayImageUrl(URL.createObjectURL(e.target.files[0]));
      setLogo(e.target.files[0]);
    } else {
      setDisplayImageUrl(null);
      setLogo(null);
    }
  };

  const updateLogo = async () => {
    setApiImageStatus(ApiImageStatus.inProgress);
    const formData = new FormData();
    formData.append("tenant_logo", logo);
    const boundary = `Boundary-${Math.random().toString(16).substring(2)}`;

    try {
      const response = await updateOrganizationLogo(formData, boundary);

      if (response.status === 200) {
        setApiImageStatus(ApiImageStatus.success);
        notifySaved();
        const data = response.data;
        const orgData = JSON.parse(localStorage.getItem("org"));
        if (orgData) {
          orgData.logoUrl = data.tenant_logo;
        }
        localStorage.setItem("org", JSON.stringify(orgData));
      } else {
        notifyError();
      }
    } catch (error) {
      notifyError();
      setApiImageStatus(ApiImageStatus.failure);
    }
  };

  const displayUploadingLoader = () => {
    switch (apiImageStatus) {
      case ApiImageStatus.inProgress:
        return <UploadingLoader />;
      case ApiImageStatus.failure:
        return <p>Error uploading file</p>;
      default:
        return null;
    }
  };

  return (
    <div style={{ width: "100vw" }}>
      <Header />
      <div className="d-flex">
        <LeftNavBar />
        <div style={{ width: "100%", backgroundColor: "#ededed" }}>
          <PageHeader>
            <PageHeaderName>Settings</PageHeaderName>
          </PageHeader>

          <div className="p-3 settings-page-container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="settings-form">
                <StyledInputGroup>
                  <StyledInputContainer>
                    <StyledInput
                      autoComplete="off"
                      type="text"
                      {...register("primary_color")}
                      placeholder="#012fds"
                    />
                    <StyledLabel className="user-label">
                      Primary Color*
                    </StyledLabel>
                    <Tooltip title="Left Navigation color" arrow>
                      <span
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "5px",
                        }}
                      >
                        <IoIosInformationCircleOutline className="info-icon" />
                      </span>
                    </Tooltip>
                  </StyledInputContainer>
                </StyledInputGroup>

                <StyledInputGroup>
                  <StyledInputContainer>
                    <StyledInput
                      autoComplete="off"
                      type="text"
                      {...register("secondary_color")}
                      placeholder="#012fds"
                    />
                    <StyledLabel className="user-label">
                      Secondary Color*
                    </StyledLabel>

                    <Tooltip title="Header color" arrow>
                      <span
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "5px",
                        }}
                      >
                        <IoIosInformationCircleOutline className="info-icon" />
                      </span>
                    </Tooltip>
                  </StyledInputContainer>
                </StyledInputGroup>

                <StyledInputGroup>
                  <StyledInputContainer>
                    <StyledInput
                      autoComplete="off"
                      type="text"
                      {...register("annual_leaves")}
                    />
                    <StyledLabel className="user-label">
                      Annual Leaves
                    </StyledLabel>
                  </StyledInputContainer>
                </StyledInputGroup>

                <StyledInputGroup>
                  <StyledInputContainer>
                    <StyledInput
                      autoComplete="off"
                      type="text"
                      {...register("hra_percentage")}
                    />
                    <StyledLabel className="user-label">
                      HRA Percentage
                    </StyledLabel>
                    <Tooltip title="40% of Basic salary" arrow>
                      <span
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "5px",
                        }}
                      >
                        <IoIosInformationCircleOutline className="info-icon" />
                      </span>
                    </Tooltip>
                  </StyledInputContainer>
                </StyledInputGroup>

                <StyledInputGroup>
                  <StyledInputContainer>
                    <StyledInput
                      autoComplete="off"
                      type="text"
                      {...register("basic_salary_percentage")}
                    />
                    <StyledLabel className="user-label">
                      Basic Percentage
                    </StyledLabel>
                    <Tooltip title="40% of Total Gross salary" arrow>
                      <span
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "5px",
                        }}
                      >
                        <IoIosInformationCircleOutline className="info-icon" />
                      </span>
                    </Tooltip>
                  </StyledInputContainer>
                </StyledInputGroup>

                <StyledInputGroup>
                  <StyledInputContainer>
                    <StyledInput
                      autoComplete="off"
                      type="text"
                      {...register("pf_percentage")}
                    />
                    <StyledLabel className="user-label">
                      PF Percentage
                    </StyledLabel>

                    <Tooltip
                      title="3600 or 24% of Basic Salary which ever is less"
                      arrow
                    >
                      <span
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "5px",
                        }}
                      >
                        <IoIosInformationCircleOutline className="info-icon" />
                      </span>
                    </Tooltip>
                  </StyledInputContainer>
                </StyledInputGroup>

                <StyledInputGroup>
                  <StyledInputContainer>
                    <StyledInput
                      autoComplete="off"
                      type="url"
                      {...register("access_control_api_key")}
                    />
                    <StyledLabel className="user-label">
                      Access Control API
                    </StyledLabel>
                  </StyledInputContainer>
                </StyledInputGroup>
              </div>
              {/* <input type="submit" /> */}
              <div>
                <PrimaryButton
                  style={{ display: "block", marginLeft: "15px" }}
                  type="submit"
                >
                  Submit
                </PrimaryButton>
              </div>
            </form>

            <div className="ml-3 mt-5">
              <div className="uploadImageContainer d-flex gap-3 mb-5">
                <StyledFileInput
                  id="fileInput"
                  name="file"
                  accept="image/*"
                  onChange={onChangePicture}
                  type="file"
                  // disabled={fileUploadButtonDisabled}
                />

                <StyledUploadLabel
                  // disabled={fileUploadButtonDisabled}
                  htmlFor="fileInput"
                  id="uploadBtn"
                >
                  <GoUpload style={{ marginRight: "8px" }} />
                  Upload
                </StyledUploadLabel>

                <SecondaryButton
                  onClick={() => {
                    setDisplayImageUrl(null);
                    setIsButtonReadOnly(true);
                  }}
                >
                  Cancel
                </SecondaryButton>
              </div>

              {displayUploadingLoader()}

              {displayImageUrl === null ? (
                <img
                  className="organizationLogo"
                  alt="profile-pic"
                  src={organizationData.logoUrl}
                />
              ) : (
                <img
                  className="organizationLogo"
                  alt="profile-pic"
                  src={displayImageUrl}
                />
              )}
            </div>

            <PrimaryButton
              className="save-btn mt-5"
              disabled={isButtonReadOnly}
              onClick={updateLogo}
            >
              Save
            </PrimaryButton>
          </div>

          <Toaster position="top-center" reverseOrder={false} />
        </div>
      </div>
    </div>
  );
};

export default Settings;
