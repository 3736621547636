import React, { useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import "./editskills.css";
import { updateSkill } from "../../api/services/hroneApi";
import { convertKeysToSnakeCase } from "../../util/convertToSnakeCase";
import {
  StyledInputGroup,
  StyledInputContainer,
  StyledInput,
  StyledLabel,
  StyledSelectGroup,
  StyledSelectContainer,
  StyledSelect,
} from "../../StyledCss/styledInputs";

import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";

const EditSkills = ({
  skillsList,
  closeModal,
  getSkillsList,
  notifySkillUpdate,
}) => {
  const [skillList, setSkillsList] = useState(skillsList);
  const [errorIndices, setErrorIndices] = useState([]);
  const [setError] = useState("");


  const onChangeSkillName = (e, index) => {
    const modifiedValue = e.target.value.trim();
    const emptyIndices = [...errorIndices];

    if (index >= 0 && index < skillList.length) {
      const updatedSkills = skillList.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            skillName: modifiedValue,
          };
        }
        return item;
      });

      setSkillsList(updatedSkills);

      if (modifiedValue === "") {
        if (!emptyIndices.includes(index)) {
          emptyIndices.push(index);
        }
      } else {
        const indexToRemove = emptyIndices.indexOf(index);
        if (indexToRemove !== -1) {
          emptyIndices.splice(indexToRemove, 1);
        }
      }

      setErrorIndices(emptyIndices);
    }
  };

  const onChangeSkillRating = (e, index) => {
    const modifiedValue = e.target.value;

    if (index >= 0 && index < skillList.length) {
      const updatedSkills = skillList.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            skillRating: modifiedValue,
          };
        }
        return item;
      });

      setSkillsList(updatedSkills);
    }
  };

  const onDeleteSkill = (indexToDelete) => {
    const updatedList = skillList.filter(
      (eachSKill) => eachSKill.skillName !== skillList[indexToDelete].skillName
    );

    setSkillsList(updatedList);
  };

  const updateSkillsDetails = async () => {
    const convertedSnakeCaseData = convertKeysToSnakeCase(skillList);

    const hasErrors = convertedSnakeCaseData.some(
      (input) => input.skill_name === ""
    );

    if (!hasErrors) {
      try {
        const response = await updateSkill(convertedSnakeCaseData);
        if (response.status === 200) {
          closeModal();
          getSkillsList();
          notifySkillUpdate();
        } else {
          const data = await response.json();
          setError(data.detail);
        }
      } catch (error) {}
    }
  };

  const renderSkillsList = () => (
    <>
      <ul className="skillsListContainer">
        {skillList.map((item, index) => (
          <li className="editSkillElement" key={index}>
            <StyledInputGroup>
              <StyledInputContainer>
                <StyledInput
                  inputError={errorIndices.includes(index)}
                  value={item.skillName}
                  required
                  type="text"
                  name="skill_name"
                  autoComplete="off"
                  onChange={(e) => onChangeSkillName(e, index)}
                  style={
                    errorIndices.includes(index)
                      ? { borderLeft: "10px solid red" }
                      : {}
                  }
                />
                <StyledLabel className="user-label">Skill Name</StyledLabel>
              </StyledInputContainer>
            </StyledInputGroup>

            <StyledSelectGroup>
              <StyledSelectContainer>
                <StyledSelect
                  width="120px"
                  onChange={(e) => onChangeSkillRating(e, index)}
                  value={item.skillRating}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </StyledSelect>
                <StyledLabel className="user-label">Rating*</StyledLabel>
              </StyledSelectContainer>
            </StyledSelectGroup>

            <button
              className="deleteButton"
              onClick={() => onDeleteSkill(index)}
            >
              <MdDeleteOutline className="deleteIcon" />
            </button>
          </li>
        ))}
      </ul>
      <div className="text-end d-flex gap-3 justify-content-end">
        <SecondaryButton onClick={() => closeModal()}>Cancel</SecondaryButton>
        <PrimaryButton
          disabled={errorIndices.length !== 0}
          onClick={updateSkillsDetails}
        >
          Submit
        </PrimaryButton>
      </div>
    </>
  );

  return <div>{renderSkillsList()}</div>;
};

export default EditSkills;
