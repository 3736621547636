/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import "./index.css";
import formatDate from "../../util/formatdate";
import { getBirthdays } from "../../api/services/hroneApi";

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const MyLoader = () => (
  <ContentLoader
    className="holidays-list-content-loader"
    backgroundColor="#E0E0E0"
  >
    <rect x="0" y="0" rx="5" ry="5" width="300" height="400" />
  </ContentLoader>
);

const orgDetails = JSON.parse(localStorage.getItem("org"));
// const { primaryColor } = orgDetails;

const UpcommingBirthdays = () => {
  const [birthdayList, setBirthdaysList] = useState([]);
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
  const [showBirthdaysList, setShowBirthdaysList] = useState(false);
  const [showText, setShowText] = useState(false);
  useEffect(() => {
    getBirthdaysList();
  }, []);

  const getBirthdaysList = async () => {
    try {
      setApiStatus(apiStatusConstants.inProgress);
      const response = await getBirthdays();
      if (response.ok) {
        const data = await response.json();
        const updatedData = data.map((eachDay) => ({
          DateOfBirth: formatDate(eachDay.date_of_birth),
          firstName: eachDay.first_name,
          lastName: eachDay.last_name,
          designation: eachDay.designation,
          department: eachDay.department,
        }));
        setBirthdaysList(updatedData);
        setApiStatus(apiStatusConstants.success);
      }
    } catch (e) {
      setApiStatus(apiStatusConstants.failure);
    }
  };

  const formattedDate = (birthdayDate) => {
    const parts = birthdayDate.split("-");

    const month = parseInt(parts[1], 10);
    const day = parseInt(parts[0], 10);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const textDay = formattedDay(day, monthNames[month - 1], month);

    return textDay;
  };
  const currentDay = new Date().getDate();
  const currentMonth = new Date().getMonth();
  const formattedDay = (day, monthName, month) => {
    if (currentDay === day && currentMonth + 1 === month) {
      return "Today";
    } else if (currentDay + 1 === day && currentMonth + 1 === month) {
      return "Tomorrow";
    } else {
      return day + getDaySuffix(day) + " " + monthName;
    }
  };

  const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const showOrHideBirthdays = () => {
    setShowText(!showText);
    if (birthdayList.length >= 6) {
      setShowBirthdaysList(!showBirthdaysList);
    } else {
      setShowBirthdaysList(false);
    }
  };

  const renderBirthdaysList = () => (
    <div className="birthday-container">
      <p className="birthday-Heading">Birthday’s</p>
      {birthdayList.length > 0 ? (
        <>
          <ul
            style={{ height: showBirthdaysList ? "450px" : "258px" }}
            className="birthdays-list"
          >
            {birthdayList.map((eachDay, index) => (
              <li key={index} className="birthday-card">
                <p className="name-heading">
                  {formattedDate(eachDay.DateOfBirth)} -{eachDay.firstName}{" "}
                  {eachDay.lastName}
                </p>
                <div className="details">
                  <p>
                    {eachDay.designation} - {eachDay.department}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div
          style={{
            height: "274px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ textAlign: "center" }}>No birthdays </p>
        </div>
      )}

      <div
        // onClick={() => setShowBirthdaysList(!showBirthdaysList)}
        onClick={showOrHideBirthdays}
        className="container-bottom-section"
      >
        <p style={orgDetails ? { color: orgDetails.primaryColor } : {}}>
          {showText ? "Show Less" : "See All Birthday's"}
        </p>
      </div>
    </div>
  );

  const DisplayBirthdaysList = () => {
    switch (apiStatus) {
      case apiStatusConstants.success:
        return renderBirthdaysList();
      case apiStatusConstants.failure:
        return <MyLoader />;
      case apiStatusConstants.inProgress:
        return <MyLoader />;
      default:
        return null;
    }
  };

  return <>{DisplayBirthdaysList()}</>;
};

export default UpcommingBirthdays;
