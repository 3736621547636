import styled from "styled-components";

export const Input = styled.input`
  border: 1px solid #e0e5ea;
  width: 320px;
  ${"" /* width: 382px; */}
  height: 48px;
  border-radius: 5px;
  ${
    "" /* min-width: 382px;
  max-width: 100%; */
  }
`;

export const Label = styled.label`
  color: #233747;
  font-size: 16px;
  margin-bottom: 5px;
`;

export const BasicText = styled.p`
  color: #64748b;
  font-size: 14px;
  line-height: 19px;
`;

export const CardHeadings = styled.p`
  color: #212121;
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
`;

export const CardText = styled.p`
  color: #64748b;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  width: 300px;
`;

export const ErrorText = styled.p`
  color: #fb5801;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
`;

export const PageHeaderName = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 18px;
  padding-left: 15px;
`;
