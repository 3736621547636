import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
  StyledTextarea,
} from "../../StyledCss/styledInputs";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import { updateExtraWork } from "../../api/services/hroneApi";

const ExtraWorkRejectForm = ({
  rowData,
  setReject,
  getPendingRequestsData,
  closeBtn,
}) => {
  const [patterError, setInputPatternError] = useState(false);
  const [rejectedReason, setRejectReason] = useState("");

  const notifyRejected = () => toast.success("Reimbursement Rejected");
  const notifyRejectFailure = () =>
    toast.error("Coudn't Reject Something Went Wrong");

  const onChangeReason = (e) => {
    setRejectReason(e.target.value);
    if (e.target.value !== "") {
      setInputPatternError(false);
    }
  };

  const onClickSubmitReason = (e) => {
    e.preventDefault();
    const pattern = /^(?!\s)[a-zA-Z0-9\s.,'()" -]{3,500}$/;
    if (pattern.test(rejectedReason)) {
      setReject(false);
      setRejectReason("");
      sendRejectStatus();
    } else {
      setInputPatternError(true);
    }
  };

  const sendRejectStatus = async () => {
    const workId = rowData.workId;
    const status = rowData.status;

    const response = await updateExtraWork(workId, status, rejectedReason);

    if (response.ok) {
      setReject(false);
      setTimeout(() => {
        getPendingRequestsData();
      }, 1000);
      notifyRejected();
    } else {
      notifyRejectFailure();
    }
  };

  return (
    <form autoComplete="off" onSubmit={onClickSubmitReason}>
      {/* <div className="d-flex justify-content-between align-items-center">
        <label className="reason-label" htmlFor="reasonInput">
          Reason for rejection*
        </label>
      </div> */}

      {/* <textarea
        id="reasonInput"
        className={patterError ? "reason-input-error" : "reason-input"}
        onChange={onChangeReason}
        rows="5"
        cols="50"
      /> */}

      <StyledInputGroup>
        <StyledInputContainer>
          <StyledTextarea
            style={patterError ? { borderLeft: "10px solid red" } : {}}
            onChange={onChangeReason}
            // style={errors.description && { borderLeft: "10px solid red" }}
            // {...register("description", { required: true })}
          />
          <StyledLabel hasValue={rejectedReason !== ""} className="user-label">
            Reason For Rejection*
          </StyledLabel>
        </StyledInputContainer>
      </StyledInputGroup>

      <div className="d-flex justify-content-end align-items-center gap-3">
        <SecondaryButton
          // className="submit-reason-btn"
          type="button"
          onClick={closeBtn}
        >
          Cancel
        </SecondaryButton>

        <PrimaryButton
          // className="submit-reason-btn"
          type="submit"
        >
          Submit
        </PrimaryButton>
      </div>
    </form>
  );
};

export default ExtraWorkRejectForm;
