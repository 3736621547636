import { getDate, getYear, getMonth } from "date-fns";


const formatDate = (holidayDate) => {
    const changeDateFormat = () =>{
        const data = new Date(holidayDate);
        const date = getDate(data);
        const month = getMonth(data) + 1;
        const year = getYear(data);

        let currentMonth = month;
        let currentDate = date;
        if (month < 10) {
          currentMonth = `0${month}`;
        }
        if (date < 10) {
          currentDate = `0${date}`;
          
        }
        return `${currentDate}-${currentMonth}-${year}`
    }

    return changeDateFormat()
    
}

export default formatDate