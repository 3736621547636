/* eslint-disable react-hooks/exhaustive-deps */
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import toast, { Toaster } from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";

import { PageHeaderName } from "../../StyledCss/styledComponents";
import {
  getAdmins,
  getUserData,
  getWorkRequests,
  updateExtraWork,
} from "../../api/services/hroneApi";
import formatDate from "../../util/formatdate";
import "../CommonCss/slidingModal.css";
import ExtraWorkRejectForm from "../ExtraWorkReject/extraWorkReject";
import Extraworkform from "../ExtraworkForm";
import ExtraworkHistory from "../ExtraworkHistory";
import FailureView from "../FailureView";
import Header from "../Header";
import LeftNavBar from "../LeftNavBar";
import LoadingView from "../LoadingView";
import PageHeader from "../PageHeader";
import PopupModal from "../PopupModal";
import ReimbursementHistory from "../ReimbursementHistory";
import "./index.css";

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const Reimbursement = () => {
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refetchData, setRefetchData] = useState(false);
  const [extraWorkingHoursData, setExtraHoursData] = useState([]);
  const [rejectClicked, setReject] = useState(false);
  const [activeTab, setActiveTab] = useState("Your History");
  const [approvalApiStatus, setApprovalApiStatus] = useState(
    apiStatusConstants.initial
  );
  const [rowData, setRowData] = useState({});
  const [hrList, setHrList] = useState([]);

  const date = new Date();
  const [startMonth, setStartMonth] = useState(date);
  const notifyApproved = () => toast.success("Reimbursement Approved");

  const notifySuccess = () => toast.success("Extra work Applied Successfully");
  const { role } = getUserData();

  const notifyFailure = () => toast.error("something went wrong");

  useEffect(() => {
    getPendingRequestsData();
  }, [activeTab === "Requests"]);
  const getPendingRequestsData = async () => {
    setApiStatus(apiStatusConstants.inProgress);

    try {
      const response = await getWorkRequests();
      const responsehrList = await getAdmins();

      if (responsehrList.status === 200) {
        const hrData = await responsehrList.json();

        setHrList(hrData);
      }

      if (response.status === 200) {
        setApiStatus(apiStatusConstants.success);
        const data = await response.json();
        const updatedData = data.map((eachWork) => ({
          firstName: eachWork.first_name,
          lastName: eachWork.last_name,
          employeeId: eachWork.employee_id,
          date: formatDate(eachWork.date),
          hoursWorked: eachWork.hours,
          status: eachWork.status,
          rejectedReason: eachWork.rejected_reason,
          workId: eachWork.extra_work_id,
        }));

        setExtraHoursData(updatedData);
      }
    } catch (error) {
      setApiStatus(apiStatusConstants.failure);
    }
  };

  const sendAcceptedStatus = async (params) => {
    setApprovalApiStatus(apiStatusConstants.inProgress);
    const workId = params.data.workId;
    const status = "Approved";
    const rejectedReason = params.data.rejectedReason;
    const response = await updateExtraWork(workId, status, rejectedReason);
    try {
      if (response.status === 200) {
        notifyApproved();
        setTimeout(() => {
          getPendingRequestsData();
          setApprovalApiStatus(apiStatusConstants.success);
        }, 500);
      } else {
        notifyFailure();
      }
    } catch (error) {
      notifyFailure();
    }
  };

  const onClickApprove = (params) => {
    sendAcceptedStatus(params);
  };

  const onClickReject = (params) => {
    const rowData = params.data;
    setRowData({ ...rowData, status: "Rejected" });
    setReject(!rejectClicked);
  };

  const onClickClose = () => {
    setReject(false);
  };

  const renderReimbursementRequests = () => {
    const paginationPageSize = 20;
    const columnDefs = [
      {
        headerName: "EMP ID",
        field: "employeeId",
        width: 120,
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "NAME",
        valueGetter: function (params) {
          const firstName = params.data.firstName;
          const lastName = params.data.lastName;
          const combinedData = firstName + " " + lastName;
          return combinedData;
        },

        filter: true,
        floatingFilter: true,
        sortable: true,
      },
      {
        headerName: "DATE",
        field: "date",
        width: 130,
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "HOURS WORKED",
        field: "hoursWorked",
        width: 180,
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "STATUS",
        field: "status",
        width: 150,
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "APPROVE",
        cellRenderer: (params) => (
          <PrimaryButton
            disabled={approvalApiStatus === apiStatusConstants.inProgress}
            className="d-flex  justify-content-center align-items-center"
            onClick={() => onClickApprove(params)}
          >
            Approve
          </PrimaryButton>
        ),
      },

      {
        headerName: "REJECT",
        cellRenderer: (params) => (
          <SecondaryButton
            className="d-flex  justify-content-center align-items-center"
            onClick={() => onClickReject(params)}
          >
            Reject
          </SecondaryButton>
        ),
      },
    ];

    return (
      <div
        className="ag-theme-alpine"
        style={{
          height: "75vh",
          fontSize: "14px",
          marginLeft: "20px",
          marginRight: "20px",
          marginTop: "20px",
          fontFamily: "Arial, Helvetica, sans-serif",
        }}
      >
        <AgGridReact
          title="All Resources"
          columnDefs={columnDefs}
          rowData={extraWorkingHoursData}
          pagination={true}
          paginationPageSize={paginationPageSize}
          // defaultColDef={defaultColDef}
        ></AgGridReact>
      </div>
    );
  };

  const handleApplyExtra = () => {
    setIsModalOpen(true);
    setRefetchData(false);
  };
  const closeBtn = () => {
    setIsModalOpen(false);
  };

  const extraworkApplied = () => {
    setIsModalOpen(false);
    setRefetchData(true);
  };

  const applyExtraWork = () => (
    <div>
      <div className="d-flex flex-row flex-wrap justify-content-end mb-1 align-items-center">
        <div className="d-flex flex-column m-1">
          <div className="me-3">
            <label className="mb-1">Select Month</label>
            <DatePicker
              className="date-input-label"
              selected={startMonth}
              onChange={(date) => {
                if (date) {
                  setStartMonth(date);
                } else {
                  setStartMonth(new Date());
                }
              }}
              showMonthYearPicker
              dateFormat="MMMM yyyy"
            />
          </div>
        </div>
      </div>

      <div className="extra-work-history-div">
        <ExtraworkHistory startMonth={startMonth} refetchData={refetchData} />
      </div>
    </div>
  );

  const pageTabsList = [
    {
      id: 1,
      name: "Your History",
      roles: ["Super Admin", "Admin", "Employee", "HR", "Lead"],
    },
    {
      id: 2,
      name: "Requests",
      roles: ["Super Admin", "Admin"],
    },
    {
      id: 3,
      name: "All History",
      roles: ["Super Admin", "Admin"],
    },
  ];

  const orgDetails = JSON.parse(localStorage.getItem("org"));
  const { primaryColor } = orgDetails;

  const tabs = () => {
    return pageTabsList.map(
      (eachTab) =>
        eachTab.roles.includes(role) && (
          <button
            style={
              activeTab === eachTab.name
                ? {
                    color: primaryColor,
                    borderBottom: `3px solid ${primaryColor}`,
                  }
                : {}
            }
            className={
              activeTab === eachTab.name
                ? "reimbursement-active-tab"
                : "reimbursement-tab"
            }
            onClick={() => setActiveTab(eachTab.name)}
            key={eachTab.id}
          >
            {eachTab.name}
          </button>
        )
    );
  };

  const renderReimbursementPage = () => {
    switch (activeTab) {
      case "Your History":
        return applyExtraWork();
      case "Requests":
        return renderReimbursementRequests();
      case "All History":
        return <ReimbursementHistory />;
      default:
        return null;
    }
  };
  const onClickRetry = () => {
    getPendingRequestsData();
  };

  const renderReimbursement = () => {
    switch (apiStatus) {
      case apiStatusConstants.success:
        return renderReimbursementPage();
      case apiStatusConstants.failure:
        return <FailureView onClickRetry={onClickRetry} />;
      case apiStatusConstants.inProgress:
        return <LoadingView />;
      default:
        return null;
    }
  };

  return (
    <div>
      <Header />
      <div className="d-flex">
        <LeftNavBar />
        <div style={{ width: "100%", backgroundColor: "#ededed" }}>
          <PageHeader>
            <PageHeaderName>Reimbursement</PageHeaderName>
            <div className="extra-btn-div" style={{ marginRight: "18px" }}>
              <PrimaryButton onClick={handleApplyExtra} type="button">
                Apply Extra Work
              </PrimaryButton>
            </div>
          </PageHeader>

          <div className="reimbursement-tabs-container">
            <div>{tabs()}</div>
            {renderReimbursement()}
          </div>
        </div>
      </div>

      <PopupModal
        modalName="Apply Reimbursement"
        closeBtn={closeBtn}
        showModal={isModalOpen}
      >
        <Extraworkform
          hrList={hrList}
          closeBtn={closeBtn}
          extraworkApplied={extraworkApplied}
          notifySuccess={notifySuccess}
        />
      </PopupModal>

      {/* <SlidingModal
        modalName="Apply Reimbursement"
        closeBtn={closeBtn}
        isModalOpen={isModalOpen}
      ></SlidingModal> */}

      <PopupModal
        modalName="Reject Work"
        closeBtn={onClickClose}
        showModal={rejectClicked}
      >
        <ExtraWorkRejectForm
          rowData={rowData}
          setReject={setReject}
          getPendingRequestsData={getPendingRequestsData}
          closeBtn={onClickClose}
        />
      </PopupModal>
      {/* <SlidingModal
        closeBtn={onClickClose}
        isModalOpen={rejectClicked}
      ></SlidingModal> */}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default Reimbursement;
