import React, { useEffect, useState } from "react";
import { BsX } from "react-icons/bs";
import AddPolicyForm from "../AddPolicyForm/Policyform";
import Header from "../Header";
import LeftNavBar from "../LeftNavBar";
import PageHeader from "../PageHeader";
// import PoliciesList from "../PoliciesListComponent/PoliciesList";
import { PageHeaderName } from "../../StyledCss/styledComponents";
import { PrimaryButton } from "../../StyledCss/styledbtn";
import { getPolicyPdfList, getUserData } from "../../api/services/hroneApi";
import { convertKeysToCamelCase } from "../../util/convertCamelCase";
import LoadingView from "../LoadingView";
import PopupModal from "../PopupModal";
import "./policies.css";

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const Policies = () => {
  const [activePolicyName, setActivePolicyName] = useState("");
  const [policyName, setPolicyName] = useState("");
  const fileImage = process.env.PUBLIC_URL + "/pdfImage.png";
  const [addPolicyModal, setAddPolicyModal] = useState(false);
  // const [policyDetails, setPolicyDetails] = useState("");
  const [policiesList, setPoliciesList] = useState([]);

  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);

  useEffect(() => {
    getPolicies();
  }, []);

  const { role } = getUserData();

  const getPolicies = async () => {
    try {
      setApiStatus(apiStatusConstants.inProgress);
      const response = await getPolicyPdfList();
      if (response.status === 200) {
        setApiStatus(apiStatusConstants.success);

        const data = await response.json();
        const updatedData = data.map((eachItem) =>
          convertKeysToCamelCase(eachItem)
        );
        setPoliciesList(updatedData);
      } else {
        setApiStatus(apiStatusConstants.failure);
      }
    } catch (error) {
      setApiStatus(apiStatusConstants.failure);
    }
  };

  const policyPdfModal = () => (
    <div className="modal-overlay">
      <div className="pdf-container">
        <div className="pdf-heading-container">
          <h4>{policyName}</h4>
          <button
            className="cross-icon"
            onClick={() => setActivePolicyName("")}
          >
            <BsX style={{ fontSize: "30px" }} />
          </button>
        </div>

        <iframe
          title="PDF Viewer"
          src={activePolicyName}
          width="100%"
          height="93%" // Adjust the height as needed
          style={{ marginTop: "10px" }}
        ></iframe>
      </div>
    </div>
  );

  const onCloseModal = () => {
    setAddPolicyModal(false);
  };

  const renderPoliciesList = () => (
    <>
      {policiesList.length === 0 ? (
        <div className="h-100 d-flex justify-content-center align-items-center">
          <p>No Policies</p>
        </div>
      ) : (
        <div className="policies">
          {policiesList.map((eachPolicy) => (
            <button
              style={{ backgroundColor: "#ffffff" }}
              key={eachPolicy.id}
              className="policy-card"
              onClick={() => {
                setActivePolicyName(eachPolicy.policyUrl);
                setPolicyName(eachPolicy.policyName);
              }}
            >
              <img className="pdfImage" src={fileImage} alt="file" />
              <div>
                <p className="cardText">{eachPolicy.policyName}</p>
              </div>
            </button>
          ))}
        </div>
      )}
    </>
  );

  const renderPolicies = () => {
    switch (apiStatus) {
      case apiStatusConstants.inProgress:
        return <LoadingView />;
      case apiStatusConstants.success:
        return renderPoliciesList();
      case apiStatusConstants.failure:
        return null;

      default:
        break;
    }
  };

  return (
    <div>
      <Header />
      <div className="d-flex ">
        <LeftNavBar />
        <div style={{ width: "100vw", backgroundColor: "#ededed" }}>
          <PageHeader>
            <PageHeaderName>Policies</PageHeaderName>
            {(role === "Admin" || role === "HR") && (
              <PrimaryButton onClick={() => setAddPolicyModal(!addPolicyModal)}>
                Add Policy
              </PrimaryButton>
            )}
          </PageHeader>
          <div className="policyPage-container">
            {renderPolicies()}
            <div>
              <p>{activePolicyName}</p>
            </div>

            {/* <ViewPolicy pdfUrl="https://www.africau.edu/images/default/sample.pdf" /> */}
          </div>

          {activePolicyName && policyPdfModal()}

          <PopupModal
            modalName="Add Policy"
            closeBtn={onCloseModal}
            showModal={addPolicyModal}
          >
            <AddPolicyForm
              getPolicies={getPolicies}
              closeModal={onCloseModal}
            />
          </PopupModal>
        </div>
      </div>
    </div>
  );
};

export default Policies;
