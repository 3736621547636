import React, { useEffect, useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
// import SlidingModal from "../SlidingModal/slidingModal";
// import EmployeeResourceSummary from "../EmployeeResourceSummary";
import { getResourceSummary } from "../../api/services/hroneApi";
import { convertKeysToCamelCase } from "../../util/convertCamelCase";
import LoadingView from "../LoadingView";

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const ResourceTrackingSummary = () => {
  // const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [resourceSummaryData, setResourceSummaryData] = useState([]);
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
  useEffect(() => {
    fetchResourceSummary();
  }, []);

  const fetchResourceSummary = async () => {
    setApiStatus(apiStatusConstants.inProgress);
    try {
      const response = await getResourceSummary();
      if (response.status === 200) {
        const data = await response.json();
        const updatedData = data.map((eachItem) =>
          convertKeysToCamelCase(eachItem)
        );
        setApiStatus(apiStatusConstants.success);
        setResourceSummaryData(updatedData);
      }
    } catch (error) {
      setApiStatus(apiStatusConstants.failure);
    }
  };

  const renderTable = () => {
    const paginationPageSize = 20;
    const columnDefs = [
      {
        headerName: "EMP ID",
        field: "employeeId",
        width: 150,
        filter: true,
        floatingFilter: true,
      },

      {
        headerName: "NAME",
        field: "employeeName",
        width: 250,
        filter: true,
        floatingFilter: true,
        tooltipField: "employeeName",
        editable: true,
      },

      {
        headerName: "TOTAL",
        field: "Total",
        width: 120,
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "LAPTOPS",
        field: "Laptop",
        width: 150,
        filter: true,
        floatingFilter: true,
      },

      {
        headerName: "MOBILES",
        field: "Mobile",
        width: 150,
        filter: true,
        floatingFilter: true,
      },

      {
        headerName: "LAN",
        field: "LAN",
        width: 150,
        filter: true,
        floatingFilter: true,
      },

      {
        headerName: "MOUSE",
        field: "Mouse",
        width: 150,
        filter: true,
        floatingFilter: true,
      },

      {
        headerName: "OTHERS",
        field: "Others",
        width: 150,
        filter: true,
        floatingFilter: true,
      },
    ];

    return (
      <div
        className="ag-theme-alpine"
        style={{
          height: "70vh",
          fontSize: "14px",
          marginLeft: "20px",
          marginRight: "20px",
          fontFamily: "Arial, Helvetica, sans-serif",
        }}
      >
        <AgGridReact
          title="All Resources"
          columnDefs={columnDefs}
          rowData={resourceSummaryData}
          pagination={true}
          paginationPageSize={paginationPageSize}
          //   defaultColDef={defaultColDef}
        ></AgGridReact>
      </div>
    );
  };

  // const toggleIsPopupOpen = () => setIsPopupOpen(false);

  // const renderLoader = () => (
  //   <div
  //     style={{ height: "70vh" }}
  //     className="d-flex justify-content-center align-items-center w-100"
  //   >
  //     <div className="spinner-border text-primary " role="status"></div>
  //   </div>
  // );

  const renderSummaryTable = () => {
    switch (apiStatus) {
      case apiStatusConstants.inProgress:
        return <LoadingView />;

      case apiStatusConstants.success:
        return renderTable();

      case apiStatusConstants.failure:
        return null;

      default:
        break;
    }
  };

  return <div>{renderSummaryTable()}</div>;
};

export default ResourceTrackingSummary;
