import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
} from "../../StyledCss/styledInputs";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import { addHoliday } from "../../api/services/hroneApi";
import "./addHolidaysForm.css";
const AddHolidayForm = ({ refreshHolidays, closeHolidaysModal }) => {
  const notifyHolidayAdded = () => toast.success("Holiday Added Successfully");
  const [errorMsg, setError] = useState("");
  const [holidayName, setHolidayName] = useState("");
  const [HolidayError, setHolidayError] = useState(false);
  const [eventDate, setEventDate] = useState("");
  const [dateError, setDateError] = useState(false);
  const [invalidYear, setInvalidYear] = useState("");

  const currentDate = () => {
    const crntDate = new Date();
    const dateString = crntDate.toISOString().split("T")[0];
    return dateString;
  };

  const onChangeDate = (e) => {
    const eventDate = e.target.value;

    if (eventDate !== "") {
      setInvalidYear("");
      setDateError(false);
      setEventDate(eventDate);
    } else {
      setDateError(true);
      setEventDate(eventDate);
    }
  };

  const onChangeEvent = (e) => {
    const eventName = e.target.value;

    if (eventName.trim() !== "") {
      setHolidayError(false);
      setHolidayName(eventName);
    } else {
      setHolidayError(true);
      setHolidayName(eventName);
    }
  };

  const onClickHolidaysSubmit = async (e) => {
    e.preventDefault();
    const addHolidayObj = {
      holidayName,
      eventDate,
    };

    const year = new Date(eventDate).getFullYear();

    if (isNaN(year) || year.toString().length !== 4) {
      setInvalidYear("Invalid year. Please enter a valid 4-digit year.");
      setDateError(true);
      if (holidayName.trim() === "") {
        setHolidayError(true);
      }
    } else {
      if (holidayName.trim() === "" && eventDate === "") {
        setDateError(true);
        setHolidayError(true);
      } else if (holidayName.trim() === "") {
        setHolidayError(true);
      } else if (eventDate === "") {
        setDateError(true);
      } else if (
        holidayName.trim() !== "" &&
        !HolidayError &&
        eventDate !== "" &&
        !dateError &&
        !invalidYear
      ) {
        try {
          const response = await addHoliday(addHolidayObj);
          if (response.status === 200) {
            notifyHolidayAdded();
            setEventDate("");
            setHolidayName("");
            setError("");
            closeHolidaysModal();
            refreshHolidays();
          } else {
            const data = await response.json();
            setError(data.detail);
            // setDateError(true);
            // setHolidayError(true);
          }
        } catch (error) {}
      }
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={onClickHolidaysSubmit}
      className="add-holidays-form"
    >
      <div className="d-flex gap-3">
        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={HolidayError ? { borderLeft: "10px solid red" } : {}}
              value={holidayName}
              name="event"
              onChange={onChangeEvent}
              type="text"
            />
            <StyledLabel className="user-label">Occasion*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>
        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={dateError ? { borderLeft: "10px solid red" } : {}}
              min={currentDate()}
              name="date"
              onChange={onChangeDate}
              value={eventDate}
              max="9999-12-31"
              // className={dateError ? "event-error" : "input-element"}
              type="date"
            />
            <StyledLabel className="user-label">Date*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>
      </div>

      {errorMsg.length > 0 && <p style={{ color: "#FB5801" }}>*{errorMsg}</p>}

      {invalidYear && eventDate !== "" && (
        <p style={{ color: "#FB5801" }}>*{invalidYear}</p>
      )}

      <div className="add-holiday-submit-btn">
        <SecondaryButton type="button" onClick={() => closeHolidaysModal()}>
          Cancel
        </SecondaryButton>
        <PrimaryButton type="submit">Submit</PrimaryButton>
      </div>
    </form>
  );
};

export default AddHolidayForm;
