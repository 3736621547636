import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import FailureView from "../FailureView";
import LoadingView from "../LoadingView";
import "./index.css";

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const ReleasedEmployeeTable = ({
  employeesList,
  apiStatus,
  refreshEmployeesList,
}) => {
  const noDataDisplay = () => (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <img
        className="noData-img"
        alt="NoData"
        src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=740&t=st=1685691455~exp=1685692055~hmac=e7fe93b465ad04cefb40b139444400f1cb0f9068a880f91999b843299068fdca"
      />

      <h3>No Records Found</h3>
    </div>
  );

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { fontSize: "14px" },
      // editable: true,
    };
  }, []);

  const paginationPageSize = 20;

  const columnDefs = [
    {
      headerName: "EMP ID",
      field: "id",
      width: 150,
      sortable: true,
      pinned: "left",
      floatingFilter: true,
      filter: true,
      editable: true,
    },
    {
      headerName: "NAME",
      cellRenderer: (params) => {
        return <p>{params.data.fullName}</p>;
      },
      field: "fullName",
      pinned: "left",
      tooltipField: "fullName",
      filter: true,
      floatingFilter: true,
      editable: true,
    },

    {
      headerName: "EMAIL",
      field: "email",
      width: 350,
      filter: true,
      floatingFilter: true,
      tooltipField: "email",
      editable: true,
    },
    {
      headerName: "ROLE",
      field: "role",
      width: 130,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "DESIGNATION",
      field: "designation",
      width: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "DEPARTMENT",
      field: "department",
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "QUALIFICATIONS",
      field: "qualifications",
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "JOINING DATE",
      field: "joiningDate",
      width: 180,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "EMP STATUS",
      field: "employementStatus",
      width: 180,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "DOB",
      field: "dateOfBirth",
      width: 120,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "GENDER",
      field: "gender",
      width: 150,
      cellStyle: { display: "flex", justifyContent: "center" },
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "BLOOD GROUP",
      field: "bloodGroup",
      width: 180,
      cellStyle: { display: "flex", justifyContent: "center" },
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "MOBILE NO",
      field: "mobileNumber",
      width: 150,
      filter: true,
      floatingFilter: true,
      editable: true,
    },
    {
      headerName: "EMG CONTACT NAME",
      field: "emergencyContactName",
      width: 250,
      filter: true,
      floatingFilter: true,
    },

    {
      headerName: "EMG CONTACT NUMBER",
      field: "emergencyContactNumber",
      width: 250,
      filter: true,
      floatingFilter: true,
      editable: true,
    },
    {
      headerName: "LOCATION",
      field: "address",
      filter: true,
      floatingFilter: true,
    },
  ];

  const renderTable = () => (
    <div
      className="ag-theme-alpine"
      style={{
        height: "78vh",
        fontSize: "14px",
        marginLeft: "20px",
        marginRight: "20px",
        fontFamily: "Arial, Helvetica, sans-serif",
        backgroundColor: "#FFFFFF",
      }}
    >
      <AgGridReact
        title="All Employees"
        columnDefs={columnDefs}
        rowData={employeesList}
        pagination={true}
        paginationPageSize={paginationPageSize}
        defaultColDef={defaultColDef}
        rowSelection="multiple"
        // onGridReady={onGridReady}
        // onSelectionChanged={onSelectionChanged}
        suppressRowClickSelection="true"
      ></AgGridReact>
    </div>
  );

  const renderEmployeeTable = () => (
    <div className="table-container">
      {employeesList.length > 0 ? renderTable() : noDataDisplay()}
    </div>
  );

  const onClickRetry = () => refreshEmployeesList();

  const renderAllEmployeesPage = () => {
    switch (apiStatus) {
      case apiStatusConstants.success:
        return renderEmployeeTable();
      case apiStatusConstants.failure:
        return <FailureView onClickRetry={onClickRetry} />;
      case apiStatusConstants.inProgress:
        return <LoadingView />;
      default:
        return null;
    }
  };

  return <>{renderAllEmployeesPage()}</>;
};

export default ReleasedEmployeeTable;
