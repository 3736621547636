import React, { useState } from "react";
// import { useForm } from "react-hook-form";
import {
  StyledFileInput,
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
  StyledUploadLabel,
} from "../../StyledCss/styledInputs";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import { uploadPolicyPdf } from "../../api/services/hroneApi";
import UploadingLoader from "../UploadingLoader";
import { ErrorText } from "../../StyledCss/styledComponents";
import "./policyform.css";

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const AddPolicyForm = ({ getPolicies, closeModal }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadPdfError, setUploadPdfError] = useState("");
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
  const [policyName, setPolicyName] = useState("");
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  //   getValues,
  // } = useForm();

  const [fileUploadButtonDisabled, SetFileUploadButtonDisabled] =
    useState(false);
  // const [fileSubmitButtonDisabled, SetFileSubmitButtonDisabled] =
  //   useState(true);

  const onSubmit = async (e) => {
    e.preventDefault();
    setUploadPdfError("");
    try {
      setApiStatus(apiStatusConstants.inProgress);
      const policyObj = new FormData();
      policyObj.append("policy_name", policyName);
      policyObj.append("policy_file", selectedFile);

      const boundary = `Boundary-${Math.random().toString(16).substring(2)}`;

      const response = await uploadPolicyPdf(policyObj, boundary);
      if (response.status === 200) {
        setApiStatus(apiStatusConstants.success);
        closeModal();
        getPolicies();
      } else {
        setApiStatus(apiStatusConstants.failure);
        setUploadPdfError("Error Uploading File");
      }
    } catch (error) {
      setUploadPdfError("Error Uploading File");
      setApiStatus(apiStatusConstants.failure);
    }
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];

    // if (selectedFile === null || file !== undefined) {
    //   setSelectedFile(file);
    //   setUploadPdfError("");
    // } else {
    //   setSelectedFile(null);
    // }

    if (file !== undefined) {
      setSelectedFile(file);
      setUploadPdfError("");
      // SetFileSubmitButtonDisabled(false);
      SetFileUploadButtonDisabled(true);
    }
  };

  const onHandlePolicyName = (e) => {
    setPolicyName(e.target.value);
  };

  // const isButtonDisabled = !(selectedFile !== null && )
  const hasValue = selectedFile !== null && selectedFile.name !== "";

  return (
    <div>
      <form onSubmit={onSubmit}>
        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              autoComplete="off"
              type="text"
              id="policy name"
              onChange={onHandlePolicyName}
            />
            <StyledLabel className="user-label">Policy Name*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        <div className="btns-container d-flex gap-3">
          <StyledFileInput
            accept=".pdf"
            onChange={handleFileSelect}
            id="fileInput"
            type="file"
            disabled={fileUploadButtonDisabled}
          />

          <StyledUploadLabel
            disabled={fileUploadButtonDisabled}
            htmlFor="fileInput"
            id="uploadBtn"
            style={
              fileUploadButtonDisabled
                ? { color: "#999999" }
                : { backgroundColor: "#E86020", color: "#ffffff" }
            }
          >
            Upload
          </StyledUploadLabel>

          <StyledInputGroup>
            <StyledInputContainer>
              <StyledInput
                value={selectedFile !== null ? selectedFile.name : ""}
                readOnly
                type="text"
                autoComplete="off"
              />
              <StyledLabel hasValue={hasValue} className="user-label">
                File Name
              </StyledLabel>
            </StyledInputContainer>
          </StyledInputGroup>

          <SecondaryButton
            type="button"
            onClick={() => {
              setSelectedFile(null);
              // SetFileSubmitButtonDisabled(true);
              SetFileUploadButtonDisabled(false);
            }}
          >
            Cancel
          </SecondaryButton>
        </div>

        <div className="d-flex justify-content-end gap-3 mt-3">
          <SecondaryButton type="button" onClick={closeModal}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            className="uploadBtn-2"
            disabled={!selectedFile || !policyName.trim()}
            type="submit"
          >
            Add
          </PrimaryButton>
        </div>
        {uploadPdfError && <ErrorText>*{uploadPdfError}</ErrorText>}
        {apiStatus === apiStatusConstants.inProgress && <UploadingLoader />}
      </form>
    </div>
  );
};

export default AddPolicyForm;
