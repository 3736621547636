import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
  StyledSelect,
  StyledSelectContainer,
  StyledSelectGroup,
} from "../../StyledCss/styledInputs";
import { addEmployee, getUserData } from "../../api/services/hroneApi";

import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import "./addEmployee.css";
import { ErrorText } from "../../StyledCss/styledComponents";

const AddEmployeePopup = (props) => {
  const { toggleIsPopupOpen, UpdateEmpAdded, fetchEmployees, leads, closeBtn } =
    props;
  const { role } = getUserData();
  const [showPswd, setShowPswd] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [dropdown, setDropDown] = useState("none");
  const [inValidDataErrorMsg, setInvalidError] = useState("");
  const [certificatesList, setCertificatesList] = useState({});

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropDown("none");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onSubmit = (data) => {
    handleAddEmployeeForm(data);
  };

  const onSelectCertificates = (e) => {
    const name = e.target.name;

    if (e.target.checked) {
      setCertificatesList({ ...certificatesList, [name]: true });
    } else {
      delete certificatesList[name];
      setCertificatesList({ ...certificatesList });
    }
  };

  const handleAddEmployeeForm = async (data) => {
    try {
      const response = await addEmployee(data, certificatesList);

      if (response.status === 200) {
        UpdateEmpAdded();
        toggleIsPopupOpen(); // to update popup open / close
        fetchEmployees(); // calling the fetch employee to refresh data
      } else {
        const data = await response.json();
        setInvalidError(data.detail);
      }
    } catch (error) {}
  };

  const toggleDropdown = () => {
    if (dropdown === "none") {
      setDropDown("block");
    } else {
      setDropDown("none");
    }
  };

  // const orgDetails = JSON.parse(localStorage.getItem("org"));
  // const { primaryColor } = orgDetails;

  return (
    <div className="popup-content">
      <form
        autoComplete="off"
        className="add-employee-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="add-employee-form-container">
          <StyledInputGroup>
            <StyledInputContainer>
              <StyledInput
                style={errors.firstName && { borderLeft: "10px solid red" }}
                type="text"
                {...register("firstName", {
                  required: true,
                  pattern: /^(?!\s)[a-zA-Z\s]{3,45}$/,
                })}
              />
              <StyledLabel className="user-label">First Name*</StyledLabel>
            </StyledInputContainer>
          </StyledInputGroup>

          <StyledInputGroup>
            <StyledInputContainer>
              <StyledInput
                style={errors.lastName && { borderLeft: "10px solid red" }}
                type="text"
                {...register("lastName", {
                  required: true,
                  pattern: /^(?!\s)[a-zA-Z\s]{3,45}$/,
                })}
              />
              <StyledLabel className="user-label">Last Name*</StyledLabel>
            </StyledInputContainer>
          </StyledInputGroup>

          <StyledInputGroup>
            <StyledInputContainer>
              <StyledInput
                id="employeeID"
                style={errors.id && { borderLeft: "10px solid red" }}
                type="text"
                {...register("id", {
                  required: true,
                  pattern: /^(?!\s)[a-zA-Z0-9]{3,10}$/,
                })}
              />
              <StyledLabel className="user-label">Employee ID*</StyledLabel>
            </StyledInputContainer>
          </StyledInputGroup>

          <StyledInputGroup>
            <StyledInputContainer>
              <StyledInput
                style={errors.email && { borderLeft: "10px solid red" }}
                type="text"
                {...register("email", {
                  required: true,
                  pattern:
                    /^(?!\s)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                })}
              />
              <StyledLabel className="user-label">Email*</StyledLabel>
            </StyledInputContainer>
          </StyledInputGroup>

          <StyledSelectGroup>
            <StyledSelectContainer>
              <StyledSelect
                style={errors.role && { borderLeft: "10px solid red" }}
                id="role"
                name="select"
                {...register("role", { required: true })}
              >
                <option value="">---Select Role---</option>
                <option value="Employee">Employee</option>
                {(role === "Admin" || role === "Super Admin") && (
                  <option value="Admin">Admin</option>
                )}
                <option value="HR">HR</option>
                <option value="Lead">Lead</option>
              </StyledSelect>
              <StyledLabel className="user-label">Role*</StyledLabel>
            </StyledSelectContainer>
          </StyledSelectGroup>

          <StyledInputGroup style={{ marginTop: "0px" }}>
            <StyledInputContainer>
              <StyledInput
                style={errors.password && { borderLeft: "10px solid red" }}
                type={showPswd ? "text" : "password"}
                {...register("password", {
                  required: true,
                  minLength: 6,
                  maxLength: 16,
                })}
              />
              <StyledLabel className="user-label">Password*</StyledLabel>
              {!showPswd ? (
                <i
                  onClick={() => setShowPswd(!showPswd)}
                  className="fa-solid fa-eye-slash  password-eye-icon"
                ></i>
              ) : (
                <i
                  onClick={() => setShowPswd(!showPswd)}
                  className="fa-solid fa-eye password-eye-icon"
                ></i>
              )}
            </StyledInputContainer>
          </StyledInputGroup>

          <StyledInputGroup>
            <StyledInputContainer>
              <StyledInput
                style={errors.dob && { borderLeft: "10px solid red" }}
                type="date"
                max="9999-12-31"
                {...register("dob", { required: true })}
              />
              <StyledLabel hasValue={true} className="user-label">
                DOB*
              </StyledLabel>
            </StyledInputContainer>
          </StyledInputGroup>

          <StyledInputGroup>
            <StyledInputContainer>
              <StyledInput
                style={errors.joiningDate && { borderLeft: "10px solid red" }}
                type="date"
                max="9999-12-31"
                // max={new Date().toISOString().split("T")[0]}
                {...register("joiningDate", { required: true })}
              />
              <StyledLabel hasValue={true} className="user-label">
                Joining Date*
              </StyledLabel>
            </StyledInputContainer>
          </StyledInputGroup>

          <StyledSelectGroup>
            <StyledSelectContainer>
              <StyledSelect
                style={
                  errors.qualifications && { borderLeft: "10px solid red" }
                }
                id="qualifications"
                {...register("qualifications", { required: true })}
              >
                <option value="">---select---</option>
                <option value="Degree">Degree</option>
                <option value="B.Tech">B.Tech</option>
                <option value="PG">PG</option>
                <option value="Other">Other</option>
              </StyledSelect>
              <StyledLabel className="user-label">Qualifications*</StyledLabel>
            </StyledSelectContainer>
          </StyledSelectGroup>

          <StyledInputGroup>
            <StyledInputContainer>
              <StyledInput
                style={errors.department && { borderLeft: "10px solid red" }}
                type="text"
                {...register("department", {
                  required: true,
                  pattern: /^(?!\s)[a-zA-Z.\s-'_]{3,60}$/,
                })}
              />
              <StyledLabel className="user-label">Department*</StyledLabel>
            </StyledInputContainer>
          </StyledInputGroup>

          <StyledSelectGroup>
            <StyledSelectContainer>
              <StyledSelect
                style={errors.gender && { borderLeft: "10px solid red" }}
                id="gender"
                {...register("gender", { required: true })}
              >
                <option value="">---select---</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Others">Others</option>
              </StyledSelect>
              <StyledLabel className="user-label">Gender*</StyledLabel>
            </StyledSelectContainer>
          </StyledSelectGroup>

          <StyledInputGroup>
            <StyledInputContainer>
              <StyledInput
                style={errors.mobileNumber && { borderLeft: "10px solid red" }}
                type="tel"
                {...register("mobileNumber", {
                  required: true,
                  maxLength: 10,
                  pattern: /[0-9]{10}/,
                })}
              />
              <StyledLabel className="user-label">Mobile No*</StyledLabel>
            </StyledInputContainer>
          </StyledInputGroup>

          <StyledSelectGroup>
            <StyledSelectContainer>
              <StyledSelect
                style={errors.bloodGroup && { borderLeft: "10px solid red" }}
                id="bloodGroup"
                {...register("bloodGroup", { required: true })}
              >
                <option className="option-bg" value="">
                  ---select---
                </option>
                <option className="option-bg" value="A+">
                  A+
                </option>
                <option value="B+">B+</option>
                <option value="AB+">AB+</option>
                <option value="O+">O+</option>
                <option value="A-">A-</option>
                <option value="B-">B-</option>
                <option value="AB-">AB-</option>
                <option value="O-">O-</option>
              </StyledSelect>
              <StyledLabel className="user-label">Blood Group*</StyledLabel>
            </StyledSelectContainer>
          </StyledSelectGroup>

          <StyledInputGroup>
            <StyledInputContainer>
              <StyledInput
                style={errors.designation && { borderLeft: "10px solid red" }}
                type="tel"
                {...register("designation", {
                  required: true,
                  // pattern: /^(?!\s)[A-Za-z.\-_ ]{2,45}$/,
                  pattern: /^(?!\s)[a-zA-Z.\s-'_ ]{3,60}$/,
                })}
              />
              <StyledLabel className="user-label">Designation*</StyledLabel>
            </StyledInputContainer>
          </StyledInputGroup>

          {role === "Admin" || role === "Super Admin" ? (
            <StyledInputGroup>
              <StyledInputContainer>
                <StyledInput
                  style={errors.salary && { borderLeft: "10px solid red" }}
                  id="salary"
                  type="number"
                  min="0"
                  {...register("salary", { required: true })}
                />
                <StyledLabel className="user-label">Salary*</StyledLabel>
              </StyledInputContainer>
            </StyledInputGroup>
          ) : null}

          <StyledInputGroup>
            <StyledInputContainer>
              <StyledInput
                style={errors.location && { borderLeft: "10px solid red" }}
                type="text"
                {...register("location", {
                  required: true,
                  pattern: /^(?!\s)[A-Za-z ]{3,45}$/i,
                })}
              />
              <StyledLabel className="user-label">Location*</StyledLabel>
            </StyledInputContainer>
          </StyledInputGroup>

          <StyledSelectGroup>
            <StyledSelectContainer>
              <StyledSelect
                style={errors.reportingTo && { borderLeft: "10px solid red" }}
                id="reportingTo"
                {...register("reportingTo", { required: true })}
              >
                <option className="option-bg" value="">
                  ---select---
                </option>

                {leads.map((eachLead) => (
                  <option value={eachLead}>{eachLead}</option>
                ))}
              </StyledSelect>
              <StyledLabel className="user-label">Reporting To*</StyledLabel>
            </StyledSelectContainer>
          </StyledSelectGroup>

          {/* certificates */}

          <div ref={dropdownRef}>
            <button
              className="dropdown-btn"
              onClick={toggleDropdown}
              type="button"
              style={{
                border: `1px solid #000000 `,
              }}
            >
              Certificates <MdOutlineKeyboardArrowDown />
            </button>

            <div
              className="certificates-dropdown shadow"
              style={{
                display: `${dropdown}`,
              }}
            >
              <li>
                <label htmlFor="10th">
                  <input
                    id="10th"
                    value="10th"
                    onChange={onSelectCertificates}
                    name="10th"
                    type="checkbox"
                  />{" "}
                  10th
                </label>
              </li>
              <li>
                <label>
                  <input
                    onChange={onSelectCertificates}
                    value="Intermediate"
                    type="checkbox"
                    name="Intermediate"
                  />{" "}
                  Intermediate
                </label>
              </li>

              <li>
                <label>
                  <input
                    onChange={onSelectCertificates}
                    value="Degree"
                    type="checkbox"
                    name="Degree"
                  />{" "}
                  Degree
                </label>
              </li>
              <li>
                <label>
                  <input
                    onChange={onSelectCertificates}
                    value="PG"
                    type="checkbox"
                    name="PG"
                  />{" "}
                  PG
                </label>
              </li>
              <li>
                <label>
                  <input
                    onChange={onSelectCertificates}
                    value="original Degree"
                    type="checkbox"
                    name="original Degree"
                  />{" "}
                  Original Degree
                </label>
              </li>
            </div>
          </div>
        </div>

        {inValidDataErrorMsg.length > 0 && (
          <ErrorText className="mt-2">*{inValidDataErrorMsg}</ErrorText>
        )}

        <div className="d-flex gap-2 justify-content-end mt-5">
          <SecondaryButton onClick={closeBtn} type="button">
            Cancel
          </SecondaryButton>
          <PrimaryButton type="submit">Add</PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default AddEmployeePopup;
