import React, { useEffect, useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import {
  assignResource,
  getExcessOrReleasedResources,
} from "../../api/services/hroneApi";
import { convertKeysToCamelCase } from "../../util/convertCamelCase";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import PopupModal from "../PopupModal";
import "./index.css";
import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
} from "../../StyledCss/styledInputs";
import { useForm } from "react-hook-form";
import { ErrorText } from "../../StyledCss/styledComponents";
import LoadingView from "../LoadingView";

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const ExcessOrReleasedResources = () => {
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
  const [releasedData, setReleasedData] = useState([]);
  const [assignClicked, setAssignClicked] = useState(false);
  const [rowData, setRowData] = useState({});
  const [currentDay, setCurrentDay] = useState("");

  useEffect(() => {
    fetchExcessOrReleasedResources();
    const date = new Date();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    const year = date.getFullYear();

    if (month < 10) {
      month = `0${month}`;
    }

    if (day < 10) {
      day = `0${day}`;
    }

    const formattedDate = `${year}-${month}-${day}`;
    setCurrentDay(formattedDate);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const countValue = watch("count");

  const fetchExcessOrReleasedResources = async () => {
    setApiStatus(apiStatusConstants.inProgress);
    try {
      const response = await getExcessOrReleasedResources();
      if (response.status === 200) {
        const responseData = await response.data;
        const updatedData = responseData.map((eachItem) =>
          convertKeysToCamelCase(eachItem)
        );
        console.log(updatedData);

        setReleasedData(updatedData);
        setApiStatus(apiStatusConstants.success);
      }
    } catch (error) {}
  };

  const assignResourceToEmployee = async (data) => {
    const resourceData = {
      id: rowData.id,
      employee_id: data.employeeId,
      assigned_from_id: rowData.employeeId,
      device_type: rowData.deviceType,
      model: rowData.model,
      serial_id: rowData.serialId,
      count: data.count ? parseInt(data.count) : 1,
      allocated_date: data.allocatedDate,
      status: "Active",
    };

    // console.log(resourceData, "resourceData");
    try {
      const response = await assignResource(resourceData);
      if (response.status === 200) {
        setAssignClicked(false);
        fetchExcessOrReleasedResources();
        reset();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleButtonClick = async (params) => {
    setAssignClicked(true);
    setRowData(params.data);
  };

  const closeModal = () => {
    setAssignClicked(false);
    reset();
  };

  const renderTable = () => {
    const paginationPageSize = 20;
    const columnDefs = [
      {
        headerName: "EMP ID",
        field: "employeeId",
        width: 150,
        filter: true,
        floatingFilter: true,
      },

      {
        headerName: "NAME",
        valueGetter: function (params) {
          const firstName = params.data.firstName;
          const lastName = params.data.lastName;
          const combinedData = firstName + " " + lastName;
          return combinedData;
        },
        filter: true,
        sortable: true,
        floatingFilter: true,
        editable: true,
      },

      {
        headerName: "DEVICE TYPE",
        field: "deviceType",
        width: 160,
        filter: true,
        floatingFilter: true,
      },

      {
        headerName: "MODEL",
        field: "model",
        width: 160,
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "SERIAL ID",
        field: "serialId",
        width: 160,
        filter: true,
        floatingFilter: true,
        tooltipField: "serialId",
        editable: true,
      },

      {
        headerName: "DEVICE COUNT",
        field: "count",
        width: 160,
        filter: true,
        floatingFilter: true,
      },
      // {
      //   headerName: "RELEASED DATE",
      //   field: "releasedDate",
      //   width: 180,
      //   filter: true,
      //   floatingFilter: true,
      // },

      {
        headerName: "STATUS",
        field: "status",
        width: 160,
        filter: true,
        floatingFilter: true,
      },

      {
        headerName: "REJECT",
        cellRenderer: (params) => (
          <SecondaryButton
            className="reject-btn d-flex  justify-content-center align-items-center"
            onClick={() => handleButtonClick(params)}
          >
            Assign To
          </SecondaryButton>
        ),
      },
    ];

    return (
      <div
        className="ag-theme-alpine"
        style={{
          height: "70vh",
          fontSize: "14px",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <AgGridReact
          title="All Resources"
          columnDefs={columnDefs}
          rowData={releasedData}
          pagination={true}
          paginationPageSize={paginationPageSize}
          //   defaultColDef={defaultColDef}
        ></AgGridReact>
      </div>
    );
  };

  const onSubmit = (data) => {
    assignResourceToEmployee(data);
  };

  const assignResourceForm = () => (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex assign-resource-form gap-3"
    >
      <StyledInputGroup>
        <StyledInputContainer>
          <StyledInput
            style={errors.employeeId && { borderLeft: "10px solid red" }}
            name="employeeId"
            type="text"
            {...register("employeeId", {
              required: true,
              pattern: /^(?!\s)[a-zA-Z0-9]{3,10}$/,
            })}
          />
          <StyledLabel className="user-label">Emp ID*</StyledLabel>
        </StyledInputContainer>
      </StyledInputGroup>

      {/* <StyledInputGroup>
        <StyledInputContainer>
          <StyledInput
            style={errors.AssignedFrom && { borderLeft: "10px solid red" }}
            // onChange={handleInputChange}
            name="AssignedFrom"
            type="text"
            {...register("AssignedFrom", {
              required: true,
              pattern: /^(?!\s)[a-zA-Z0-9]{3,10}$/,
            })}
          />
          <StyledLabel className="user-label">Assigned From ID*</StyledLabel>
        </StyledInputContainer>
      </StyledInputGroup> */}

      <StyledInputGroup>
        <StyledInputContainer>
          <StyledInput
            style={errors.allocatedDate && { borderLeft: "10px solid red" }}
            // onChange={handleInputChange}
            name="allocatedDate"
            // max="9999-12-31"
            max={currentDay}
            type="date"
            {...register("allocatedDate", {
              required: true,
            })}
          />
          <StyledLabel hasValue="true" className="user-label">
            Date*
          </StyledLabel>
        </StyledInputContainer>
      </StyledInputGroup>

      <StyledInputGroup>
        <StyledInputContainer>
          <StyledInput readOnly value={rowData.deviceType} type="text" />
          <StyledLabel
            hasValue={rowData.deviceType !== ""}
            className="user-label"
          >
            Device Type*
          </StyledLabel>
        </StyledInputContainer>
      </StyledInputGroup>

      {rowData.deviceType === "LAN" && (
        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={errors.count && { borderLeft: "10px solid red" }}
              type="text"
              name="count"
              {...register("count", {
                required: true,
              })}
              defaultValue={rowData.count}
            />
            <StyledLabel hasValue={true} className="user-label">
              Count*
            </StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>
      )}

      <StyledInputGroup>
        <StyledInputContainer>
          <StyledInput readOnly value={rowData.model} type="text" />
          <StyledLabel hasValue={rowData.model !== ""} className="user-label">
            Device Model*
          </StyledLabel>
        </StyledInputContainer>
      </StyledInputGroup>

      {rowData.deviceType !== "LAN" && (
        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput readOnly value={rowData.serialId} type="text" />
            <StyledLabel
              hasValue={rowData.serialId !== ""}
              className="user-label"
            >
              Serial ID*
            </StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>
      )}

      {countValue && (countValue > rowData.count || countValue < 1) && (
        <ErrorText style={{ marginTop: "10px" }}>
          *Count should be less than or equal to a {rowData.count}
        </ErrorText>
      )}

      {errors.count && (
        <ErrorText style={{ marginTop: "10px" }}>
          *Count should be less than or equal to {rowData.count}
        </ErrorText>
      )}

      <div className="d-flex w-100 justify-content-end gap-2 d-block me-5">
        <SecondaryButton onClick={closeModal} type="button">
          Cancel
        </SecondaryButton>
        <PrimaryButton type="submit" disabled={releaseButtonDisabled}>
          Submit
        </PrimaryButton>
      </div>
    </form>
  );

  const releaseButtonDisabled =
    countValue &&
    (countValue > rowData.count || countValue < 1 || countValue === "");

  const renderExcessOrReleasedTable = () => {
    switch (apiStatus) {
      case apiStatusConstants.inProgress:
        return <LoadingView />;

      case apiStatusConstants.success:
        return renderTable();

      case apiStatusConstants.failure:
        return null;

      default:
        break;
    }
  };

  return (
    <div>
      {renderExcessOrReleasedTable()}
      <PopupModal
        modalName="Assign To"
        closeBtn={closeModal}
        showModal={assignClicked}
      >
        {assignResourceForm()}
      </PopupModal>
    </div>
  );
};

export default ExcessOrReleasedResources;
