import React, { useEffect, useRef } from "react";
import { BsX } from "react-icons/bs";
import { CSSTransition } from "react-transition-group";
import "./slidingModal.css";

const SlidingModal = ({
  children,
  closeBtn,
  isModalOpen,
  modalName,
  heightDimensions,
}) => {
  const modalRef = useRef(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("keydown", handleEscapeKeyClick);
    return () => {
      document.removeEventListener("keydown", handleEscapeKeyClick);
      document.removeEventListener("mousedown", handleOutsideClick);
    }; // eslint-disable-next-line
  }, []);

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeBtn();
    }
  };
  const handleEscapeKeyClick = (event) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target) &&
      event.key === "Escape"
    ) {
      closeBtn();
    }
  };

  return (
    <CSSTransition
      in={isModalOpen}
      timeout={300}
      classNames="slidingModal"
      unmountOnExit
    >
      <div className="modal-container" ref={modalRef}>
        <div className="close-btn-div ">
          <p className="modal-heading">{modalName}</p>
          <BsX onClick={closeBtn} className="close-btn" />
        </div>
        <div className="modal-children">{children}</div>
      </div>
    </CSSTransition>
  );
};

export default SlidingModal;
