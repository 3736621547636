/* eslint-disable react-hooks/exhaustive-deps */
import { AiOutlineClose } from "react-icons/ai";
import { BsBellFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import {
  deleteNotifications,
  // getUserData,
  markNotifications,
} from "../../api/services/hroneApi";
import { openMobileMenu } from "../../redux/slices/mobileSlice";
import {
  clearAllNotifications,
  markNotificationsAsRead,
} from "../../redux/slices/notificationSlice";

// import { getMessaging,onMessage, } from "firebase/messaging"
// import app from "../../firebase"
import { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../StyledCss/styledbtn";
import "./index.css";

const Header = () => {
  const [notificationClicked, setNotificationClicked] = useState(false);
  const [notificationsCount, setNotificationCount] = useState();
  // const { firstName } = getUserData();
  const dispatch = useDispatch();

  const orgDetails = JSON.parse(localStorage.getItem("org"));
  const { secondaryColor, logoUrl, profilePic } = orgDetails
    ? orgDetails
    : () => {};

  const notificationsList = useSelector(
    (state) => state.notifications.notificationsList
  );

  useEffect(() => {
    displayNotificationsCount();
  }, [notificationsList]);

  const hambergerClicked = () => {
    dispatch(openMobileMenu());
  };

  const onClickNotification = () => {
    setNotificationClicked(!notificationClicked);
    setNotificationCount(0);
    markNotificationsRead();
  };

  const markNotificationsRead = async () => {
    try {
      const readNotificationList = notificationsList.map(
        (eachNotification) => ({
          ...eachNotification,
          isRead: true,
        })
      );
      dispatch(markNotificationsAsRead(readNotificationList));
      await markNotifications();
    } catch (err) {}
  };

  const onClickClose = () => {
    setNotificationClicked(false);
  };

  const clearNotifications = async () => {
    try {
      dispatch(clearAllNotifications());
      await deleteNotifications();
    } catch (err) {}
  };

  const notifications = () => (
    <>
      {notificationClicked && (
        <ul className="shadow notification-list-container">
          <div className="close-icon-container">
            <p style={{ fontSize: "18px", paddingTop: "10px" }}>
              Notifications
            </p>
            <button
              className="notify-close-btn"
              onClick={onClickClose}
              type="button"
            >
              <AiOutlineClose />
            </button>
          </div>
          <hr />
          {notificationsList.length < 1 ? (
            <p style={{ textAlign: "center" }}>No Notifications </p>
          ) : (
            notificationsList.map((eachItem, index) => (
              <li key={index}>
                <div className="notification-item">
                  <img
                    alt="profile-pic"
                    className="image"
                    src={eachItem.profilePicUrl}
                  />
                  <p>{eachItem.message}</p>
                </div>

                <hr />
              </li>
            ))
          )}

          {notificationsList.length > 0 && (
            <li className="text-end">
              <Button onClick={clearNotifications}>Clear All</Button>
            </li>
          )}
        </ul>
      )}
    </>
  );

  const displayNotificationsCount = () => {
    const notificationLength = notificationsList.filter(
      (eachNotification) => eachNotification.isRead === false
    );

    if (notificationLength.length === 0) {
      setNotificationCount(0);
    } else {
      setNotificationCount(notificationLength.length);
    }
  };

  return (
    <div
      className="header-container"
      style={{ backgroundColor: `${secondaryColor}` }}
    >
      <nav className="w-100 d-flex justify-content-between nav-container">
        <div onClick={hambergerClicked} className="mobile-hamberger-menu">
          <GiHamburgerMenu />
        </div>

        <Link to="/">
          <img className="company-logo-header" src={logoUrl} alt="logo" />
        </Link>

        <div className="header-elements-right">
          <button
            onClick={onClickNotification}
            type="button"
            className="notification-container notification-btn"
          >
            <div>
              <BsBellFill className="notification-icon" />
            </div>

            {notificationsCount > 100 ? (
              <p className="notification-count">100+</p>
            ) : (
              notificationsCount > 0 && (
                <p className="notification-count">{notificationsCount}</p>
              )
            )}
          </button>
          <button className="profile-icon-container d-flex align-items-center">
            {/* {firstName[0]} */}
            <img
              className="profile-icon-container"
              src={profilePic}
              alt="profile-pic"
            />
          </button>
        </div>
        <div>{notifications()}</div>
      </nav>
    </div>
  );
};

export default Header;
