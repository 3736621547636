/* eslint-disable react-hooks/exhaustive-deps */
// import DataTable from "react-data-table-component";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { convertKeysToCamelCase } from "../../util/convertCamelCase";
// import "./index.css";
import { getUserWorksByMonth } from "../../api/services/hroneApi";
import formatDate from "../../util/formatdate";

const ExtraworkHistory = ({ refetchData, startMonth }) => {
  const [rowData, setRowData] = useState([]);
  const formattedStartMonth = format(startMonth, "yyyy-MM");

  useEffect(() => {
    fetchLeaveHistory();
    // eslint-disable-next-line
  }, [startMonth]);

  useEffect(() => {
    if (refetchData === true) {
      fetchLeaveHistory();
    }
  }, [refetchData]);

  const fetchLeaveHistory = async () => {
    try {
      const response = await getUserWorksByMonth(formattedStartMonth);
      if (response.status === 200) {
        const data = await response.json();
        const camelCaseData = data.map((work) => convertKeysToCamelCase(work));
        const updatedData = camelCaseData.map((eachItem) => {
          const date = formatDate(eachItem.date);

          return { ...eachItem, date };
        });
        setRowData(updatedData);
      }
    } catch (err) {}
  };
  const columnDefs = [
    { headerName: "DATE", field: "date", filter: true, floatingFilter: true },
    { headerName: "HOURS", field: "hours", filter: true, floatingFilter: true },
    {
      headerName: "STATUS",
      field: "status",
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "REJECTION REASON",
      field: "rejectedReason",
      width: 300,
      tooltipField: "rejectedReason",
      filter: true,
      floatingFilter: true,
    },
  ];
  const paginationSize = 12;

  return (
    <>
      <div
        className="ag-theme-alpine"
        style={{
          height: "60vh",
          fontSize: "14px",
          marginLeft: "20px",
          marginRight: "20px",
          marginTop: "20px",
          fontFamily: "Arial, Helvetica, sans-serif",
        }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={true}
          paginationPageSize={paginationSize}
        ></AgGridReact>
      </div>
    </>
  );
};

export default ExtraworkHistory;
