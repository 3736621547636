import React, { useState } from "react";

import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import { updateLeave } from "../../api/services/hroneApi";
import {
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
  StyledTextarea,
} from "../../StyledCss/styledInputs";

const LeaveCancelForm = ({
  setIsLeaveCancelModalOpen,
  activeCancelId,
  getUserLeaves,
  requestType,
  closeBtn,
}) => {
  const [cancelReason, setcancelReason] = useState("");
  const [leaveReasonErr, setLeaveReasonErr] = useState(true);
  const notifyLeaveCancel = () => {
    toast.success(`${requestType} Cancelled successfully`);
  };
  const notifyLeaveCancelFailed = () =>
    toast.error("Error while cancelling an leave");

  const cancelLeave = async (event) => {
    event.preventDefault();

    // console.log(event)
    if (cancelReason.trim() === "") {
      setLeaveReasonErr(true);
    }
    if (cancelReason.trim() !== "") {
      try {
        const response = await updateLeave(
          "Cancelled",
          activeCancelId,
          cancelReason
        );

        if (response.ok) {
          getUserLeaves();
          setIsLeaveCancelModalOpen(false);
          notifyLeaveCancel();
          setcancelReason("");
          setLeaveReasonErr(false);
        }
      } catch (error) {
        notifyLeaveCancelFailed();
        setIsLeaveCancelModalOpen(false);
        setcancelReason("");
        setLeaveReasonErr(false);
      }
    }
  };

  return (
    <form autoComplete="off" onSubmit={cancelLeave}>
      {/* <div>
        <label htmlFor="reason">Reason for cancel:</label>
        <textarea
          onChange={(e) => {
            setcancelReason(e.target.value);
            setLeaveReasonErr("");
          }}
          value={cancelReason}
          id="reason"
          className=" form-control cancel-input"
          type="text"
        />
        {leaveReasonErr && <p className="text-danger">{leaveReasonErr}</p>}
      </div> */}
      <StyledInputGroup>
        <StyledInputContainer>
          <StyledTextarea
            onChange={(e) => {
              setcancelReason(e.target.value);
              if (e.target.value) {
                setLeaveReasonErr(false);
              } else {
                setLeaveReasonErr(true);
              }
            }}
            value={cancelReason}
            id="reason"
            // className=" form-control cancel-input"
            type="text"
          />
          <StyledLabel hasValue={cancelReason !== ""} className="user-label">
            Reason*
          </StyledLabel>
        </StyledInputContainer>
      </StyledInputGroup>

      <div className="d-flex justify-content-start mt-3 gap-3">
        <SecondaryButton onClick={closeBtn}>Cancel</SecondaryButton>
        <PrimaryButton
          disabled={leaveReasonErr}
          type="button"
          onClick={cancelLeave}
        >
          Submit
        </PrimaryButton>
      </div>
    </form>
  );
};

export default LeaveCancelForm;
