/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Button } from "../../StyledCss/styledbtn";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import "./tenantform.css";
import { updateTenantData } from "../../api/services/hroneApi";

function TenantEditForm({ refreshData, setEditModal, tenantsData }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("organization_name", tenantsData.organizationName);
    setValue("organization_code", tenantsData.organizationCode);
    setValue("organization_url", tenantsData.organizationUrl);
    setValue("contact_phone", tenantsData.mobileNumber);
    setValue("contact_email", tenantsData.contactEmail);
    setValue("status", tenantsData.status);
    setValue("urbuddi_url", tenantsData.domainUrl);
  }, [tenantsData]);

  const notifyTenantAdded = () => toast.success("Tenant Updated Successfully");
  const notifyError = () => toast.error("Something Went Wrong");
  const onSubmit = async (data) => {
    const updatedData = { ...data, tenant_id: tenantsData.tenantId };

    try {
      const response = await updateTenantData(updatedData);
      if (response.status === 200) {
        setEditModal();
        notifyTenantAdded();
        setTimeout(() => {
          refreshData();
        }, 1000);
      } else {
      }
    } catch (error) {
      notifyError();
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="expenditure-form"
      autoComplete="off"
    >
      <div className="d-flex align-items-center justify-content-between">
        <h3>Edit Tenant</h3>
      </div>

      <hr />

      <label className="mt-2">Organization Name</label>
      <input
        name="organization_name"
        className={
          errors.organization_name ? "highlight-error" : "tenant-form-input"
        }
        type="text"
        {...register("organization_name", {
          required: true,
          pattern: /^(?!\s)[a-zA-Z\s]{3,45}$/,
        })}
      />

      <label className="mt-2">Organization Code</label>
      <input
        name="organization_code"
        className={
          errors.organization_code ? "highlight-error" : "tenant-form-input"
        }
        type="text"
        {...register("organization_code", {
          required: true,
          pattern: /^(?!\s)[a-zA-Z\s]{2,3}$/,
        })}
      />

      <label className="mt-2">Email</label>
      <input
        name="contact_email"
        className={
          errors.contact_email ? "highlight-error" : "tenant-form-input"
        }
        type="email"
        {...register("contact_email", {
          required: true,
          pattern: /^(?!\s)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        })}
      />

      <label className="mt-2">Contact Number</label>
      <input
        name="contact_phone"
        className={
          errors.contact_phone ? "highlight-error" : "tenant-form-input"
        }
        maxLength="10"
        type="tel"
        {...register("contact_phone", {
          required: true,
          maxLength: 10,
          pattern: /[0-9]{10}/,
        })}
      />

      <label>Status</label>
      <select
        className={errors.status ? "highlight-error" : "tenant-form-input"}
        name="status"
        {...register("status", { required: true })}
      >
        <option value="">---select---</option>

        <option value="Active">Active</option>
        <option value="Inactive">Inactive</option>
      </select>

      <label className="mt-2">Organization URL</label>
      <input
        name="organization_url"
        className={
          errors.organization_url ? "highlight-error" : "tenant-form-input"
        }
        type="url"
        {...register("organization_url", { required: true })}
      />

      <label className="mt-2">Domain URL</label>
      <input
        name="urbuddi_url"
        className={errors.urbuddi_url ? "highlight-error" : "tenant-form-input"}
        type="url"
        {...register("urbuddi_url", { required: true })}
      />

      <div>
        <Button>SUBMIT</Button>
      </div>
    </form>
  );
}

export default TenantEditForm;
