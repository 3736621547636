import React, { useState } from "react";
import ContentLoader from "react-content-loader";
import { Toaster } from "react-hot-toast";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { deleteHoliday, getUserData } from "../../api/services/hroneApi";
import "./index.css";

const MyLoader = () => (
  <ContentLoader
    className="holidays-list-content-loader"
    backgroundColor="#E0E0E0"
  >
    <rect x="0" y="0" rx="5" ry="5" width="300" height="400" />
  </ContentLoader>
);

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const HolidaysCard = ({ apiStatus, holidaysData, refreshHolidays }) => {
  const [showHolidaysList, setShowHolidaysList] = useState(false);
  const { role } = getUserData();

  const formattedDate = (date) => {
    const parts = date.split("-");
    const month = parseInt(parts[1], 10);
    const day = parseInt(parts[0], 10);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const textDay = formattedDay(day, monthNames[month - 1], month);

    return textDay;
  };

  const currentDay = new Date().getDate();
  const currentMonth = new Date().getMonth();
  const formattedDay = (day, monthName, month) => {
    if (currentDay === day && currentMonth + 1 === month) {
      return "Today";
    } else if (currentDay + 1 === day && currentMonth + 1 === month) {
      return "Tomorrow";
    } else {
      return day + getDaySuffix(day) + " " + monthName;
    }
  };

  const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const onClickDeleteHoliday = async (holiday) => {
    try {
      const response = await deleteHoliday(holiday);
      if (response.status === 200) {
        refreshHolidays();
      }
    } catch (error) {}
  };

  const [showText, setShowText] = useState(false);

  const showOrHideHolidays = () => {
    setShowText(!showText);
    if (holidaysData.length >= 6) {
      setShowHolidaysList(!showHolidaysList);
    } else {
      setShowHolidaysList(false);
    }
  };

  const orgDetails = JSON.parse(localStorage.getItem("org"));
  const { primaryColor } = orgDetails;

  const renderHolidaysList = () => (
    <div className="events-container">
      <p className="birthday-Heading">Holiday's</p>
      {holidaysData.length > 0 ? (
        <>
          <ul
            style={{
              height: showHolidaysList ? "450px" : "258px",
            }}
            className="events-list"
          >
            {holidaysData.map((eachDay, index) => (
              <div className="holiday-display-card">
                <li key={index} className="holidays-card">
                  <p className="holiday-name">{eachDay.occasion}</p>
                  <p className="name-heading">{formattedDate(eachDay.date)}</p>
                </li>

                {(role === "Admin" ||
                  role === "Super Admin" ||
                  role === "HR") && (
                  <IoMdCloseCircleOutline
                    key={eachDay.date}
                    onClick={() => onClickDeleteHoliday(eachDay)}
                    className="holiday-delete-icon"
                  />
                )}
              </div>
            ))}
          </ul>
        </>
      ) : (
        <div
          style={{
            height: "274px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ textAlign: "center" }}>No Holidays </p>
        </div>
      )}

      <div
        // onClick={() => setShowHolidaysList(!showHolidaysList)}
        onClick={showOrHideHolidays}
        className="container-bottom-section"
      >
        <p style={{ color: primaryColor }}>
          {showText ? "Show Less" : "See All Holiday's"}
        </p>
      </div>
    </div>
  );

  const DisplayHolidaysList = () => {
    switch (apiStatus) {
      case apiStatusConstants.success:
        return renderHolidaysList();
      case apiStatusConstants.failure:
        return <MyLoader />;
      case apiStatusConstants.inProgress:
        return <MyLoader />;
      default:
        return null;
    }
  };

  return (
    <div>
      {DisplayHolidaysList()}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default HolidaysCard;
