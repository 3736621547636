import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDMs-FdAXq_eM6UzoypppPG28Z7b12Yk3Y",
  authDomain: "urbuddi-8d3fb.firebaseapp.com",
  projectId: "urbuddi-8d3fb",
  storageBucket: "urbuddi-8d3fb.appspot.com",
  messagingSenderId: "673431605393",
  appId: "1:673431605393:web:fed8a841e290d549e5ef30",
  measurementId: "G-Y9K0W9G0TF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

export default messaging









