import { Navigate } from "react-router-dom";
import { getUserData } from "../../api/services/hroneApi";

const employeeAccessiblePaths = [
  "payslip",
  "profile",
  "reimbursement",
  "login",
  "leave_management",
  "/",
  "policies",
];
const hrAccessiblePaths = [
  "payslip",
  "profile",
  "reimbursement",
  "login",
  "leave_management",
  "/",
  "resource_tracking",
  "reimbursement",
  "allemployees",
  "expenditure",
  "policies",
];

const leadAccessiblePath = [
  "payslip",
  "profile",
  "reimbursement",
  "login",
  "leave_management",
  "/",
  "reimbursement",
  "policies",
];

const ProtectedRoute = ({ children }) => {
  const path = children.props.path;
  const jwtToken = localStorage.getItem("loginDetails");

  let uerRole = "";

  if (jwtToken !== null) {
    const { role } = getUserData(jwtToken);
    uerRole = role;
  }

  if (uerRole !== null) {
    switch (uerRole) {
      case "Admin":
        return children;
      case "Super Admin":
        return children;
      case "Employee":
        if (employeeAccessiblePaths.includes(path)) {
          return children;
        }
        break;
      case "HR":
        if (hrAccessiblePaths.includes(path)) {
          return children;
        }
        break;
      case "Lead":
        if (leadAccessiblePath.includes(path)) {
          return children;
        }
        break;
      default:
        return <Navigate to="/login" />;
    }
  }

  return <Navigate to="/login" />;
};

export default ProtectedRoute;
