import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Button } from "../../StyledCss/styledbtn";
// import ExpenditureHistory from "../ExpenditureHistory";
import { AgGridReact } from "ag-grid-react";
import Header from "../Header";
import LeftNavBar from "../LeftNavBar";
import SlidingModal from "../SlidingModal/slidingModal";
import TenantForm from "../TenantForm/TenantForm";
// import TenantHistoryTable from "./tenantHistoryTable";
// import FileDownloader from "../ZipFilesDownloader";
import { AiFillDelete } from "react-icons/ai";
import toast from "react-hot-toast";
import { deleteTenant, getTenantsData } from "../../api/services/hroneApi";
// import ExpenditureForm from "../ExpenditureForm/expenditureForm";
import PageHeader from "../PageHeader";
import TenantEditForm from "../TenantForm/TenantEditForm";
import FailureView from "../FailureView";
import LoadingView from "../LoadingView";
import { PageHeaderName } from "../../StyledCss/styledComponents";
import "./tenant.css";

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const Tenant = () => {
  const [tenantsData, setTenantsData] = useState([]);
  // const [uploadBillModal, setUploadBillModal] = useState(false);
  const [singleTenantData, setSingleTenantData] = useState({});
  const [addTenantModal, setAddTenantModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);

  useEffect(() => {
    tenantsHistoryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tenantsHistoryData = async () => {
    setApiStatus(apiStatusConstants.inProgress);
    try {
      const response = await getTenantsData();
      if (response.status === 200) {
        setApiStatus(apiStatusConstants.success);

        const data = await response.json();

        const updatedData = data.map((eachItem) => ({
          tenantId: eachItem.tenant_id,
          organizationName: eachItem.organization_name,
          organizationCode: eachItem.organization_code,
          organizationUrl: eachItem.organization_url,
          mobileNumber: eachItem.contact_phone,
          contactEmail: eachItem.contact_email,
          status: eachItem.status,
          domainUrl: eachItem.urbuddi_url,
          password: eachItem.password,
        }));
        setTenantsData(updatedData);
      } else {
        setApiStatus(apiStatusConstants.failure);
      }
    } catch (error) {
      setApiStatus(apiStatusConstants.failure);
    }
  };

  const onCloseModal = () => {
    setEditModal(false);
  };

  const onCloseAddTenantModal = () => {
    setAddTenantModal(false);
  };

  const notifyTenantDeleted = () =>
    toast.success("Tenant Deleted Successfully");

  const deleteTenantData = async (tenantId) => {
    try {
      const response = await deleteTenant(tenantId);
      if (response.status === 200) {
        setTimeout(() => {
          tenantsHistoryData();
        }, 1000);
        notifyTenantDeleted();
      }
    } catch (error) {}
  };

  const tenantHistoryTable = () => {
    const columnDefs = [
      {
        headerName: "TENANT ID",
        field: "tenantId",
        width: 120,
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "ORGANIZATION NAME",
        field: "organizationName",
        width: 250,
        filter: true,
        floatingFilter: true,
        cellRenderer: (params) => {
          // const firstName = params.data.firstName;
          // const lastName = params.data.lastName;
          // const combinedData = firstName + " " + lastName;
          return (
            <p
              onClick={() => {
                setSingleTenantData(params.data);
                setEditModal(true);
                // console.log(params.data);
              }}
            >
              {params.data.organizationName}
            </p>
          );
        },
      },
      {
        headerName: "ORGANIZATION CODE",
        field: "organizationCode",
        tooltipField: "description",
        width: 190,
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "ORGANIZATION URL",
        field: "organizationUrl",
        width: 190,
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "DOMAIN URL",
        field: "domainUrl",
        width: 190,
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "EMAIL",
        field: "contactEmail",
        width: 190,
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "CONTACT NUMBER",
        field: "mobileNumber",
        width: 190,
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "STATUS",
        field: "status",
        width: 120,
        filter: true,
        floatingFilter: true,
      },
      {
        headerName: "DELETE",
        cellRenderer: (params) => (
          <AiFillDelete
            id={params.data.id}
            onClick={() => deleteTenantData(params.data.tenantId)}
            style={{ fontSize: "18px", cursor: "pointer" }}
          />
        ),
      },
    ];

    const paginationSize = 20;

    return (
      <div
        style={{
          height: "70vh",
          marginLeft: "15px",
          marginRight: "15px",
          fontSize: "14px",
          fontFamily: "Arial, Helvetica, sans-serif",
        }}
        className="ag-theme-alpine history-container"
      >
        <AgGridReact
          rowData={tenantsData}
          columnDefs={columnDefs}
          pagination={true}
          paginationPageSize={paginationSize}
        ></AgGridReact>
      </div>
    );
  };
  const onClickRetry = () => tenantsHistoryData();

  const renderTenantsHistoryTable = () => {
    switch (apiStatus) {
      case apiStatusConstants.success:
        return tenantHistoryTable();
      case apiStatusConstants.failure:
        return <FailureView onClickRetry={onClickRetry} />;
      case apiStatusConstants.inProgress:
        return <LoadingView />;
      default:
        return null;
    }
  };

  return (
    <div>
      <Header />
      <div className="d-flex ">
        <LeftNavBar />
        <div style={{ width: "100vw", backgroundColor: "#ededed" }}>
          <PageHeader>
            <PageHeaderName>Tenants</PageHeaderName>

            <Button
              style={{ marginRight: "10px" }}
              onClick={() => setAddTenantModal(!editModal)}
            >
              Add Tenant
            </Button>
          </PageHeader>

          <div className="tenant-history-page">
            {renderTenantsHistoryTable()}
          </div>
          <SlidingModal
            closeBtn={onCloseAddTenantModal}
            isModalOpen={addTenantModal}
          >
            <TenantForm
              refreshData={tenantsHistoryData}
              setAddTenantModal={setAddTenantModal}
            />
          </SlidingModal>

          <SlidingModal closeBtn={onCloseModal} isModalOpen={editModal}>
            <TenantEditForm
              tenantsData={singleTenantData}
              refreshData={tenantsHistoryData}
              setEditModal={setEditModal}
            />
          </SlidingModal>

          <Toaster position="top-center" reverseOrder={false} />
        </div>
      </div>
    </div>
  );
};

export default Tenant;
