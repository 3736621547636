import React, { useState } from "react";
import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
} from "../../StyledCss/styledInputs";
import { ErrorText } from "../../StyledCss/styledComponents";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import { addIncentive } from "../../api/services/hroneApi";
import "./index.css";

import { useForm } from "react-hook-form";

const AddIncentiveForm = ({
  closeBtn,
  NotifyIncentiveAdded,
  NotifyIncentiveFailure,
}) => {
  const [errorMsg, setErrorMsg] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    addIncentiveDetails(data);
  };

  const addIncentiveDetails = async (data) => {
    try {
      const updatedData = {
        employee_id: data.empId,
        incentive_amount: data.amount,
      };

      const response = await addIncentive(updatedData);

      if (response.status === 200) {
        NotifyIncentiveAdded();
        closeBtn();
      } else {
      }
    } catch (error) {
      // console.log(error.response.data);
      setErrorMsg(error.response.data);
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      className="d-flex flex-column gap-3"
    >
      <div className="d-flex gap-3">
        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={errors.empId && { borderLeft: "10px solid red" }}
              {...register("empId", {
                required: true,
                pattern: /^(?!\s)[a-zA-Z0-9]{3,10}$/,
              })}
              type="text"
              id="empId"
            />
            <StyledLabel className="user-label">Emp ID*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={errors.amount && { borderLeft: "10px solid red" }}
              {...register("amount", {
                required: true,
                pattern: /^[1-9][0-9]*$/,
              })}
              type="number"
              id="Amount"
            />
            <StyledLabel className="user-label">Amount*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>
      </div>

      {errorMsg && <ErrorText>*{errorMsg}</ErrorText>}

      <div className="d-flex justify-content-end gap-3">
        <SecondaryButton onClick={closeBtn} type="button">
          Cancel
        </SecondaryButton>
        <PrimaryButton type="submit">Submit</PrimaryButton>
      </div>
    </form>
  );
};

export default AddIncentiveForm;
