import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import { addSkill } from "../../api/services/hroneApi";
import { MdDeleteOutline } from "react-icons/md";

import "./addSkillForm.css";

import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
  StyledSelect,
  StyledSelectContainer,
  StyledSelectGroup,
} from "../../StyledCss/styledInputs";

const AddSkillForm = ({
  getSkillsList,
  setAddSkillClicked,
  notifySkillsAdded,
}) => {
  const [errorMsg, setErrorMsg] = useState("");
  // const notifySkillSaved = () => toast.success("Skill Added Successfully");

  const [skillInputs, setSkillInputs] = useState([
    { skill_name: "", skill_rating: "1" },
  ]);

  const handleSkillInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedInputs = [...skillInputs];
    const regex = /^[a-zA-Z0-9#+_\-. \W]+$/;
    // const regex = /^[a-zA-Z]+[a-zA-Z0-9#+_\-. \W]*$/;
    // Perform validation specifically for the 'name' field
    if (name === "skill_name") {
      if (/^\s+/.test(value)) {
        updatedInputs[index].skill_name = value;
        updatedInputs[index].error = true; // Set error flag if leading whitespace detected
      } else if (value.trim() !== "" && regex.test(value)) {
        updatedInputs[index].skill_name = value;
        updatedInputs[index].error = false; // Valid skill name
      } else {
        updatedInputs[index].skill_name = value;
        updatedInputs[index].error = true; // Invalid skill name based on the regex
      }
    } else {
      // For other fields like 'rating'
      updatedInputs[index][name] = value;
    }

    setSkillInputs(updatedInputs);
  };
  const handleSkillRatingChange = (index, e) => {
    const rating = e.target.value;
    const updatedInputs = [...skillInputs];
    updatedInputs[index].skill_rating = rating;
    setSkillInputs(updatedInputs);
  };

  const onClickSubmitSkills = async (e) => {
    e.preventDefault();

    // Check if any input field is empty
    const updatedInputs = skillInputs.map((input) => {
      if (input.skill_name.trim() === "" || input.skill_rating === "") {
        return {
          ...input,
          error: true, // Set error flag for empty input fields
        };
      }

      return input;
    });

    // Update state with modified inputs
    setSkillInputs(updatedInputs);

    const hasErrors = updatedInputs.some((input) => input.error);
    if (!hasErrors) {
      try {
        const cleanedInputs = updatedInputs.map(({ error, ...rest }) => rest);
        const response = await addSkill(cleanedInputs);
        if (response.ok) {
          setAddSkillClicked(false);
          notifySkillsAdded();
          getSkillsList();
          setErrorMsg("");
        } else {
          const data = await response.json();
          setErrorMsg(data.detail);
          // setSkillRatingError(true);
        }
      } catch (error) {}
    }
  };

  const closeModal = (e) => {
    e.preventDefault();
    setAddSkillClicked();
  };

  const addAnotherSkill = (e) => {
    e.preventDefault();
    setSkillInputs([...skillInputs, { skill_name: "", skill_rating: "1" }]);
  };

  const deleteSkill = (indexToDelete) => {
    setSkillInputs((prevInputs) =>
      prevInputs.filter((_, index) => index !== indexToDelete)
    );
  };

  return (
    <div>
      {skillInputs.map((input, index) => (
        <form autoComplete="off" key={index}>
          <div className="d-flex align-items-center gap-3 mb-3">
            <StyledInputGroup>
              <StyledInputContainer>
                <StyledInput
                  inputError={input.error}
                  value={input.skill_name}
                  type="text"
                  name="skill_name"
                  autoComplete="off"
                  onChange={(e) => handleSkillInputChange(index, e)}
                />
                <StyledLabel className="user-label">Skill Name*</StyledLabel>
              </StyledInputContainer>
            </StyledInputGroup>

            <StyledSelectGroup>
              <StyledSelectContainer>
                <StyledSelect
                  width="120px"
                  onChange={(e) => handleSkillRatingChange(index, e)}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </StyledSelect>
                <StyledLabel className="user-label">Rating*</StyledLabel>
              </StyledSelectContainer>
            </StyledSelectGroup>

            {index >= 1 && (
              <button
                type="button"
                onClick={() => deleteSkill(index)}
                className="deleteButton"
              >
                <MdDeleteOutline className="deleteIcon" />
              </button>
            )}
          </div>
        </form>
      ))}

      <button className="addSkillBtn" onClick={addAnotherSkill}>
        <FaPlus style={{ marginRight: "10px", color: "#E86020" }} />
        Add Another skill
      </button>

      {errorMsg && <p className="text-danger mt-5">*{errorMsg}</p>}

      <div className="d-flex justify-content-end gap-3 mt-3">
        <SecondaryButton style={{ marginRight: "10px" }} onClick={closeModal}>
          Cancel
        </SecondaryButton>
        <PrimaryButton onClick={onClickSubmitSkills} type="button">
          Submit
        </PrimaryButton>
      </div>
    </div>
  );
};
export default AddSkillForm;
