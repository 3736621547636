/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import ContentLoader from "react-content-loader";
import { Toaster } from "react-hot-toast";
// import { AiOutlinePlus } from "react-icons/ai";
// import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdCloseCircleOutline } from "react-icons/io";

import { deleteEvent, getUserData } from "../../api/services/hroneApi";
// import formatDate from "../../util/formatdate";
import "../CommonCss/slidingModal.css";
import "./index.css";

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const MyLoader = () => (
  <ContentLoader
    className="holidays-list-content-loader"
    backgroundColor="#E0E0E0"
  >
    <rect x="0" y="0" rx="5" ry="5" width="300" height="400" />
  </ContentLoader>
);

const UpcommingEvents = ({ apiStatus, eventsData, refreshEvents }) => {
  const { role } = getUserData();
  const [showEventsList, setShowEventsList] = useState(false);
  const [showText, setShowText] = useState(false);

  const onClickDeleteEvent = async (eachEvent) => {
    try {
      const response = await deleteEvent(eachEvent);
      if (response.status === 200) {
        // notifyEventDeleted();
        // getUpcommingEvents();
        refreshEvents();
      }
    } catch (error) {}
  };

  const formattedDate = (birthdayDate) => {
    const parts = birthdayDate.split("-");
    const month = parseInt(parts[1], 10);
    const day = parseInt(parts[0], 10);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const textDay = formattedDay(day, monthNames[month - 1], month);

    return textDay;
  };
  const currentDay = new Date().getDate();
  const currentMonth = new Date().getMonth();

  const formattedDay = (day, monthName, month) => {
    if (currentDay === day && currentMonth + 1 === month) {
      return "Today";
    } else if (currentDay + 1 === day && currentMonth + 1 === month) {
      return "Tomorrow";
    } else {
      return day + getDaySuffix(day) + " " + monthName;
    }
  };

  const getDaySuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const showOrHideEvents = () => {
    setShowText(!showText);
    if (eventsData.length >= 6) {
      setShowEventsList(!showEventsList);
    } else {
      setShowEventsList(false);
    }
  };
  const orgDetails = JSON.parse(localStorage.getItem("org"));
  const { primaryColor } = orgDetails;

  const renderEvents = () => (
    <div className="events-container">
      <p className="birthday-Heading">Event's</p>
      {eventsData.length > 0 ? (
        <ul
          style={{
            height: showEventsList ? "450px" : "258px",
          }}
          className="events-list"
        >
          {eventsData.map((eachDay, index) => (
            <div key={index} className="events-display-card">
              <li className="events-card">
                <p className="event-name">{eachDay.event}</p>
                <p className="name-heading">{formattedDate(eachDay.date)}</p>
              </li>

              {(role === "Admin" ||
                role === "Super Admin" ||
                role === "HR") && (
                <IoMdCloseCircleOutline
                  onClick={() => onClickDeleteEvent(eachDay)}
                  className="event-delete-icon"
                />
              )}
            </div>
          ))}
        </ul>
      ) : (
        <div
          style={{
            height: "274px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ textAlign: "center" }}>No Events In This month </p>
        </div>
      )}

      <div
        // onClick={() => setShowEventsList(!showEventsList)}
        onClick={showOrHideEvents}
        className="container-bottom-section"
      >
        <p style={{ color: primaryColor }}>
          {showText ? "Show Less" : "See All Event's"}
        </p>
      </div>
    </div>
  );

  const DisplayEventsList = () => {
    switch (apiStatus) {
      case apiStatusConstants.success:
        return renderEvents();
      case apiStatusConstants.failure:
        return <MyLoader />;
      case apiStatusConstants.inProgress:
        return <MyLoader />;
      default:
        return null;
    }
  };

  return (
    <>
      {DisplayEventsList()}

      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default UpcommingEvents;
