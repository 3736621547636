/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import * as yup from "yup";
// import { Button } from "../../StyledCss/styledbtn";
import { verifyOtp } from "../../api/services/hroneApi";
// import { validateOtp } from "../../redux/slices/resetPswdSlice";
import jwt_decode from "jwt-decode";
import { CardHeadings, CardText } from "../../StyledCss/styledComponents";
import { LoginPageButton } from "../../StyledCss/styledbtn";
import { verifyUser } from "../../api/services/hroneApi";
import { notifyFailure, notifySuccess } from "../../util/react-toaster";
import "./otpEnter.css";
// Define a yup schema for OTP validation
const otpSchema = yup
  .string()
  .required("OTP is required")
  .matches(/^\d{6}$/, "OTP must be a 6-digit number");

const OTPEnter = () => {
  const [time, setTime] = useState(60);
  const [isActive, setIsActive] = useState(true);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
  const [isLoading, setIsLoading] = useState(false);
  // const isEmailValidated = useSelector(state => state.resetPswd.isEmailValidated);
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const userResetToken = JSON.parse(sessionStorage.getItem("userResetToken"));
  const { email_validated, otp_validated, email } = userResetToken
    ? jwt_decode(userResetToken)
    : () => {};

  // Initialize the yup validation error state
  const [otpError, setOtpError] = useState("");

  useEffect(() => {
    let timer;
    if (isActive && time > 0) {
      timer = setInterval(() => {
        setTime(time - 1);
      }, 1000);
    } else if (time === 0) {
      setIsActive(false);
    }
    return () => {
      clearInterval(timer);
    };
  }, [time]);

  useEffect(() => {
    setOtpError("");
  }, [otp]); // Clear the error when the OTP input changes

  const handleChange = (e, index) => {
    const value = e.target.value;

    if (!isNaN(value) && value.length <= 1) {
      otp[index] = value;
      setOtp([...otp]);

      if (index < 5 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleBackspace = (e, index) => {
    if (index > 0 && e.key === "Backspace" && otp[index] === "") {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleResendBtn = async (e) => {
    e.preventDefault();
    try {
      const userObj = {
        employee_email: email,
        keyword: "forgotPassword",
      };
      const response = await verifyUser(userObj);
      if (response.status === 200) {
        setIsActive(true);
        setTime(60);
        notifySuccess(`OTP resent to your email ${email} successfully`);
      } else {
        notifyFailure(response.data.message);
      }
    } catch (err) {
      // toast.failure(err.response.data.detail)
    }
  };

  const handleSubmitOtp = (e) => {
    e.preventDefault();

    // Validate OTP using yup
    otpSchema
      .isValid(otp.join(""))
      .then((valid) => {
        if (valid) {
          setIsLoading(true);
          verifyOtp(otp.join(""))
            .then((response) => {
              // console.log(response);
              if (response.status === 200) {
                // dispatch(validateOtp());

                navigate("/reset-passwd");
              } else {
                setOtpError(response.data.message);
                setIsLoading(false);
              }
            })
            .catch((err) => {
              setOtpError(err.response.data.detail);
              setIsLoading(false);
            });
        } else {
          setOtpError("OTP must be a 6-digit number");
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const formattedTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} : ${
      remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}`;
  };

  const image = process.env.PUBLIC_URL + "/forgotpassword.jpg";
  const companyLogo = process.env.PUBLIC_URL + "/urbuddilogo.png";

  return otp_validated && email_validated ? (
    <Navigate to="/reset-passwd" />
  ) : email_validated ? (
    <>
      <div className="d-flex align-items-center optScreen-container">
        <img style={{ height: "60%" }} src={image} alt="forgotPasswordVector" />

        <div className="otp-div">
          {/* <div className="col-11 col-sm-8 col-md-8 col-lg-8 col-xl-8"> */}
          <div className="optCard-container">
            <img className="company-logo" src={companyLogo} alt="companyLogo" />

            <CardHeadings>Forgot Password</CardHeadings>
            <CardText>OTP has sent to {email}</CardText>
            <form>
              <p className="text-start">Enter OTP</p>
              <div className="otp-input-div">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    ref={inputRefs[index]}
                    className="otp-input form-control"
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleBackspace(e, index)}
                    autoFocus={index === 0}
                  />
                ))}
              </div>
              <div className="d-flex align-items-center">
                <LoginPageButton onClick={handleSubmitOtp} type="submit">
                  {isLoading ? (
                    <ClipLoader color="#FFFFFF" />
                  ) : (
                    <p style={{ paddingTop: "12px" }}>Submit</p>
                  )}
                </LoginPageButton>
              </div>
              {otpError && <p className="text-center error-text">{otpError}</p>}
            </form>

            {isActive && <p className="timer">{formattedTime(time)}</p>}

            {!isActive && (
              <div className="text-center w-100">
                <p style={{ fontSize: "14px" }} className="mt-4 mb-0">
                  Didn't receive yet?{" "}
                </p>
                <button onClick={handleResendBtn} className="resend-btn">
                  Resend OTP
                </button>
              </div>
            )}

            {/* <p className="mt-4 mb-0">Didn't receive yet? </p>
            <button onClick={handleResendBtn} className="resend-btn">
              Resend code
            </button> */}
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  ) : (
    <Navigate to="/forgot-passwd" />
  );
};

export default OTPEnter;
