import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
} from "../../StyledCss/styledInputs";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import { addEvent } from "../../api/services/hroneApi";
import { ErrorText } from "../../StyledCss/styledComponents";
import "./addEventForm.css";

const AddEventForm = ({ refreshEvents, closeEventsModal }) => {
  const [eventName, setEventName] = useState("");
  const [eventError, setEventError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [eventDate, setEventDate] = useState("");
  const [invalidYear, setInvalidYear] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const notifyEventAdded = () => toast.success("Event Added Successfully");
  const currentDate = () => {
    const todayDate = new Date();
    const dateString = todayDate.toISOString().split("T")[0];
    return dateString;
  };
  const onChangeEvent = (e) => {
    const eventName = e.target.value;
    // const regex = /^[a-zA-Z\s]+$/;
    // const regex = "[A-z0-9A-Zs]+";

    // if (/^\s+/.test(eventName)) {
    //   setEventError(true);
    //   setEventName(eventName);
    // } else
    if (eventName.trim() !== "") {
      setEventError(false);
      setEventName(eventName);
    } else {
      setEventError(true);
      setEventName(eventName);
    }
  };

  const onChangeDate = (e) => {
    const eventDate = e.target.value;
    if (eventDate !== "") {
      setInvalidYear("");
      setDateError(false);
      setEventDate(eventDate);
    } else {
      setDateError(true);
      setEventDate(eventDate);
    }
  };

  const onClickSubmitEvent = async (e) => {
    e.preventDefault();

    const year = new Date(eventDate).getFullYear();

    if (isNaN(year) || year.toString().length !== 4) {
      setInvalidYear("Invalid year. Please enter a valid 4-digit year.");
      setDateError(true);
      if (eventName.trim() === "") {
        setEventError(true);
      }
    } else {
      if (eventName.trim() === "" && eventDate === "") {
        setDateError(true);
        setEventError(true);
      } else if (eventName.trim() === "") {
        setEventError(true);
      } else if (eventDate === "") {
        setDateError(true);
      } else if (
        eventName.trim() !== "" &&
        !eventError &&
        eventDate !== "" &&
        !dateError
      ) {
        const addEventObj = {
          eventName,
          eventDate,
        };
        try {
          const response = await addEvent(addEventObj);
          if (response.ok) {
            notifyEventAdded();
            setEventDate("");
            setEventName("");
            setErrorMsg("");
            closeEventsModal();
            refreshEvents();
          } else {
            // setDateError(true);
            // setEventError(true);
            const data = await response.json();
            setErrorMsg(data.detail);
          }
        } catch (error) {}
      }
    }
  };

  return (
    <form
      autoComplete="off"
      onSubmit={onClickSubmitEvent}
      className="d-flex flex-column add-Event-form"
    >
      <div className="d-flex gap-3">
        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={eventError ? { borderLeft: "10px solid red" } : {}}
              value={eventName}
              name="event"
              onChange={onChangeEvent}
              type="text"
            />
            <StyledLabel className="user-label">Event*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={dateError ? { borderLeft: "10px solid red" } : {}}
              min={currentDate()}
              name="date"
              onChange={onChangeDate}
              value={eventDate}
              // className={dateError ? "event-error" : "input-element"}
              type="date"
              max="9999-12-31"
            />
            <StyledLabel className="user-label">Date*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>
      </div>

      {errorMsg.length > 0 && <ErrorText>*{errorMsg}</ErrorText>}
      {invalidYear && eventDate !== "" && (
        <p style={{ color: "red" }}>*{invalidYear}</p>
      )}
      <div className="add-event-submit-btn">
        <SecondaryButton onClick={() => closeEventsModal()} type="button">
          Cancel
        </SecondaryButton>
        <PrimaryButton type="submit">Submit</PrimaryButton>
      </div>
    </form>
  );
};

export default AddEventForm;
