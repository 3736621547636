import { format } from "date-fns";

import DatePicker from "react-datepicker";

import "./regenPayslip.css";

import React, { useState } from "react";
import { notifySuccess } from "../../util/react-toaster";

import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
} from "../../StyledCss/styledInputs";
import { regenerateUserPayslip } from "../../api/services/hroneApi";

import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
// import "./index.css";

import { useForm } from "react-hook-form";

const RegeneratePayslipForm = ({
  closeBtn,
  // NotifyIncentiveAdded,
  // NotifyIncentiveFailure,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedRadioButton, setSelectedRadioButton] =
    useState("singleEmployee");
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = (data) => {
    generatePayslip(data);
  };

  const generatePayslip = async (formData) => {
    try {
      const data = {
        employee_id_to_update:
          selectedRadioButton === "allEmployees" ? "" : formData.empId,
        all_employees: selectedRadioButton === "allEmployees",
      };

      // const fullDate = formData.date;
      const formattedMonth = selectedMonth
        ? format(selectedMonth, "yyyy-MM")
        : null; // Extracts characters from index 0 to 6 (inclusive)

      const response = await regenerateUserPayslip(data, formattedMonth);
      if (response.status === 200) {
        notifySuccess(
          `Payslip regenerated to ${data.employee_id_to_update} for ${formattedMonth} successfully `
        );
        closeBtn();
      } else {
        const data = await response.json();
        // console.log(data);
        setErrorMessage(data.message);
      }
    } catch (error) {
      // NotifyIncentiveFailure();
    }
  };

  const regeneratePayslipForSingleEmployeeForm = () => (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} className="">
      <div className="d-flex gap-3 mt-3 mb-3">
        <StyledInputGroup>
          <StyledInputContainer>
            <DatePicker
              id="date"
              onChange={(date) => {
                if (date) {
                  setSelectedMonth(date);
                } else {
                  setSelectedMonth(new Date());
                }
              }}
              selected={selectedMonth}
              className="month-input-label"
              showMonthYearPicker
              dateFormat="MMMM yyyy"
            />
          </StyledInputContainer>
        </StyledInputGroup>

        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={errors.empId && { borderLeft: "10px solid red" }}
              {...register("empId", {
                required: true,
                pattern: /^(?!\s)[a-zA-Z0-9]{3,10}$/,
              })}
              type="text"
              id="EmpID"
            />
            <StyledLabel className="user-label">Emp ID*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>
      </div>

      <div className="d-flex justify-content-end gap-3">
        <SecondaryButton onClick={closeBtn} type="button">
          Cancel
        </SecondaryButton>
        <PrimaryButton type="submit">Submit</PrimaryButton>
      </div>

      {errorMessage && <p style={{ color: "red" }}>*{errorMessage}</p>}
    </form>
  );

  const regeneratePayslipForAllEmployeeForm = () => (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: "700px" }}
    >
      <div className="d-flex gap-3 mt-3 mb-3">
        <StyledInputGroup>
          <StyledInputContainer>
            <DatePicker
              id="date"
              onChange={(date) => {
                if (date) {
                  setSelectedMonth(date);
                } else {
                  setSelectedMonth(new Date());
                }
              }}
              selected={selectedMonth}
              className="month-input-label"
              showMonthYearPicker
              dateFormat="MMMM yyyy"
            />
          </StyledInputContainer>
        </StyledInputGroup>

        {/* <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={errors.empId && { borderLeft: "10px solid red" }}
              {...register("empId", {
                required: true,
                pattern: /^(?!\s)[a-zA-Z0-9]{3,10}$/,
              })}
              type="text"
              id="EmpID"
            />
            <StyledLabel className="user-label">Emp ID*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup> */}
      </div>

      <div className="d-flex justify-content-end gap-3">
        <SecondaryButton onClick={closeBtn} type="button">
          Cancel
        </SecondaryButton>
        <PrimaryButton type="submit">Submit</PrimaryButton>
      </div>

      {errorMessage && <p style={{ color: "red" }}>*{errorMessage}</p>}
    </form>
  );

  return (
    // <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} className="">
    //   <div className="d-flex gap-3 mt-3 mb-3">
    //     <StyledInputGroup>
    //       <StyledInputContainer>
    //         <DatePicker
    //           id="date"
    //           onChange={(date) => {
    //             if (date) {
    //               setSelectedMonth(date);
    //             } else {
    //               setSelectedMonth(new Date());
    //             }
    //           }}
    //           selected={selectedMonth}
    //           className="month-input-label"
    //           showMonthYearPicker
    //           dateFormat="MMMM yyyy"
    //         />
    //       </StyledInputContainer>
    //     </StyledInputGroup>

    //     <StyledInputGroup>
    //       <StyledInputContainer>
    //         <StyledInput
    //           style={errors.empId && { borderLeft: "10px solid red" }}
    //           {...register("empId", {
    //             required: true,
    //             pattern: /^(?!\s)[a-zA-Z0-9]{3,10}$/,
    //           })}
    //           type="text"
    //           id="EmpID"
    //         />
    //         <StyledLabel className="user-label">Emp ID*</StyledLabel>
    //       </StyledInputContainer>
    //     </StyledInputGroup>
    //   </div>

    //   <div className="d-flex justify-content-end gap-3">
    //     <SecondaryButton onClick={closeBtn} type="button">
    //       Cancel
    //     </SecondaryButton>
    //     <PrimaryButton type="submit">Submit</PrimaryButton>
    //   </div>
    // </form>
    <>
      <div className="d-flex">
        <div className="d-flex me-5">
          <input
            className="me-2"
            id="singleEmployee"
            type="radio"
            name="singleEmployee"
            value="singleEmployee"
            checked={selectedRadioButton === "singleEmployee"}
            onChange={(e) => {
              setSelectedRadioButton(e.target.value);
              setErrorMessage("");
            }}
          />
          <label htmlFor="singleEmployee">Single Employee</label>
        </div>

        <div className="d-flex">
          <input
            className="me-2"
            id="allEmployees"
            type="radio"
            name="allEmployees"
            value="allEmployees"
            onChange={(e) => {
              setSelectedRadioButton(e.target.value);
              setErrorMessage("");
            }}
            checked={selectedRadioButton === "allEmployees"}
          />
          <label htmlFor="allEmployees">All Employees</label>
        </div>
      </div>

      {selectedRadioButton === "singleEmployee"
        ? regeneratePayslipForSingleEmployeeForm()
        : regeneratePayslipForAllEmployeeForm()}
    </>
  );
};

export default RegeneratePayslipForm;
