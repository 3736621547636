import axios from "axios";
import JSZip from "jszip";
import React from "react";
import { PrimaryButton } from "../StyledCss/styledbtn";

class FileDownloader extends React.Component {
  downloadFiles = async () => {
    const { links, date } = this.props;
    const zip = new JSZip();

    // Download and add image files to the zip
   
      try {
        for (const link of links) {
        const response = await axios.get(link, {
          responseType: "arraybuffer",
        });

        const fileName = link.split("/").pop();
        // Specify the desired folder structure within the ZIP
        // const folderStructure = "expenditure_bills";
        // zip.file(`${folderStructure}/${fileName}`, response.data);
        zip.file(fileName, response.data);
      }
        // zip.file(link, response.data);
      } catch (error) {
        console.error("Error downloading image:", error);
      }
    

    // Download and add PDF files to the zip

    // Generate the zip file
    zip.generateAsync({ type: "blob" }).then((content) => {
      const blob = new Blob([content]);
      const zipUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = zipUrl;
      a.download = `Expenditure_bills_${date}.zip`;
      a.click();
    });
  };

  render() {
    return (
      <PrimaryButton style={{ marginRight: "10px" }} onClick={this.downloadFiles}>
        Download Files
      </PrimaryButton>
    );
  }
}

export default FileDownloader;
