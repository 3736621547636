import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import "../../CommonCss/form.css";
import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
  StyledSelect,
  StyledSelectContainer,
  StyledSelectGroup,
} from "../../StyledCss/styledInputs";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import { editEmployee, getUserData } from "../../api/services/hroneApi";
import "./index.css";

const EditEmployeeModal = (props) => {
  const {
    closeEditEmployeeModel,
    singleEmployeeData,
    notifyUpdated,
    fetchEmployees,
    leads,
  } = props;
  const [dropdown, setDropDown] = useState("none");
  const [updatedCertificates, setUpdatedCertificates] = useState({
    tenth: singleEmployeeData.tenth ? true : null,
    intermediate: singleEmployeeData.intermediate ? true : null,
    degree: singleEmployeeData.degree ? true : null,
    masters: singleEmployeeData.masters ? true : null,
    originalDegree: singleEmployeeData.originalDegree ? true : null,
  });
  const { role } = getUserData();

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropDown("none");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // const orgDetails = JSON.parse(localStorage.getItem("org"));
  // const { primaryColor } = orgDetails;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSelectCertificates = (e) => {
    const name = e.target.name;
    if (e.target.checked) {
      setUpdatedCertificates({ ...updatedCertificates, [name]: true });
    } else {
      // delete updatedCertificates[name]
      setUpdatedCertificates({ ...updatedCertificates, [name]: null });
    }
  };

  const onSubmit = (data) => {
    handleUpdateEmployeeForm(data);
  };

  const handleUpdateEmployeeForm = async (data) => {
    try {
      const response = await editEmployee(data, updatedCertificates);
      if (response.ok) {
        closeEditEmployeeModel();
        notifyUpdated();
        fetchEmployees();
      }
    } catch {}
  };

  const toggleDropdown = () => {
    if (dropdown === "none") {
      setDropDown("block");
    } else {
      setDropDown("none");
    }
  };

  const formatDate = (date) => {
    const splitDate = date.split("-");
    return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      className="edit-Employee-modal"
    >
      <div className="edit-employee-form-container pt-3" autoComplete="off">
        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              defaultValue={singleEmployeeData.firstName}
              style={errors.firstName && { borderLeft: "10px solid red" }}
              type="text"
              {...register("firstName", {
                required: true,
                pattern: /^(?!\s)[a-zA-Z\s]{3,45}$/,
              })}
            />
            <StyledLabel className="user-label">First Name*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              defaultValue={singleEmployeeData.lastName}
              style={errors.lastName && { borderLeft: "10px solid red" }}
              type="text"
              {...register("lastName", {
                required: true,
                pattern: /^(?!\s)[a-zA-Z\s]{3,45}$/,
              })}
            />
            <StyledLabel className="user-label">Last Name*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              defaultValue={singleEmployeeData.id}
              style={errors.id && { borderLeft: "10px solid red" }}
              type="text"
              {...register("id", {
                required: true,
                pattern: /^(?!\s)[a-zA-Z0-9]{3,10}$/,
              })}
            />
            <StyledLabel className="user-label">Employee ID*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              defaultValue={singleEmployeeData.email}
              style={errors.email && { borderLeft: "10px solid red" }}
              type="text"
              {...register("email", {
                required: true,
                pattern:
                  /^(?!\s)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              })}
            />
            <StyledLabel className="user-label">Email*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        <StyledSelectGroup>
          <StyledSelectContainer>
            <StyledSelect
              defaultValue={singleEmployeeData.role}
              style={errors.role && { borderLeft: "10px solid red" }}
              id="role"
              name="select"
              {...register("role", { required: true })}
            >
              <option value="">---Select Role---</option>
              <option value="Employee">Employee</option>
              {(role === "Admin" || role === "Super Admin") && (
                <option value="Admin">Admin</option>
              )}
              <option value="HR">HR</option>
              <option value="Lead">Lead</option>
            </StyledSelect>
            <StyledLabel className="user-label">Role*</StyledLabel>
          </StyledSelectContainer>
        </StyledSelectGroup>

        <StyledSelectGroup>
          <StyledSelectContainer>
            <StyledSelect
              defaultValue={singleEmployeeData.gender}
              style={errors.gender && { borderLeft: "10px solid red" }}
              id="gender"
              {...register("gender", { required: true })}
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Others">Others</option>
            </StyledSelect>
            <StyledLabel className="user-label">Gender*</StyledLabel>
          </StyledSelectContainer>
        </StyledSelectGroup>

        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              defaultValue={formatDate(singleEmployeeData.dateOfBirth)}
              style={errors.dob && { borderLeft: "10px solid red" }}
              type="date"
              max="9999-12-31"
              {...register("dob", { required: true })}
            />
            <StyledLabel className="user-label">DOB*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              defaultValue={formatDate(singleEmployeeData.joiningDate)}
              style={errors.joiningDate && { borderLeft: "10px solid red" }}
              type="date"
              max="9999-12-31"
              {...register("joiningDate", { required: true })}
            />
            <StyledLabel className="user-label">Joining Date*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        <StyledSelectGroup>
          <StyledSelectContainer>
            <StyledSelect
              defaultValue={singleEmployeeData.qualifications}
              style={errors.qualifications && { borderLeft: "10px solid red" }}
              id="qualifications"
              {...register("qualifications", { required: true })}
            >
              <option value="">---select---</option>
              <option value="Degree">Degree</option>
              <option value="B.Tech">B.Tech</option>
              <option value="PG">PG</option>
              <option value="Other">Other</option>
            </StyledSelect>
            <StyledLabel className="user-label">Qualifications*</StyledLabel>
          </StyledSelectContainer>
        </StyledSelectGroup>

        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              defaultValue={singleEmployeeData.department}
              style={errors.department && { borderLeft: "10px solid red" }}
              type="text"
              {...register("department", {
                required: true,
                pattern: /^(?!\s)[a-zA-Z.\s-]{3,45}$/,
              })}
            />
            <StyledLabel className="user-label">Department*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              defaultValue={singleEmployeeData.mobileNumber}
              style={errors.mobileNumber && { borderLeft: "10px solid red" }}
              type="tel"
              {...register("mobileNumber", {
                required: true,
                maxLength: 10,
                pattern: /[0-9]{10}/,
              })}
            />
            <StyledLabel className="user-label">Mobile No*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        <StyledSelectGroup>
          <StyledSelectContainer>
            <StyledSelect
              defaultValue={singleEmployeeData.bloodGroup}
              style={errors.bloodGroup && { borderLeft: "10px solid red" }}
              id="bloodGroup"
              {...register("bloodGroup", { required: true })}
            >
              <option className="option-bg" value="">
                ---select---
              </option>
              <option className="option-bg" value="A+">
                A+
              </option>
              <option value="B+">B+</option>
              <option value="AB+">AB+</option>
              <option value="O+">O+</option>
              <option value="A-">A-</option>
              <option value="B-">B-</option>
              <option value="AB-">AB-</option>
              <option value="O-">O-</option>
            </StyledSelect>
            <StyledLabel className="user-label">Blood Group*</StyledLabel>
          </StyledSelectContainer>
        </StyledSelectGroup>

        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              defaultValue={singleEmployeeData.designation}
              style={errors.designation && { borderLeft: "10px solid red" }}
              type="tel"
              {...register("designation", {
                required: true,
                pattern: /^(?!\s)[A-Za-z.\-_ ]{2,45}$/,
              })}
            />
            <StyledLabel className="user-label">Designation*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        {role === "Admin" || role === "Super Admin" ? (
          <StyledInputGroup>
            <StyledInputContainer>
              <StyledInput
                defaultValue={singleEmployeeData.salary}
                style={errors.salary && { borderLeft: "10px solid red" }}
                id="salary"
                type="number"
                min="0"
                {...register("salary", { required: true })}
              />
              <StyledLabel className="user-label">Salary*</StyledLabel>
            </StyledInputContainer>
          </StyledInputGroup>
        ) : null}

        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              defaultValue={singleEmployeeData.address}
              style={errors.location && { borderLeft: "10px solid red" }}
              type="text"
              {...register("location", {
                required: true,
                pattern: /^(?!\s)[A-Za-z ]{3,45}$/i,
              })}
            />
            <StyledLabel className="user-label">Location*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              id="bankName"
              defaultValue={singleEmployeeData.bankName}
              style={errors.bankName && { borderLeft: "10px solid red" }}
              type="text"
              {...register("bankName", {
                pattern: /^(?!\s)[a-zA-Z ]{3,45}$/,
              })}
            />
            <StyledLabel className="user-label">Bank Name</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={
                errors.bankAccountNumber && { borderLeft: "10px solid red" }
              }
              defaultValue={singleEmployeeData.bankAccountNumber}
              id="bankAccountNumber"
              type="tel"
              {...register("bankAccountNumber", {
                minLength: 11,
                maxLength: 20,
                pattern: /^(?!\s)[0-9]/,
              })}
            />
            <StyledLabel className="user-label">Bank Ac Number</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={errors.ifsc && { borderLeft: "10px solid red" }}
              id="ifsc"
              defaultValue={singleEmployeeData.ifsc}
              type="text"
              {...register("ifsc", {
                pattern: /^(?!\s)[a-zA-Z0-9]{10,15}$/,
              })}
            />
            <StyledLabel className="user-label">IFSC</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        <StyledInputGroup>
          <StyledInputContainer>
            <StyledInput
              style={errors.panNumber && { borderLeft: "10px solid red" }}
              defaultValue={singleEmployeeData.panNumber}
              id="panNumber"
              type="text"
              {...register("panNumber", {
                required: true,
                pattern: /^(?!\s)[a-zA-Z0-9]{10}$/,
              })}
            />
            <StyledLabel className="user-label">PAN Number*</StyledLabel>
          </StyledInputContainer>
        </StyledInputGroup>

        <StyledSelectGroup>
          <StyledSelectContainer>
            <StyledSelect
              defaultValue={singleEmployeeData.employementStatus}
              id="employeeStatus"
              {...register("employeeStatus", { required: true })}
            >
              <option className="option-bg" value="Active">
                Active
              </option>
              <option value="Released">Released</option>
            </StyledSelect>
            <StyledLabel className="user-label">Employee Status*</StyledLabel>
          </StyledSelectContainer>
        </StyledSelectGroup>

        <StyledSelectGroup>
          <StyledSelectContainer>
            <StyledSelect
              defaultValue={singleEmployeeData.employeeProjectStatus}
              className="select-element"
              id="employeeProjectStatus"
              {...register("employeeProjectStatus", { required: true })}
            >
              <option className="option-bg" value="Active">
                Active
              </option>
              <option value="Released">on Bench</option>
            </StyledSelect>
            <StyledLabel className="user-label">
              Employee Project Status*
            </StyledLabel>
          </StyledSelectContainer>
        </StyledSelectGroup>

        <StyledSelectGroup>
          <StyledSelectContainer>
            <StyledSelect
              style={errors.reportingTo && { borderLeft: "10px solid red" }}
              defaultValue={singleEmployeeData.reportingTo}
              className="select-element"
              id="reportingTo"
              {...register("reportingTo")}
            >
              <option className="option-bg" value="">
                ----Select----
              </option>

              {leads.map((eachLead) => (
                <option key={eachLead} value={eachLead}>
                  {eachLead}
                </option>
              ))}
            </StyledSelect>
            <StyledLabel className="user-label">Reporting To</StyledLabel>
          </StyledSelectContainer>
        </StyledSelectGroup>

        {/* certificates */}
        <div ref={dropdownRef}>
          <button
            className="dropdown-btn"
            onClick={toggleDropdown}
            type="button"
            style={{
              border: `1px solid #000000`,
            }}
          >
            Certificates <MdOutlineKeyboardArrowDown />
          </button>
          <ul
            className="certificates-dropdown shadow"
            style={{
              display: `${dropdown}`,
              // border: `1px solid ${primaryColor}`,
            }}
          >
            <li>
              <label>
                <input
                  onChange={onSelectCertificates}
                  checked={updatedCertificates.tenth ? true : null}
                  name="tenth"
                  value="10th"
                  type="checkbox"
                />{" "}
                10th
              </label>
            </li>
            <li>
              <label>
                <input
                  checked={updatedCertificates.intermediate ? true : null}
                  onChange={onSelectCertificates}
                  type="checkbox"
                  value="intermediate"
                  name="intermediate"
                />{" "}
                Intermediate
              </label>
            </li>
            <li>
              <label>
                <input
                  checked={updatedCertificates.degree ? true : null}
                  onChange={onSelectCertificates}
                  type="checkbox"
                  name="degree"
                  value="Degree"
                />{" "}
                Degree
              </label>
            </li>
            <li>
              <label>
                <input
                  onChange={onSelectCertificates}
                  checked={updatedCertificates.masters ? true : null}
                  type="checkbox"
                  name="masters"
                  value="masters"
                />{" "}
                PG
              </label>
            </li>

            <li>
              <label>
                <input
                  checked={updatedCertificates.originalDegree ? true : null}
                  onChange={onSelectCertificates}
                  value="original Degree"
                  type="checkbox"
                  name="originalDegree"
                />{" "}
                Original Degree
              </label>
            </li>
          </ul>
        </div>
      </div>

      <div className="edit-emp-submit-btn gap-3">
        <SecondaryButton type="button" onClick={closeEditEmployeeModel}>
          Cancel
        </SecondaryButton>
        <PrimaryButton type="submit">Submit</PrimaryButton>
      </div>
    </form>
  );
};

export default EditEmployeeModal;
