import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isMobileMenu: false,
  isLeftNavBarCollapsed: false,
};

export const mobileMenuSlice = createSlice({
  name: "mobile",
  initialState,
  reducers: {
    openMobileMenu: (state) => {
      state.isMobileMenu = true; //immer
    },
    closeMobileMenu: (state) => {
      state.isMobileMenu = false; //immer
    },
    collapseLeftnavBar: (state) => {
      state.isLeftNavBarCollapsed = true;
    },
    expandLeftnavBar: (state) => {
      state.isLeftNavBarCollapsed = false;
    },
  },
});

export const {
  openMobileMenu,
  closeMobileMenu,
  collapseLeftnavBar,
  expandLeftnavBar,
} = mobileMenuSlice.actions;

export default mobileMenuSlice.reducer;

// Action creators are generated for each case reducer function
