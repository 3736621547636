import React from "react";
import { BsX } from "react-icons/bs";

import "./index.css";
const PopupModal = ({ children, showModal, modalName, closeBtn }) => {
  return (
    <>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <div className="d-flex justify-content-between mb-3">
              <p className="modal-heading">{modalName}</p>
              <BsX onClick={closeBtn} className="close-btn" />
            </div>

            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default PopupModal;
