import Header from "../Header";
import LeftNavBar from "../LeftNavBar";
import UpcommingBirthdays from "../UpcommingBirthdays";
// import UpcommingEvents from "../UpcommingEvents";
// import { getUserData } from "../../api/services/hroneApi";
import "./index.css";
// import { TitleHeader } from "../StyledComponent";
import { PageHeaderName } from "../../StyledCss/styledComponents";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import PageHeader from "../PageHeader";
import PopupModal from "../PopupModal";
import UpcommingEvents from "../UpcommingEvents";

import { useEffect, useState } from "react";
import {
  getEvents,
  getHolidays,
  getTotalEmployeesCount,
  getUserData,
} from "../../api/services/hroneApi";
import formatDate from "../../util/formatdate";
import AddEventForm from "../AddEventForm/addEventForm";
import AddHolidayForm from "../AddHolidaysForm/addHolidaysForm";
import HolidaysCard from "../HolidaysCard";

const apiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const eventsApiStatusConstants = {
  initial: "INITIAL",
  success: "SUCCESS",
  failure: "FAILURE",
  inProgress: "IN_PROGRESS",
};

const HomeRoute = () => {
  const [employeeCount, setEmployeeCount] = useState("");
  const [displayHolidaysModal, setDisplayHolidaysModal] = useState(false);
  const [displayEventsModal, setDisplayEventsModal] = useState(false);
  const [holidaysList, setHolidaysList] = useState([]);
  const [events, setEvents] = useState([]);
  const { role } = getUserData();
  const [apiStatus, setApiStatus] = useState(apiStatusConstants.initial);
  const [eventsApiStatus, setEventsApiStatus] = useState(
    eventsApiStatusConstants.initial
  );

  useEffect(() => {
    getEmployeesCount();
    getHolidaysList();
    getUpcommingEvents();
  }, []);

  const getHolidaysList = async () => {
    try {
      setApiStatus(apiStatusConstants.inProgress);
      const response = await getHolidays();
      const data = await response.json();

      if (response.ok) {
        const updatedData = data.map((eachItem) => {
          const newDate = formatDate(eachItem.date);
          return { ...eachItem, date: newDate };
        });
        setHolidaysList(updatedData);
        setApiStatus(apiStatusConstants.success);
      } else {
      }
    } catch (e) {
      setApiStatus(apiStatusConstants.failure);
    }
  };

  const getUpcommingEvents = async () => {
    setEventsApiStatus(eventsApiStatusConstants.inProgress);
    try {
      const response = await getEvents();
      if (response.ok) {
        const data = await response.json();
        const updatedData = data.map((eachItem) => {
          const newDate = formatDate(eachItem.date);
          return { ...eachItem, date: newDate };
        });
        setEvents(updatedData);
        setEventsApiStatus(eventsApiStatusConstants.success);
      }
    } catch (error) {
      setEventsApiStatus(eventsApiStatusConstants.failure);
    }
  };

  const getEmployeesCount = async () => {
    try {
      let res = await getTotalEmployeesCount();
      if (res.status === 200) {
        const data = await res.json();
        setEmployeeCount(data.employees_count);
      }
    } catch (error) {}
  };

  const closeHolidaysModal = () => {
    setDisplayHolidaysModal(false);
  };

  const closeEventsModal = () => {
    setDisplayEventsModal(false);
  };

  const refreshHolidays = () => {
    getHolidaysList();
  };

  const refreshEvents = () => {
    getUpcommingEvents();
  };

  return (
    <>
      <Header />
      <div className="d-flex">
        <LeftNavBar />
        <div className="d-flex flex-column main-container">
          <PageHeader>
            <PageHeaderName>Dashboard</PageHeaderName>

            {(role === "Admin" || role === "HR" || role === "Super Admin") && (
              <div className="d-flex gap-3">
                <PrimaryButton onClick={() => setDisplayHolidaysModal(true)}>
                  Add Holidays
                </PrimaryButton>
                <SecondaryButton onClick={() => setDisplayEventsModal(true)}>
                  Add Events
                </SecondaryButton>
              </div>
            )}
          </PageHeader>

          <div className="dashboard-main-container">
            <div style={{ gap: "20px" }} className="d-flex flex-wrap">
              <div>
                <UpcommingBirthdays />
              </div>
              <div>
                <UpcommingEvents
                  apiStatus={eventsApiStatus}
                  eventsData={events}
                  refreshEvents={refreshEvents}
                />
              </div>
              <div>
                <HolidaysCard
                  refreshHolidays={refreshHolidays}
                  apiStatus={apiStatus}
                  holidaysData={holidaysList}
                />
              </div>

              <div className="dashboard-card">
                <div className="d-flex justify-content-center">
                  <p className="card-heading">All Employees</p>
                </div>
                <p className="card-count">{employeeCount}</p>
              </div>
            </div>
          </div>

          <PopupModal
            closeBtn={closeHolidaysModal}
            modalName="Add Holidays"
            showModal={displayHolidaysModal}
          >
            <AddHolidayForm
              refreshHolidays={refreshHolidays}
              closeHolidaysModal={closeHolidaysModal}
            />
          </PopupModal>

          <PopupModal
            closeBtn={closeEventsModal}
            modalName="Add Events"
            showModal={displayEventsModal}
          >
            <AddEventForm
              refreshEvents={refreshEvents}
              closeEventsModal={closeEventsModal}
            />
          </PopupModal>
        </div>
      </div>
    </>
  );
};

export default HomeRoute;
