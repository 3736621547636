// import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
// import * as Yup from "yup";
// import DatePicker from "react-datepicker";
// import { format } from "date-fns";
// import { useApplyLeaveMutation } from "../../api/leavesApi";
import "react-datepicker/dist/react-datepicker.css";
import { ClipLoader } from "react-spinners";
import {
  StyledInput,
  StyledInputContainer,
  StyledInputGroup,
  StyledLabel,
  StyledSelect,
  StyledSelectContainer,
  StyledSelectGroup,
  StyledTextarea,
} from "../../StyledCss/styledInputs";
import { PrimaryButton, SecondaryButton } from "../../StyledCss/styledbtn";
import { applyLeave, getUserData } from "../../api/services/hroneApi";
import { useForm } from "react-hook-form";
import { ErrorText } from "../../StyledCss/styledComponents";

import "./index.css";
const LeaveForm = ({
  hrList,
  holidaysList,
  leaveApplied,
  notifyLeaveApplied,
  closeBtn,
}) => {
  // const [success, setSuccess] = useState("");

  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [formData, setFormData] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const { empId, email } = getUserData();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const fromDate = watch("fromDate");
  const toDate = watch("toDate");
  const reasonInput = watch("reason");
  const [isLoading, setIsLoading] = useState(false);
  const [requestType, setRequestType] = useState("");

  useEffect(() => {
    const today = new Date();

    // Calculate one week ago
    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    // Format the date to YYYY-MM-DD
    const year = oneWeekAgo.getFullYear();
    let month = oneWeekAgo.getMonth() + 1;
    month = month < 10 ? "0" + month : month; // Add leading zero if needed
    let day = oneWeekAgo.getDate();
    day = day < 10 ? "0" + day : day; // Add leading zero if needed

    // Create the minimum date string in the format YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`;

    // Set the minimum date
    setMinDate(formattedDate);
  }, []);

  useEffect(() => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    let selectedMonth = currentMonth + 2;
    let selectedYear = currentYear;

    if (currentMonth === 12) {
      selectedMonth = 1;
      selectedYear = currentYear + 1;
    } else {
      selectedMonth = currentMonth + 2;
      selectedYear = currentYear;
    }
    if (selectedMonth < 10) {
      selectedMonth = `0${selectedMonth}`;
    }
    const numberOfDays = getDaysInMonth(selectedMonth, selectedYear);

    const formattedMaxDate = `${selectedYear}-${selectedMonth}-${numberOfDays}`;
    // const formattedMaxDate = new Date(
    //   selectedYear,
    //   selectedMonth - 1,
    //   numberOfDays + 1
    // )
    //   .toISOString()
    //   .split("T")[0];
    // console.log(formattedMaxDate, "max date");
    setMaxDate(formattedMaxDate);
  }, []);

  var getDaysInMonth = function (month, year) {
    return new Date(year, month, 0).getDate();
  };

  const onSubmit = (data) => {
    setFormData(data);
    handleApplyLeave(data);
  };

  const handleApplyLeave = async (data) => {
    setIsLoading(true);
    try {
      const updatedDate = {
        employee_id: empId,
        employee_email: email,
        hr_email: data.lead,
        start_date: data.fromDate,
        end_date: data.toDate,
        days: daysWithoutWeekends,
        leave_reason: data.reason,
        request_type: requestType,
        subject: data.subject,
        status: "Awaiting",
      };

      const response = await applyLeave(updatedDate);

      if (response.status === 200) {
        setIsLoading(false);
        notifyLeaveApplied(`${requestType} Applied Successfully`);
        leaveApplied();
        reset();
      } else {
        setIsLoading(false);
        const data = await response.json();
        setErrorMsg(data.detail);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const numberOfDays = () => {
    if (!fromDate || !toDate) {
      return 0;
    }

    const start = new Date(fromDate);
    const end = new Date(toDate);

    // Initialize a count for the total number of days
    let totalDays = 0;

    // Loop through the dates from start to end
    for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
      // Check if the current date is not a Saturday (6) or Sunday (0)
      if (
        date.getDay() !== 6 &&
        date.getDay() !== 0 &&
        !holidaysList.includes(date.toISOString().slice(0, 10))
      ) {
        totalDays++;
      }
    }
    return totalDays;
  };

  const daysWithoutWeekends = numberOfDays();

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="leave-form  col-lg-9 mb-3">
          <div className="d-flex">
            <StyledInputGroup className="me-5">
              <StyledInputContainer>
                <StyledInput
                  style={
                    errors.fromDate
                      ? { borderLeft: "10px solid red", width: "120px" }
                      : { width: "120px" }
                  }
                  type="date"
                  id="fromDate"
                  min={minDate}
                  max={maxDate}
                  {...register("fromDate", { required: true })}
                />
                <StyledLabel
                  hasValue={true}
                  htmlFor="fromDate"
                  className="user-label"
                >
                  From
                </StyledLabel>
              </StyledInputContainer>
            </StyledInputGroup>

            <StyledInputGroup>
              <StyledInputContainer>
                <StyledInput
                  style={
                    errors.toDate
                      ? { borderLeft: "10px solid red", width: "120px" }
                      : { width: "120px" }
                  }
                  // max="2025-02-29"
                  max={maxDate}
                  type="date"
                  id="toDate"
                  min={minDate}
                  {...register("toDate", { required: true })}
                />
                <StyledLabel
                  hasValue={true}
                  htmlFor="toDate"
                  className="user-label"
                >
                  To
                </StyledLabel>
              </StyledInputContainer>
            </StyledInputGroup>
          </div>

          <p>No of Days : {daysWithoutWeekends} </p>

          <StyledSelectGroup>
            <StyledSelectContainer>
              <StyledSelect
                style={errors.lead && { borderLeft: "10px solid red" }}
                {...register("lead", { required: true })}
                defaultValue={hrList[0]}
              >
                {/* <option value="">Select your lead</option> */}
                {Array.isArray(hrList) &&
                  hrList.map((hr) => (
                    <option key={hr} value={hr}>
                      {hr}
                    </option>
                  ))}
              </StyledSelect>
              <StyledLabel className="user-label">Select Lead*</StyledLabel>
            </StyledSelectContainer>
          </StyledSelectGroup>

          <StyledInputGroup>
            <StyledInputContainer>
              <StyledInput
                style={errors.subject && { borderLeft: "10px solid red" }}
                type="text"
                {...register("subject", {
                  required: true,
                  pattern: /^(?!\s)[a-zA-Z1-9.\s-;'"!(),?*:@]{3,100}$/,
                })}
              />
              <StyledLabel className="user-label">Subject*</StyledLabel>
            </StyledInputContainer>
          </StyledInputGroup>

          <StyledInputGroup>
            <StyledInputContainer>
              <StyledTextarea
                style={errors.reason && { borderLeft: "10px solid red" }}
                {...register("reason", {
                  required: true,
                  pattern: /^(?!\s)[a-zA-Z1-9.\s-;'"!(),?*:@]{3,350}$/,
                })}
              />
              <StyledLabel hasValue={reasonInput !== ""} className="user-label">
                Reason For Leave*
              </StyledLabel>
            </StyledInputContainer>
          </StyledInputGroup>

          <div className="form-group col-lg-9 mb-3">
            <label className="label-elements" htmlFor="requestType">
              Select request type*
            </label>

            <div className="d-flex justify-content-between mt-3">
              <div className="form-check">
                <input
                  type="radio"
                  id="leave"
                  className="form-check-input"
                  value="leave"
                  name="requestType"
                  // {...register("requestType")}
                  onChange={() => setRequestType("Leave")}
                />
                <label htmlFor="leave" className="label-elements">
                  Leave
                </label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  id="workFromHome"
                  className="form-check-input"
                  value="workFromHome"
                  name="requestType"
                  onChange={() => setRequestType("WFH")}
                />
                <label htmlFor="workFromHome" className="label-elements">
                  Work from Home
                </label>
              </div>
            </div>
          </div>

          {formData.requestType === null && (
            <p style={{ color: "#FB5801" }}>*Select a request type</p>
          )}

          {!(fromDate <= toDate) && fromDate && toDate && (
            <p style={{ color: "#FB5801" }}>
              *Start date must be less than end date
            </p>
          )}

          {errorMsg && <ErrorText>*{errorMsg}</ErrorText>}

          <div className="form-btn-container">
            <SecondaryButton onClick={closeBtn} type="button">
              Cancel
            </SecondaryButton>
            {isLoading ? (
              <ClipLoader color="#cb7c25" />
            ) : (
              <div>
                <PrimaryButton
                  disabled={
                    toDate < fromDate ||
                    requestType === "" ||
                    daysWithoutWeekends === 0
                  }
                  type="submit"
                >
                  Submit
                </PrimaryButton>
              </div>
            )}
          </div>
        </div>
      </form>
      {/* {success && <p className="text-success success-msg mt-1">{success}</p>} */}
      {/* {error && <p className="text-danger">{error}</p>} */}
    </>
  );
};

export default LeaveForm;
